.street-width {
  display: inline-flex;
  align-items: center;
}

.street-width .street-meta-icon svg {
  /* Radix UI optimized width/height */
  width: 15px;
  height: 15px;
}

.street-width .menu-carat-down {
  transform: scale(0.85);
}

.street-width-over {
  margin-left: 0.25em;
  color: var(--warning-color);

  [dir="rtl"] & {
    margin-right: 0.25em;
    margin-left: 0;
  }
}

.street-width-under {
  margin-left: 0.25em;
  color: rgb(96 96 96);
  transition: color var(--skybox-transition);

  [dir="rtl"] & {
    margin-right: 0.25em;
    margin-left: 0;
  }
}

/* Invert the UI text color when there is a dark background */
body.dark-skybox-invert-ui {
  .street-width-under {
    color: #a0a0a0;
  }
}

[data-radix-popper-content-wrapper] {
  z-index: 900 !important;
}

.dropdown-menu-content {
  --menu-border-radius: var(--border-radius);
  --menu-box-shadow: var(--medium-box-shadow);

  padding: 5px;
  background-color: white;
  transform-origin: 50% 0;
  border-radius: var(--menu-border-radius);
  box-shadow: var(--menu-box-shadow);
  line-height: 1.4;
  animation-duration: 120ms;
  will-change: transform, opacity;
}

.dropdown-menu-content[data-side="bottom"] {
  animation-name: swing-down;
}

body.safari .dropdown-menu-content[data-side="bottom"] {
  animation-name: shift-down;
}

.dropdown-menu-item,
.dropdown-menu-checkbox-item,
.dropdown-menu-radio-item {
  user-select: none;
  outline: none;
  padding: 0.5em 1em 0.5em 1.5em;
  text-decoration: none;
  color: black;
  border-radius: var(--border-radius);
  cursor: pointer;

  [dir="rtl"] & {
    padding: 0.5em 1.5em 0.5em 1em;
  }
}

.dropdown-menu-item[data-disabled],
.dropdown-menu-checkbox-item[data-disabled],
.dropdown-menu-radio-item[data-disabled] {
  color: #999; /* TODO: gray color */
  pointer-events: none;
}

.dropdown-menu-item[data-highlighted],
.dropdown-menu-checkbox-item[data-highlighted],
.dropdown-menu-radio-item[data-highlighted] {
  background-color: var(--interactive-element-hover-color);
}

.dropdown-menu-checkbox-item[data-state="checked"],
.dropdown-menu-radio-item[data-state="checked"] {
  font-weight: 500;
}

.dropdown-menu-label {
  padding: 0.5em 1em 0.5em 1.5em;
  font-weight: 500;
  color: var(--color-turquoise-700);

  [dir="rtl"] & {
    padding: 0.5em 1.5em 0.5em 1em;
  }
}

.dropdown-menu-item-indicator {
  position: absolute;
  left: 0.65em;
  margin-top: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--color-turquoise-500);

  [dir="rtl"] & {
    right: 0.65em;
    left: auto;
  }
}

.dropdown-menu-arrow {
  fill: white;
}

@keyframes swing-down {
  from {
    opacity: 0;
    transform: rotateX(-90deg);
  }

  to {
    opacity: 1;
    transform: rotateX(0);
  }
}

/* Use this instead of swing-down on Safari / iOS */
@keyframes shift-down {
  from {
    opacity: 0;
    transform: translateY(40px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
