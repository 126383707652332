.radio-group-item {
  display: flex;
  margin: 0.8em 0;
}

.radio-group-radio {
  position: relative;
  padding: 0;
  margin-top: -2px;
  width: 21px;
  height: 21px;
  border: 0;
  background-color: white;
  box-shadow: 0 0 0 1px var(--color-turquoise-700);
  transition: background-color 120ms;
  border-radius: 9999px;
  cursor: pointer;

  &:focus,
  &:active:not(:disabled) {
    box-shadow: 0 0 0 2px var(--color-turquoise-700);
  }

  &:disabled {
    box-shadow: 0 0 0 1px lightgray;

    & + label {
      color: darkgray;
    }
  }

  + label {
    margin-left: 0.75em;
    user-select: none;

    [dir="rtl"] & {
      margin-left: 0;
      margin-right: 0.75em;
    }
  }
}

.radio-group-indicator {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &::after {
    content: "";
    display: block;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: var(--color-turquoise-700);
  }
}

.radio-group-sublabel {
  display: block;
  margin-top: 4px;
  margin-bottom: 4px;
  line-height: 8px;
  color: rgb(128 128 128);
  font-size: 0.8em;
}
