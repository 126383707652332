.help-menu-shortcuts {
  padding: 0.8em 1em 1em 2.5em;
  max-width: 360px;
  position: relative;

  [dir="rtl"] & {
    padding-left: 1em;
    padding-right: 2.5em;
  }

  p:first-child {
    margin-top: 0;
  }

  table {
    margin-top: 0.5em;
    border-collapse: collapse;

    td {
      vertical-align: top;
      padding: 0.25em;
    }

    td:first-child {
      min-width: 60px;
      padding-left: 0;

      [dir="rtl"] & {
        padding-left: 0.25em;
        padding-right: 0;
      }
    }
  }

  [data-icon="keyboard"] {
    top: 0.9em;
  }
}
