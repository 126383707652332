.info-bubble-remove {
  width: auto !important; /* Override button width of other buttons in infobubble */
  padding: 0 0.75em;
  margin-left: 1em;
  font-size: 11px;
  display: flex;
  align-items: center;

  .remove-icon {
    width: 16px;
    height: 16px;
    margin-right: 0.35em;

    [dir="rtl"] & {
      margin-right: 0;
      margin-left: 0.35em;
    }
  }
}
