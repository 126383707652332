.share-sign-in-promo {
  text-align: center;
  background-color: var(--alert-background);
  border-bottom: var(--alert-border);
  border-bottom-width: 3px;
  padding: 1em 2.5em;

  /* Override parent padding */
  margin-left: -5px;
  width: calc(100% + 10px);
  margin-top: -5px;

  a {
    text-decoration: underline;
  }
}
