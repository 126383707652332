.sky-background {
  /* Backgrounds don't need to have parallax scrolling, so they don't
     need to be as wide as the clouds, which do scroll. But we can't
     have it wider than the viewport, because there's a rendering bug
     in Chrome where if stars.svg is in a div that's too wide, it's
     rendered pixellated for some reason. */
  position: absolute;
  left: 0;
  width: 100vw;
  height: 100%;
}

/* Give the react-spring animations smooth transitions */
.sky-background-color {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: opacity;
}
