.resize-guides {
  --resize-guide-text-color: rgb(0 0 0 / 60%);
  --resize-guide-text-inverted-color: rgb(255 255 255 / 60%);
  --resize-guide-line: 1px dashed rgb(0 0 0 / 40%);
  --resize-guide-line-inverted: 1px dashed rgb(255 255 255 / 40%);

  position: absolute;
  z-index: var(--z-07-resize-guide);
  top: -75px;
  bottom: 120px;
  left: 50%; /* Will be overridden by component */
  pointer-events: none;
}

.resize-guide {
  position: absolute;
  height: 100%;
  border-left: var(--resize-guide-line);
  border-right: var(--resize-guide-line);

  /* Invert the UI text color when there is a dark background */
  body.dark-skybox-invert-ui & {
    border-left: var(--resize-guide-line-inverted);
    border-right: var(--resize-guide-line-inverted);
  }
}

.resize-guide-max-before,
.resize-guide-max-after,
.resize-guide-min-before,
.resize-guide-min-after {
  width: 50px;
  position: absolute;
  bottom: 400px;
  font-size: 0.8rem;
  color: var(--resize-guide-text-color);

  /* Invert the UI text color when there is a dark background */
  body.dark-skybox-invert-ui & {
    color: var(--resize-guide-text-inverted-color);
  }
}

.resize-guide-max-before {
  left: -55px;
  text-align: right;
}

.resize-guide-max-after {
  right: -55px;
  text-align: left;
}

.resize-guide-min-before {
  left: 5px;
  text-align: left;
}

.resize-guide-min-after {
  right: 5px;
  text-align: right;
}
