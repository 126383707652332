/**
 *  Global base styles and resets
 *  ------------------------------------------------------- */

html {
  height: 100%;
  overflow: hidden;

  /* Global box-sizing "reset" to the "quirks" box model */
  box-sizing: border-box;
}

/* Inherit the box-sizing everywhere */
*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative; /* Fixes iOS Safari overflow: hidden not working bug */
  background: white;
  overscroll-behavior: none;
  text-size-adjust: none;

  &:not(.safari) {
    perspective: 500px;
  }
}

/* Reset buttons to have no default appearance */
button {
  appearance: none;
  border: 0;
  background-color: transparent;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
}

/* Certain interactive elements remove tap-highlight-color in Webkit
 * This is a non-standard CSS property. */
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

a,
.link-like {
  cursor: pointer;
  color: var(--interactive-text-color);

  &:hover {
    color: var(--interactive-text-hover-color);
  }

  &:active {
    color: var(--interactive-text-active-color);
  }
}

.link-like {
  text-decoration: underline;
}

input[type="text"],
textarea {
  padding: 3px;

  &[disabled] {
    background: var(--color-gray-100) !important;
    border-color: var(--color-gray-200) !important;
  }
}

hr {
  border: 0;
  border-top: 1px solid var(--color-turquoise-500);
  margin-top: 1em;
  margin-bottom: 1em;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--header-font-family);
}

/* Some utility classes */

.underline {
  text-decoration: underline;
}
