.avatar {
  display: inline-block;
  position: relative;
  background-size: 100%;
  background-color: rgba(127 127 127 / 10%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  vertical-align: middle;
  margin-left: 3px;
  margin-right: 5px;
  overflow: hidden;

  /* Avatar images are loaded in an object element to allow for fallbacks
     when image source is broken */
  object {
    width: 100%;
    height: 100%;
  }

  /* Should only display as fallback when object source is broken */
  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    border: 1px solid white;
  }
}
