.menu {
  --menu-border-radius: var(--border-radius);
  --menu-box-shadow: var(--medium-box-shadow);

  position: absolute;
  margin-top: 7px;
  padding: 5px;
  touch-action: none;
  opacity: 0;
  background-color: white;
  transform-origin: 50% 0;
  transform: rotateX(-90deg);
  transition:
    transform 100ms,
    opacity 100ms;
  pointer-events: none;
  border-radius: var(--menu-border-radius);
  box-shadow: var(--menu-box-shadow);
  line-height: 1.4;

  /* Allows scrolling if the menu length is longer than height of viewport */
  max-height: calc(100% - 100px);
  overflow: hidden auto;

  &.menu-visible-enter-done {
    transform: rotateX(0);
    opacity: 1;
    pointer-events: auto;
  }

  &.menu-visible-exit {
    transform: rotateX(-90deg);
    opacity: 0;
  }

  > a {
    display: block;
    position: relative;
    padding: 0.5em 1em 0.5em 2.5em;
    text-decoration: none;
    color: black;
    border-radius: var(--border-radius);

    &:hover {
      background-color: var(--interactive-element-hover-color);
    }

    &:active {
      color: var(--interactive-text-active-color);
    }

    [dir="rtl"] & {
      padding-right: 2.5em;
      padding-left: 1em;
    }
  }

  input {
    appearance: none;
    background: var(--form-element-background);
    border: 1px solid var(--form-element-border);
    resize: none;
  }

  [data-icon="external-link"] {
    vertical-align: middle;
    margin-left: 0.25em;
    margin-top: -2px;
    height: 12px;
    color: var(--color-midnight-600);

    /* Reset icon-source styling */
    position: relative;
    left: auto;
    top: auto;

    [dir="rtl"] & {
      margin-left: auto;
      margin-right: 0.25em;
    }
  }
}

body.safari .menu {
  transform: translateY(20px) translateZ(100px);

  &.menu-visible-enter-done {
    /* Y-position of 0 puts it too close to menubar, so give it a bit of space */
    transform: translateY(3px) rotateX(0) translateZ(100px);
  }
}

.menu-item-group {
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: 1px solid var(--color-turquoise-200);
  border-bottom: 1px solid var(--color-turquoise-200);

  /* Remove the top border if menu group is the first element in a menu */
  &:first-child {
    border-top: 0;
  }

  /* Remove the bottom border if menu group is the last element in a menu */
  &:last-child {
    border-bottom: 0;
  }

  /* Remove the doubling up of borders between consecutive menu groups */
  + .menu-item-group {
    border-top: 0;
  }
}

.menu-item {
  display: block;
  position: relative;
  padding: 0.5em 2em;
  cursor: pointer;
  border-radius: var(--border-radius);

  &:hover {
    background-color: var(--interactive-element-hover-color);
  }

  &:active {
    color: var(--interactive-text-active-color);
  }

  &.menu-item-selected [data-icon="check"] {
    display: inline-block;
  }

  .loading-spinner {
    position: absolute;
    left: 8px;
    top: 8px;

    [dir="rtl"] & {
      left: auto;
      right: 8px;
    }
  }
}

.menu-item-icon {
  position: absolute;
  top: 0.5em;
  left: 0.8em;
  width: 16px;
  height: 16px;
  color: var(--color-midnight-800);

  [dir="rtl"] & {
    left: auto;
    right: 0.8em;
  }

  &[data-icon-source="io5"] {
    top: 0.55em;
    left: 0.75em;

    [dir="rtl"] & {
      left: auto;
      right: 0.75em;
    }
  }

  /* Radix icons are optimized for 15px */
  &[data-icon-source="radix"] {
    top: 0.6em;
    width: 15px;
    height: 15px;

    [dir="rtl"] & {
      left: auto;
      right: 0.8em;
    }
  }

  &[data-icon="check"] {
    display: none;
    left: 0.5em;
    margin-top: 1px;
    color: var(--color-turquoise-500);

    [dir="rtl"] & {
      left: auto;
      right: 0.5em;
    }
  }

  &[data-icon="discord"] {
    color: var(--social-discord);
  }

  &[data-icon="facebook"] {
    color: var(--social-facebook);
  }

  &[data-icon="github"] {
    color: var(--social-github);
  }

  &[data-icon="mastodon"] {
    color: var(--social-mastodon);
  }

  &[data-icon="twitter"] {
    color: var(--social-twitter);
  }
}

.menu-item-subtext {
  display: block;
  color: rgb(128 128 128);
  font-size: 0.8em;
}

.menu-separator {
  height: 1px;
  background-color: var(--color-turquoise-300);
  margin: 5px 0;
}
