.about-dialog {
  max-width: 820px;

  @media only screen and (width > 768px) {
    & {
      max-height: 75vh !important;
    }
  }

  /* Used by team list and external links */
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  header {
    background-color: white;
    padding: 0;
    text-align: left;

    [dir="rtl"] & {
      text-align: right;
    }
  }

  header h1 {
    display: none;
  }

  .dialog-content {
    overflow: hidden;
    display: flex;
  }
}

.about-dialog-logo {
  height: 36px;
  margin: 2.5rem 2rem 2rem;
}

.about-dialog-content {
  display: flex;
  flex-grow: 1;
}

.about-dialog-left {
  border-right: 1px solid var(--color-turquoise-500);
  padding: 0 3.5em 0 2em;
  width: min(30%, 280px);

  [dir="rtl"] & {
    padding: 0 2em 0 3.5em;
    border-left: 1px solid var(--color-turquoise-500);
    border-right: 0;
  }

  p:first-child {
    margin-top: 0;
    text-wrap: balance;
  }

  p {
    margin-top: 1.5em;
  }
}

.about-dialog-right {
  flex: 1;
  overflow: hidden scroll;
  padding: 0 2em 3em;

  > :first-child {
    margin-top: 0;
  }

  > h2 {
    margin-top: 1.5em;
  }
}

@media only screen and (width < 640px) {
  .about-dialog-content {
    display: block;
    overflow-y: scroll;
  }

  .about-dialog-left {
    width: 100%;
    border: 0;
  }

  .credits-team {
    max-width: 500px;
  }
}

.about-dialog-sponsors {
  margin-top: -1em !important;

  img {
    height: 40px;
  }
}

.about-dialog-sponsors li {
  display: inline-block;
  margin-right: 1.5em;
  margin-top: 1em;

  [dir="rtl"] & {
    margin-left: 1.5em;
    margin-right: 0;
  }
}
