.streetmix-plus-prompt {
  button {
    border: 1px solid var(--color-turquoise-600);
    width: 100%;
    background-color: transparent;
    color: var(--color-turquoise-800);
  }
}

.streetmix-plus-locked-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-copper-800);
  font-weight: 550;
  font-size: 0.9em;
  text-transform: uppercase;
  margin: 0.5em 0;

  [data-icon="lock"] {
    margin: 0.2em 0.5em;
    font-size: 0.75em;
  }
}
