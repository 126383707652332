.profile-settings-item {
  margin-top: 1.2em;

  h3 {
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.profile-settings-editable,
.profile-settings-editable > form {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  > *:not(:last-child) {
    margin-right: 0.5em;

    [dir="rtl"] & {
      margin-left: 0.5em;
      margin-right: 0;
    }
  }

  > p {
    flex-grow: 1;
    margin: 0;
    height: 2.5rem;
    display: flex;
    align-items: center;
  }

  input {
    appearance: none;
    width: 100%;
    height: 2.5rem;
    border-radius: 4px;
    border: 1px solid gray;
  }

  .profile-settings-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;

    button {
      position: relative;
      height: 2.5rem;
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0 1.5em;
    }

    button + button {
      margin-left: 0.5em;

      [dir="rtl"] & {
        margin-left: 0;
        margin-right: 0.5em;
      }
    }
  }
}

.profile-settings-pending {
  position: absolute;
  inset: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
  filter: grayscale(100%);
}

.profile-display-name-not-set {
  color: gray;
}

.profile-display-name-messages {
  p {
    margin: 0;
    margin-top: 0.25em;
    font-size: 0.9rem;
    color: red;
  }
}
