.sky-options {
  --selection-highlight-color: var(--color-copper-700);
  --selection-focus-color: var(--color-turquoise-700);

  display: grid;
  height: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-flow: row;
  grid-gap: 0.75em;
  padding: 1em;
}

.sky-option-item {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: var(--border-radius-large);
  cursor: pointer;
  transition: 120ms box-shadow;

  /* These are functionally buttons, reset appearance */
  appearance: none;
  border: 0;
  padding: 0;

  &:focus {
    box-shadow:
      0 0 0 2px var(--selection-focus-color),
      inset 0 0 0 2px rgb(255 255 255 / 100%);
  }
}

.sky-option-item.sky-selected {
  box-shadow:
    0 0 0 2px var(--selection-highlight-color),
    inset 0 0 0 2px rgb(255 255 255 / 100%);
}

.sky-option-item.sky-disabled {
  cursor: default;

  .sky-disabled-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius-large);
    background-color: rgba(221 221 221 / 65%);
  }

  [data-icon="lock"] {
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 2;
    color: var(--color-copper-800);

    [dir="rtl"] & {
      left: 4px;
      right: auto;
    }
  }
}
