button.scrollable {
  position: absolute;
  top: 0;
  width: 25px;
  height: 40px;
  padding: 0;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Buttons need to make sure they're above the scrolled items */
  z-index: 1;

  &[disabled] {
    opacity: 0;
    pointer-events: none;
  }
}
