.palette-item {
  display: inline-block;
  position: relative;
  margin: 14px 0;
  margin-right: 1px;
  height: 80px;
  width: 60px;
  top: 0;

  /* While the preferred mixing method is in oklch, there is a bug(?) in
     Safari where doing so also causes the hue to shift unexpectedly.
     Mixing transparency in srgb will only affect alpha channel as expected
     in tested browsers (Firefox + Chrome) including Safari.
     See also hover state below. */
  background-color: color-mix(in srgb, var(--sky-color), transparent 30%);
  transform-origin: 50% 75%;
  transition: transform 50ms !important;
}

/* Wrapper element for Tooltip */
.palette-item > div {
  height: 100%; /* Must provide this for proper tooltip positioning */
  width: 100%;
  text-align: center;
}

.palette-item:not(.palette-item-disabled):hover,
.palette-item:not(.palette-item-disabled) > div:focus {
  /* See note above re: mixing in srgb over oklch here. */
  background-color: color-mix(in srgb, var(--sky-color), transparent 5%);
  z-index: 1;

  .palette-item-image {
    transform: scale(1.15);
  }
}

.palette-item-image {
  transform-origin: 50% 75px;
  transition: transform 0.08s cubic-bezier(0, 2.5, 1, 1);

  @media (prefers-reduced-motion) {
    & {
      transition: none;
    }
  }
}

.palette-item-disabled {
  /* This complicated color-mix replaces this previous SCSS statement:
        color.adjust(fade-out($sky-colour, 0.3), $saturation: -100%);
     The CSS postprocesser will squash hsl(none 0% none) to #000000, which
     is wrong. `none` is meant as "absence of change", not "value equals zero".
     Workaround is to store the saturation value as a separate variable and
     bring it into the hsl() function. */
  --palette-item-disabled-saturation: 0%;

  background-color: color-mix(
    in hsl,
    var(--sky-color),
    hsl(none var(--palette-item-disabled-saturation) none / 70%) 100%
  );

  .palette-item-image {
    filter: grayscale(0.8);
    opacity: 0.5;
  }

  [data-icon="lock"] {
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 2;
    color: var(--color-copper-800);

    [dir="rtl"] & {
      left: 4px;
      right: auto;
    }
  }
}
