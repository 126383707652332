/* Applied by the wrapping element created by <Scrollable /> */
.palette-items-scrollable-container {
  position: relative;
  flex-grow: 1;

  /* Margins account for the scrollable left/right buttons */
  margin-left: 20px;
  margin-right: 20px;

  /* Aligns the scroll buttons to the ends of this container,
     and vertically at the same height as the "command" buttons */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  button.scrollable {
    position: relative;
    width: 30px;

    /* Prevent hovered palette items from stacking above the button */
    z-index: 2;

    /* Adjust button position so that it doesn't overlap palette items by as much */
    &.scroll-right {
      right: -20px !important;
    }

    &.scroll-left {
      left: -20px !important;
    }
  }

  [dir="rtl"] & {
    direction: ltr;
  }
}

.palette-items {
  /* Palette item is tall enough to "overhang" above the top edge of the
     palette bar. It also must extend below the viewport to hide the horizontal
     scroll bar. We rely on flexbox vertical alignment for positioning here */
  height: calc(var(--palette-height) + 60px);
  position: absolute;
  left: 0;
  right: 0;
  overflow: scroll hidden;
  white-space: nowrap;

  /* Keep palette items center aligned when the viewport is very wide */
  text-align: center;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
}
