@font-face {
  font-family: Manrope Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 200 800;
  src: url("manrope-cyrillic-ext-wght-normal.4211f9a1.woff2") format("woff2-variations");
  unicode-range: U+460-52F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Manrope Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 200 800;
  src: url("manrope-cyrillic-wght-normal.7269cdb5.woff2") format("woff2-variations");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Manrope Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 200 800;
  src: url("manrope-greek-wght-normal.7a48293e.woff2") format("woff2-variations");
  unicode-range: U+370-377, U+37A-37F, U+384-38A, U+38C, U+38E-3A1, U+3A3-3FF;
}

@font-face {
  font-family: Manrope Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 200 800;
  src: url("manrope-vietnamese-wght-normal.c24f3caf.woff2") format("woff2-variations");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Manrope Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 200 800;
  src: url("manrope-latin-ext-wght-normal.c4d6dafa.woff2") format("woff2-variations");
  unicode-range: U+100-2BA, U+2BD-2C5, U+2C7-2CC, U+2CE-2D7, U+2DD-2FF, U+304, U+308, U+329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Manrope Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 200 800;
  src: url("manrope-latin-wght-normal.bea796d6.woff2") format("woff2-variations");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Overpass Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("overpass-cyrillic-ext-wght-normal.abfb3d75.woff2") format("woff2-variations");
  unicode-range: U+460-52F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Overpass Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("overpass-cyrillic-wght-normal.cf81d25b.woff2") format("woff2-variations");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Overpass Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("overpass-vietnamese-wght-normal.23eb805f.woff2") format("woff2-variations");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Overpass Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("overpass-latin-ext-wght-normal.19030d37.woff2") format("woff2-variations");
  unicode-range: U+100-2BA, U+2BD-2C5, U+2C7-2CC, U+2CE-2D7, U+2DD-2FF, U+304, U+308, U+329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Overpass Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url("overpass-latin-wght-normal.0c1f012a.woff2") format("woff2-variations");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Rubik Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 300 900;
  src: url("rubik-arabic-wght-normal.53067075.woff2") format("woff2-variations");
  unicode-range: U+6??, U+750-77F, U+870-88E, U+890-891, U+897-8E1, U+8E3-8FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+102E0-102FB, U+10E60-10E7E, U+10EC2-10EC4, U+10EFC-10EFF, U+1EE00-1EE03, U+1EE05-1EE1F, U+1EE21-1EE22, U+1EE24, U+1EE27, U+1EE29-1EE32, U+1EE34-1EE37, U+1EE39, U+1EE3B, U+1EE42, U+1EE47, U+1EE49, U+1EE4B, U+1EE4D-1EE4F, U+1EE51-1EE52, U+1EE54, U+1EE57, U+1EE59, U+1EE5B, U+1EE5D, U+1EE5F, U+1EE61-1EE62, U+1EE64, U+1EE67-1EE6A, U+1EE6C-1EE72, U+1EE74-1EE77, U+1EE79-1EE7C, U+1EE7E, U+1EE80-1EE89, U+1EE8B-1EE9B, U+1EEA1-1EEA3, U+1EEA5-1EEA9, U+1EEAB-1EEBB, U+1EEF0-1EEF1;
}

@font-face {
  font-family: Rubik Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 300 900;
  src: url("rubik-cyrillic-ext-wght-normal.a5da59a6.woff2") format("woff2-variations");
  unicode-range: U+460-52F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Rubik Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 300 900;
  src: url("rubik-cyrillic-wght-normal.75ce6f76.woff2") format("woff2-variations");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Rubik Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 300 900;
  src: url("rubik-hebrew-wght-normal.729e1d72.woff2") format("woff2-variations");
  unicode-range: U+307-308, U+590-5FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: Rubik Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 300 900;
  src: url("rubik-latin-ext-wght-normal.72e72d1d.woff2") format("woff2-variations");
  unicode-range: U+100-2BA, U+2BD-2C5, U+2C7-2CC, U+2CE-2D7, U+2DD-2FF, U+304, U+308, U+329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Rubik Variable;
  font-style: normal;
  font-display: swap;
  font-weight: 300 900;
  src: url("rubik-latin-wght-normal.65e4a59f.woff2") format("woff2-variations");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Rubik Variable;
  font-style: italic;
  font-display: swap;
  font-weight: 300 900;
  src: url("rubik-arabic-wght-italic.a7bb60c7.woff2") format("woff2-variations");
  unicode-range: U+6??, U+750-77F, U+870-88E, U+890-891, U+897-8E1, U+8E3-8FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+102E0-102FB, U+10E60-10E7E, U+10EC2-10EC4, U+10EFC-10EFF, U+1EE00-1EE03, U+1EE05-1EE1F, U+1EE21-1EE22, U+1EE24, U+1EE27, U+1EE29-1EE32, U+1EE34-1EE37, U+1EE39, U+1EE3B, U+1EE42, U+1EE47, U+1EE49, U+1EE4B, U+1EE4D-1EE4F, U+1EE51-1EE52, U+1EE54, U+1EE57, U+1EE59, U+1EE5B, U+1EE5D, U+1EE5F, U+1EE61-1EE62, U+1EE64, U+1EE67-1EE6A, U+1EE6C-1EE72, U+1EE74-1EE77, U+1EE79-1EE7C, U+1EE7E, U+1EE80-1EE89, U+1EE8B-1EE9B, U+1EEA1-1EEA3, U+1EEA5-1EEA9, U+1EEAB-1EEBB, U+1EEF0-1EEF1;
}

@font-face {
  font-family: Rubik Variable;
  font-style: italic;
  font-display: swap;
  font-weight: 300 900;
  src: url("rubik-cyrillic-ext-wght-italic.d1d4a43d.woff2") format("woff2-variations");
  unicode-range: U+460-52F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Rubik Variable;
  font-style: italic;
  font-display: swap;
  font-weight: 300 900;
  src: url("rubik-cyrillic-wght-italic.26073e2d.woff2") format("woff2-variations");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Rubik Variable;
  font-style: italic;
  font-display: swap;
  font-weight: 300 900;
  src: url("rubik-hebrew-wght-italic.58ba877f.woff2") format("woff2-variations");
  unicode-range: U+307-308, U+590-5FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: Rubik Variable;
  font-style: italic;
  font-display: swap;
  font-weight: 300 900;
  src: url("rubik-latin-ext-wght-italic.79ba766c.woff2") format("woff2-variations");
  unicode-range: U+100-2BA, U+2BD-2C5, U+2C7-2CC, U+2CE-2D7, U+2DD-2FF, U+304, U+308, U+329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Rubik Variable;
  font-style: italic;
  font-display: swap;
  font-weight: 300 900;
  src: url("rubik-latin-wght-italic.df508a04.woff2") format("woff2-variations");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.leaflet-pane, .leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-tile-container, .leaflet-pane > svg, .leaflet-pane > canvas, .leaflet-zoom-box, .leaflet-image-layer, .leaflet-layer {
  position: absolute;
  top: 0;
  left: 0;
}

.leaflet-container {
  overflow: hidden;
}

.leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.leaflet-tile::selection {
  background: none;
}

.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}

.leaflet-safari .leaflet-tile-container {
  -webkit-transform-origin: 0 0;
  width: 1600px;
  height: 1600px;
}

.leaflet-marker-icon, .leaflet-marker-shadow {
  display: block;
}

.leaflet-container .leaflet-overlay-pane svg {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container .leaflet-marker-pane img, .leaflet-container .leaflet-shadow-pane img, .leaflet-container .leaflet-tile-pane img, .leaflet-container img.leaflet-image-layer, .leaflet-container .leaflet-tile {
  width: auto;
  padding: 0;
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container img.leaflet-tile {
  mix-blend-mode: plus-lighter;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  touch-action: none;
  touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
  -webkit-tap-highlight-color: #33b5e566;
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  box-sizing: border-box;
  z-index: 800;
  width: 0;
  height: 0;
}

.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-overlay-pane {
  z-index: 400;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}

.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url("#default#VML");
  display: inline-block;
  position: absolute;
}

.leaflet-control {
  z-index: 800;
  pointer-events: visiblePainted;
  pointer-events: auto;
  position: relative;
}

.leaflet-top, .leaflet-bottom {
  z-index: 1000;
  pointer-events: none;
  position: absolute;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  transition: opacity .2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-animated {
  transform-origin: 0 0;
}

svg.leaflet-zoom-animated {
  will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1);
  -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1);
  transition: transform .25s cubic-bezier(0, 0, .25, 1);
}

.leaflet-zoom-anim .leaflet-tile, .leaflet-pan-anim .leaflet-tile {
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

.leaflet-interactive {
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.leaflet-crosshair, .leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}

.leaflet-popup-pane, .leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab, .leaflet-dragging .leaflet-grab .leaflet-interactive, .leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-image-layer, .leaflet-pane > svg path, .leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive, .leaflet-image-layer.leaflet-interactive, .leaflet-pane > svg path.leaflet-interactive, svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted;
  pointer-events: auto;
}

.leaflet-container {
  outline-offset: 1px;
  background: #ddd;
}

.leaflet-container a {
  color: #0078a8;
}

.leaflet-zoom-box {
  background: #ffffff80;
  border: 2px dotted #38f;
}

.leaflet-container {
  font-family: Helvetica Neue, Arial, Helvetica, sans-serif;
  font-size: .75rem;
  line-height: 1.5;
}

.leaflet-bar {
  border-radius: 4px;
  box-shadow: 0 1px 5px #000000a6;
}

.leaflet-bar a {
  text-align: center;
  color: #000;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-decoration: none;
  display: block;
}

.leaflet-bar a, .leaflet-control-layers-toggle {
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover, .leaflet-bar a:focus {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom: none;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  color: #bbb;
  background-color: #f4f4f4;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.leaflet-control-zoom-in, .leaflet-control-zoom-out {
  text-indent: 1px;
  font: bold 18px Lucida Console, Monaco, monospace;
}

.leaflet-control-layers {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 5px #0006;
}

.leaflet-control-layers-toggle {
  background-image: url("layers.760a0456.png");
  width: 36px;
  height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url("layers-2x.b7b89169.png");
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list, .leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  color: #333;
  background: #fff;
  padding: 6px 10px 6px 6px;
}

.leaflet-control-layers-scrollbar {
  padding-right: 5px;
  overflow: hidden scroll;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  font-size: 1.08333em;
  display: block;
}

.leaflet-control-layers-separator {
  border-top: 1px solid #ddd;
  height: 0;
  margin: 5px -10px 5px -6px;
}

.leaflet-default-icon-path {
  background-image: url("marker-icon.3f7d3721.png");
}

.leaflet-container .leaflet-control-attribution {
  background: #fffc;
  margin: 0;
}

.leaflet-control-attribution, .leaflet-control-scale-line {
  color: #333;
  padding: 0 5px;
  line-height: 1.4;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover, .leaflet-control-attribution a:focus {
  text-decoration: underline;
}

.leaflet-attribution-flag {
  width: 1em;
  height: .6669em;
  vertical-align: baseline !important;
  display: inline !important;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  white-space: nowrap;
  box-sizing: border-box;
  text-shadow: 1px 1px #fff;
  background: #fffc;
  border: 2px solid #777;
  border-top: none;
  padding: 2px 5px 1px;
  line-height: 1.1;
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution, .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  background-clip: padding-box;
  border: 2px solid #0003;
}

.leaflet-popup {
  text-align: center;
  margin-bottom: 20px;
  position: absolute;
}

.leaflet-popup-content-wrapper {
  text-align: left;
  border-radius: 12px;
  padding: 1px;
}

.leaflet-popup-content {
  min-height: 1px;
  margin: 13px 24px 13px 20px;
  font-size: 1.08333em;
  line-height: 1.3;
}

.leaflet-popup-content p {
  margin: 1.3em 0;
}

.leaflet-popup-tip-container {
  pointer-events: none;
  width: 40px;
  height: 20px;
  margin-top: -1px;
  margin-left: -20px;
  position: absolute;
  left: 50%;
  overflow: hidden;
}

.leaflet-popup-tip {
  pointer-events: auto;
  width: 17px;
  height: 17px;
  margin: -10px auto 0;
  padding: 1px;
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  color: #333;
  background: #fff;
  box-shadow: 0 3px 14px #0006;
}

.leaflet-container a.leaflet-popup-close-button {
  text-align: center;
  color: #757575;
  background: none;
  border: none;
  width: 24px;
  height: 24px;
  font: 16px / 24px Tahoma, Verdana, sans-serif;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
}

.leaflet-container a.leaflet-popup-close-button:hover, .leaflet-container a.leaflet-popup-close-button:focus {
  color: #585858;
}

.leaflet-popup-scrolled {
  overflow: auto;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  -ms-zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  width: 24px;
  filter: progid:DXImageTransform. Microsoft. Matrix(M11= .707107, M12= .707107, M21= -.707107, M22= .707107);
  margin: 0 auto;
}

.leaflet-oldie .leaflet-control-zoom, .leaflet-oldie .leaflet-control-layers, .leaflet-oldie .leaflet-popup-content-wrapper, .leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

.leaflet-tooltip {
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 6px;
  position: absolute;
  box-shadow: 0 1px 3px #0006;
}

.leaflet-tooltip.leaflet-interactive {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-tooltip-top:before, .leaflet-tooltip-bottom:before, .leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  pointer-events: none;
  content: "";
  background: none;
  border: 6px solid #0000;
  position: absolute;
}

.leaflet-tooltip-bottom {
  margin-top: 6px;
}

.leaflet-tooltip-top {
  margin-top: -6px;
}

.leaflet-tooltip-bottom:before, .leaflet-tooltip-top:before {
  margin-left: -6px;
  left: 50%;
}

.leaflet-tooltip-top:before {
  border-top-color: #fff;
  margin-bottom: -12px;
  bottom: 0;
}

.leaflet-tooltip-bottom:before {
  border-bottom-color: #fff;
  margin-top: -12px;
  margin-left: -6px;
  top: 0;
}

.leaflet-tooltip-left {
  margin-left: -6px;
}

.leaflet-tooltip-right {
  margin-left: 6px;
}

.leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  margin-top: -6px;
  top: 50%;
}

.leaflet-tooltip-left:before {
  border-left-color: #fff;
  margin-right: -12px;
  right: 0;
}

.leaflet-tooltip-right:before {
  border-right-color: #fff;
  margin-left: -12px;
  left: 0;
}

@media print {
  .leaflet-control {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

:root {
  --tile-size: 12px;
  --canvas-height: 480px;
  --canvas-ground: 35px;
  --canvas-baseline: calc(var(--canvas-height)  - var(--canvas-ground)  + 150px);
  --street-section-inner-height: calc(var(--canvas-height)  + 45px);
  --gallery-height: 180px;
  --left-right-inset: 50px;
  --menu-side-inset: calc(var(--left-right-inset)  - 20px);
  --border-radius-small: 2px;
  --border-radius-medium: 4px;
  --border-radius-large: 6px;
  --border-radius-pill: 999em;
  --border-radius: var(--border-radius-medium);
  --breakpoint-md: 768px;
  --color-emerald-100: #dbf8dc;
  --color-emerald-200: #cbf6d5;
  --color-emerald-300: #9ef4b6;
  --color-emerald-400: #75e499;
  --color-emerald-500: #5bc17d;
  --color-emerald-600: #51ad70;
  --color-emerald-700: #418d5a;
  --color-emerald-800: #2f6942;
  --color-emerald-900: #1c4429;
  --color-turquoise-100: #def3f9;
  --color-turquoise-200: #d2f0f8;
  --color-turquoise-300: #c1ebf7;
  --color-turquoise-400: #74d9f4;
  --color-turquoise-500: #47c3e1;
  --color-turquoise-600: #3ba6c0;
  --color-turquoise-700: #2e879c;
  --color-turquoise-800: #216475;
  --color-turquoise-900: #12414c;
  --color-copper-100: #faebe2;
  --color-copper-200: #fae5d9;
  --color-copper-300: #f9d4be;
  --color-copper-400: #f8b890;
  --color-copper-500: #f9944d;
  --color-copper-600: #df7724;
  --color-copper-700: #b6601c;
  --color-copper-800: #894611;
  --color-copper-900: #5a2c09;
  --color-midnight-100: #edeef2;
  --color-midnight-200: #e7e9ee;
  --color-midnight-300: #d8dce5;
  --color-midnight-400: #c2c9d8;
  --color-midnight-500: #a7b1c7;
  --color-midnight-600: #7b8bad;
  --color-midnight-700: #687aa2;
  --color-midnight-800: #43598b;
  --color-midnight-900: #29395e;
  --color-citrine-100: #fdf3d1;
  --color-citrine-200: #faeaa4;
  --color-citrine-300: #ffd755;
  --color-citrine-400: #ffb843;
  --color-citrine-500: #ff8300;
  --color-gray-100: #eee;
  --color-gray-200: #dcdcdc;
  --color-gray-400: #999;
  --background-dirt-color: #352d27;
  --sky-color: #a9ccdb;
  --off-white: #f7f7f7;
  --bottom-background: #d8d3cb;
  --warning-color: #dc1414;
  --segment-text: #28231e;
  --segment-hover-background: #fff3;
  --info-bubble-background: white;
  --interactive-text-color: var(--color-turquoise-700);
  --interactive-text-hover-color: var(--color-turquoise-800);
  --interactive-text-active-color: var(--color-turquoise-600);
  --interactive-element-hover-color: var(--color-turquoise-100);
  --form-element-background: var(--color-turquoise-100);
  --form-element-border: var(--color-turquoise-200);
  --button-border: 1px solid var(--color-turquoise-600);
  --alert-background: var(--color-citrine-100);
  --alert-border: 5px solid var(--color-citrine-300);
  --alert-text-color: var(--color-citrine-500);
  --social-discord: #5865f2;
  --social-discord-hover: color-mix(in oklch, black 10%, var(--social-discord));
  --social-facebook: #1877f2;
  --social-facebook-hover: color-mix(in oklch, black 10%, var(--social-facebook));
  --social-github: #171515;
  --social-github-hover: color-mix(in oklch, black 10%, var(--social-github));
  --social-instagram: #e1306c;
  --social-instagram-hover: #c13584;
  --social-bluesky: #009bff;
  --social-bluesky-hover: color-mix(in oklch, black 10%, var(--social-bluesky));
  --social-mastodon: #6364ff;
  --social-mastodon-hover: #563acc;
  --social-twitter: #4a99e9;
  --social-twitter-hover: color-mix(in oklch, black 10%, var(--social-twitter));
  --light-box-shadow: 0 0 14px 0 #00000013;
  --medium-box-shadow: 0 0 10px 0 #0000001a;
  --heavy-box-shadow: 0 0 10px 0 #0003;
  --street-name-size: 1.5;
  --street-name-size-small: .5;
  --font-family: "Rubik Variable", system-ui, sans-serif;
  --font-size-base: 13px;
  --header-font-family: "Manrope Variable", system-ui, sans-serif;
  --segment-switching-time: .25s;
  --skybox-transition: .5s ease;
  --z-index-01: 100;
  --z-index-02: 200;
  --z-index-03: 300;
  --z-index-04: 400;
  --z-index-05: 500;
  --z-index-06: 600;
  --z-index-07: 700;
  --z-index-08: 800;
  --z-index-09: 900;
  --z-index-10: 1000;
  --z-01-main-screen: var(--z-index-01);
  --z-01-street-section: var(--z-index-01);
  --z-01-foreground: var(--z-index-01);
  --z-02-segment-focused: var(--z-index-02);
  --z-02-palette: var(--z-index-02);
  --z-02-menu-bar: var(--z-index-02);
  --z-03-street-name: var(--z-index-03);
  --z-03-gallery-message: var(--z-index-03);
  --z-04-drag-layer: var(--z-index-04);
  --z-05-welcome: var(--z-index-05);
  --z-06-info-bubble: var(--z-index-06);
  --z-06-drag-handle: var(--z-index-06);
  --z-07-street-scroll: var(--z-index-07);
  --z-07-toasts: var(--z-index-07);
  --z-07-trashcan: var(--z-index-07);
  --z-07-resize-guide: var(--z-index-07);
  --z-07-debug-hover-polygon: var(--z-index-07);
  --z-07-sky-picker: calc(var(--z-index-07)  + 50);
  --z-07-menu: calc(var(--z-index-07)  + 75);
  --z-08-dialog-box-backdrop: var(--z-index-08);
  --z-08-gallery-shield: var(--z-index-08);
  --z-08-notification-bar: var(--z-index-08);
  --z-09-gallery: var(--z-index-09);
  --z-09-dialog-box: var(--z-index-09);
  --z-09-debug: var(--z-index-09);
  --z-09-loading: var(--z-index-09);
  --z-09-error: var(--z-index-09);
  --z-09-blocking-shield: var(--z-index-09);
  --z-10-flash-border: var(--z-index-10);
  --z-10-switch-away: var(--z-index-10);
  --z-10-print: var(--z-index-10);
}

html {
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  overscroll-behavior: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  background: #fff;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}

body:not(.safari) {
  perspective: 500px;
}

button {
  appearance: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  background-color: #0000;
  border: 0;
}

input, textarea, button, select, a {
  -webkit-tap-highlight-color: transparent;
}

a, .link-like {
  cursor: pointer;
  color: var(--interactive-text-color);
}

:is(a, .link-like):hover {
  color: var(--interactive-text-hover-color);
}

:is(a, .link-like):active {
  color: var(--interactive-text-active-color);
}

.link-like {
  text-decoration: underline;
}

input[type="text"], textarea {
  padding: 3px;
}

[disabled]:is(input[type="text"], textarea) {
  background: var(--color-gray-100) !important;
  border-color: var(--color-gray-200) !important;
}

hr {
  border: 0;
  border-top: 1px solid var(--color-turquoise-500);
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--header-font-family);
}

.underline {
  text-decoration: underline;
}

html {
  font-size: var(--font-size-base);
}

body, input, select, option {
  font-family: var(--font-family);
  font-size: 1rem;
}

b, strong {
  font-weight: 550;
}

html[lang="ko"] {
  word-break: keep-all;
}

h1 {
  margin: 0;
  font-size: 250%;
  font-weight: 300;
  line-height: 1;
}

.app {
  flex-direction: column;
  display: flex;
  position: absolute;
  inset: 0;
}

.main-screen {
  z-index: var(--z-01-main-screen);
  flex-grow: 1;
  position: relative;
}

body:not(.safari) .main-screen {
  perspective: 2000px;
}

.streetmix-logo {
  background-image: url("logo_main.9383770c.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: relative;
}

.streetmix-logo-horizontal {
  background-image: url("logo_horizontal.95f79ca4.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: relative;
}

.streetmix-wordmark {
  background-image: url("wordmark_green.5a6505bf.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: relative;
}

body.phone #error, body.phone #loading {
  font-size: 2rem;
}

#svg {
  display: none;
}

#error, #loading {
  color: var(--color-turquoise-900);
}

#error {
  z-index: var(--z-09-error);
  background-color: var(--color-turquoise-200);
  display: table;
}

.error-content {
  text-align: center;
  vertical-align: middle;
  line-height: 1.6;
  display: table-cell;
  position: relative;
}

.error-content a {
  color: #000;
  white-space: nowrap;
}

.error-content button + button {
  margin-left: 1em;
}

.error-content .error-text {
  margin-top: 4em;
  margin-bottom: 4em;
}

.error-content .error-help-link {
  margin-top: 2em;
  font-size: .85em;
}

:is(.error-content .error-help-link) a, :is(.error-content .error-help-link) a:visited, :is(.error-content .error-help-link) a:active {
  color: var(--color-turquoise-900);
}

:is(.error-content .error-help-link) a:hover {
  color: var(--color-turquoise-800);
}

#loading {
  z-index: var(--z-09-loading);
  opacity: 1;
  will-change: opacity;
  background-color: var(--color-emerald-200);
  transition: opacity .12s;
}

#loading.hidden {
  opacity: 0;
  pointer-events: none;
}

#loading .loading-stuck {
  background-color: var(--alert-background);
  border-bottom: var(--alert-border);
  border-radius: var(--border-radius-medium);
  opacity: 0;
  box-shadow: var(--medium-box-shadow);
  padding: 1em;
  transition: opacity .12s, transform .15s ease-out;
  position: absolute;
  bottom: 20px;
  transform: translateY(10px);
}

#loading .streetmix-logo {
  margin-top: -25px;
  margin-bottom: 50px;
}

#loading .streetmix-logo, .error-content .streetmix-logo {
  background-position: center;
  width: 80%;
  height: 100px;
  max-height: 100px;
}

.error-content .streetmix-logo {
  height: 60px;
  margin: 0 auto;
}

#loading .loading-spinner:before {
  border-bottom-color: #ffffffbf;
  border-left-color: #ffffffbf;
  border-right-color: #ffffffbf;
}

#error, #loading, .blocking-shield {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 50px;
  font-size: 1.2rem;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.debug-hover-polygon {
  z-index: var(--z-07-debug-hover-polygon);
  pointer-events: none;
  position: absolute;
  inset: 0;
}

.debug-hover-polygon canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.menu {
  --menu-border-radius: var(--border-radius);
  --menu-box-shadow: var(--medium-box-shadow);
  touch-action: none;
  opacity: 0;
  transform-origin: 50% 0;
  pointer-events: none;
  border-radius: var(--menu-border-radius);
  box-shadow: var(--menu-box-shadow);
  background-color: #fff;
  max-height: calc(100% - 100px);
  margin-top: 7px;
  padding: 5px;
  line-height: 1.4;
  transition: transform .1s, opacity .1s;
  position: absolute;
  overflow: hidden auto;
  transform: rotateX(-90deg);
}

.menu.menu-visible-enter-done {
  opacity: 1;
  pointer-events: auto;
  transform: rotateX(0);
}

.menu.menu-visible-exit {
  opacity: 0;
  transform: rotateX(-90deg);
}

.menu > a {
  color: #000;
  border-radius: var(--border-radius);
  padding: .5em 1em .5em 2.5em;
  text-decoration: none;
  display: block;
  position: relative;
}

:is(.menu > a):hover {
  background-color: var(--interactive-element-hover-color);
}

:is(.menu > a):active {
  color: var(--interactive-text-active-color);
}

[dir="rtl"] :is(.menu > a) {
  padding-left: 1em;
  padding-right: 2.5em;
}

.menu input {
  appearance: none;
  background: var(--form-element-background);
  border: 1px solid var(--form-element-border);
  resize: none;
}

.menu [data-icon="external-link"] {
  vertical-align: middle;
  height: 12px;
  color: var(--color-midnight-600);
  margin-top: -2px;
  margin-left: .25em;
  position: relative;
  top: auto;
  left: auto;
}

[dir="rtl"] :is(.menu [data-icon="external-link"]) {
  margin-left: auto;
  margin-right: .25em;
}

body.safari .menu {
  transform: translateY(20px)translateZ(100px);
}

.menu-visible-enter-done:is(body.safari .menu) {
  transform: translateY(3px)rotateX(0)translateZ(100px);
}

.menu-item-group {
  border-top: 1px solid var(--color-turquoise-200);
  border-bottom: 1px solid var(--color-turquoise-200);
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu-item-group:first-child {
  border-top: 0;
}

.menu-item-group:last-child {
  border-bottom: 0;
}

.menu-item-group + .menu-item-group {
  border-top: 0;
}

.menu-item {
  cursor: pointer;
  border-radius: var(--border-radius);
  padding: .5em 2em;
  display: block;
  position: relative;
}

.menu-item:hover {
  background-color: var(--interactive-element-hover-color);
}

.menu-item:active {
  color: var(--interactive-text-active-color);
}

.menu-item.menu-item-selected [data-icon="check"] {
  display: inline-block;
}

.menu-item .loading-spinner {
  position: absolute;
  top: 8px;
  left: 8px;
}

[dir="rtl"] :is(.menu-item .loading-spinner) {
  left: auto;
  right: 8px;
}

.menu-item-icon {
  width: 16px;
  height: 16px;
  color: var(--color-midnight-800);
  position: absolute;
  top: .5em;
  left: .8em;
}

[dir="rtl"] .menu-item-icon {
  left: auto;
  right: .8em;
}

.menu-item-icon[data-icon-source="io5"] {
  top: .55em;
  left: .75em;
}

[dir="rtl"] .menu-item-icon[data-icon-source="io5"] {
  left: auto;
  right: .75em;
}

.menu-item-icon[data-icon-source="radix"] {
  width: 15px;
  height: 15px;
  top: .6em;
}

[dir="rtl"] .menu-item-icon[data-icon-source="radix"] {
  left: auto;
  right: .8em;
}

.menu-item-icon[data-icon="check"] {
  color: var(--color-turquoise-500);
  margin-top: 1px;
  display: none;
  left: .5em;
}

[dir="rtl"] .menu-item-icon[data-icon="check"] {
  left: auto;
  right: .5em;
}

.menu-item-icon[data-icon="discord"] {
  color: var(--social-discord);
}

.menu-item-icon[data-icon="facebook"] {
  color: var(--social-facebook);
}

.menu-item-icon[data-icon="github"] {
  color: var(--social-github);
}

.menu-item-icon[data-icon="mastodon"] {
  color: var(--social-mastodon);
}

.menu-item-icon[data-icon="twitter"] {
  color: var(--social-twitter);
}

.menu-item-subtext {
  color: gray;
  font-size: .8em;
  display: block;
}

.menu-separator {
  background-color: var(--color-turquoise-300);
  height: 1px;
  margin: 5px 0;
}

.key {
  color: #404040;
  text-shadow: 0 1px #fff;
  white-space: nowrap;
  background: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0 .25em;
  padding: .2em .75em;
  font-family: Menlo, monospace;
  font-size: .8em;
  line-height: 1.8;
  display: inline-block;
  position: relative;
  box-shadow: 0 2px #0003, inset 0 1px #fff;
}

.key-icon {
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 0;
  display: inline-flex;
}

.help-menu-shortcuts {
  max-width: 360px;
  padding: .8em 1em 1em 2.5em;
  position: relative;
}

[dir="rtl"] .help-menu-shortcuts {
  padding-left: 1em;
  padding-right: 2.5em;
}

.help-menu-shortcuts p:first-child {
  margin-top: 0;
}

.help-menu-shortcuts table {
  border-collapse: collapse;
  margin-top: .5em;
}

:is(.help-menu-shortcuts table) td {
  vertical-align: top;
  padding: .25em;
}

:is(.help-menu-shortcuts table) td:first-child {
  min-width: 60px;
  padding-left: 0;
}

[dir="rtl"] :is(:is(.help-menu-shortcuts table) td:first-child) {
  padding-left: .25em;
  padding-right: 0;
}

.help-menu-shortcuts [data-icon="keyboard"] {
  top: .9em;
}

.avatar {
  vertical-align: middle;
  background-color: #7f7f7f1a;
  background-size: 100%;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-left: 3px;
  margin-right: 5px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.avatar object {
  width: 100%;
  height: 100%;
}

.avatar img {
  border: 1px solid #fff;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.identity-menu {
  white-space: nowrap;
}

.identity-section {
  min-width: 150px;
  max-width: 300px;
  padding: .5em .25em;
}

.identity-avatar-name {
  flex-direction: row;
  display: flex;
}

.identity-avatar-name-left {
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: .5em;
  display: flex;
}

[dir="rtl"] .identity-avatar-name-left {
  margin-left: .5em;
  margin-right: 0;
}

.identity-avatar-name-left .avatar {
  width: 36px;
  height: 36px;
  margin: 0;
}

.identity-avatar-name-right {
  white-space: normal;
  flex-direction: column;
  justify-content: center;
  line-height: 1.2;
  display: flex;
}

.identity-avatar-display-name {
  color: var(--color-turquoise-900);
  font-weight: 550;
}

.identity-roles {
  margin-top: .5em;
  margin-bottom: -.5em;
}

.identity-roles ul {
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  display: flex;
}

.identity-roles ul li {
  border-radius: var(--border-radius-medium);
  justify-content: center;
  align-items: center;
  margin-bottom: .35em;
  margin-right: .35em;
  padding: .3em .6em;
  font-size: .9em;
  font-weight: 550;
  display: inline-flex;
}

[dir="rtl"] :is(.identity-roles ul li) {
  margin-left: .35em;
  margin-right: 0;
}

.identity-roles .role-badge-generic {
  background-color: var(--color-midnight-100);
  color: var(--color-midnight-800);
}

.identity-roles .role-badge-subscriber {
  background-color: var(--color-turquoise-200);
  color: var(--color-turquoise-900);
}

:is(.identity-roles .role-badge-subscriber) img {
  height: 1em;
  margin-right: .35em;
}

[dir="rtl"] :is(:is(.identity-roles .role-badge-subscriber) img) {
  margin-left: .35em;
  margin-right: auto;
}

.identity-roles .role-badge-admin {
  background-color: var(--color-citrine-300);
  color: var(--color-copper-900);
}

.menu-sign-out {
  color: var(--warning-color) !important;
}

.menu-sign-out:hover {
  background-color: var(--color-gray-100) !important;
}

.menu-sign-out svg {
  color: var(--warning-color) !important;
}

.loading-spinner {
  --spinner-default-size: 30px;
  --spinner-small-size: 13px;
  --spinner-border-default-width: 3px;
  --spinner-border-small-width: 2px;
  --spinner-primary-color: var(--color-turquoise-600);
  --spinner-secondary-color: var(--color-turquoise-200);
  width: var(--spinner-default-size);
  height: var(--spinner-default-size);
  position: relative;
}

.loading-spinner:before {
  content: "";
  border-width: var(--spinner-border-default-width);
  border-style: solid;
  border-color: var(--spinner-secondary-color);
  border-top-color: var(--spinner-primary-color);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: .75s linear infinite spinner;
  position: absolute;
  top: 0;
  left: 0;
}

.loading-spinner-small {
  width: var(--spinner-small-size);
  height: var(--spinner-small-size);
}

.loading-spinner-small:before {
  border-width: var(--spinner-border-small-width);
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.btn, a.btn {
  border-radius: var(--border-radius-medium);
  background-color: var(--color-turquoise-300);
  color: var(--color-midnight-900);
  text-align: center;
  cursor: pointer;
  border: 0;
  outline: none;
  padding: .75em 2em;
  font-weight: 550;
  text-decoration: none;
  display: inline-block;
}

:is(.btn, a.btn):hover, :is(.btn, a.btn):active {
  background-color: var(--color-turquoise-400);
}

[disabled]:is(.btn, a.btn) {
  background-color: var(--color-gray-100);
  color: var(--color-gray-400);
  cursor: auto;
}

[disabled]:is(.btn, a.btn):hover {
  background-color: var(--color-gray-100);
}

.btn-primary:is(.btn, a.btn) {
  background-color: var(--color-emerald-500);
  color: #fff;
}

.btn-primary:is(.btn, a.btn):hover {
  background-color: var(--color-emerald-600);
}

.btn-secondary:is(.btn, a.btn) {
  background-color: var(--color-turquoise-500);
  color: #fff;
}

.btn-secondary:is(.btn, a.btn):hover {
  background-color: var(--color-turquoise-600);
}

.btn-tertiary:is(.btn, a.btn) {
  color: var(--interactive-text-color);
  background-color: #0000;
}

.btn-tertiary:is(.btn, a.btn):not(:disabled) {
  border: 1px solid var(--color-turquoise-500);
}

.btn-tertiary:is(.btn, a.btn):hover {
  background-color: var(--interactive-element-hover-color);
  color: var(--interactive-text-hover-color);
}

.btn-tertiary:is(.btn, a.btn):active {
  color: var(--interactive-text-active-color);
}

.share-sign-in-promo {
  text-align: center;
  background-color: var(--alert-background);
  border-bottom: var(--alert-border);
  border-bottom-width: 3px;
  width: calc(100% + 10px);
  margin-top: -5px;
  margin-left: -5px;
  padding: 1em 2.5em;
}

.share-sign-in-promo a {
  text-decoration: underline;
}

.share-menu {
  --share-menu-width: 350px;
  width: var(--share-menu-width);
}

.share-via-link-container {
  padding: .8em 1em .8em 2.5em;
  position: relative;
}

[dir="rtl"] .share-via-link-container {
  padding: .8em 2.5em .8em 1em;
}

.share-via-link-container [data-icon="link"] {
  top: .9em;
}

.share-via-link-form {
  flex-direction: row;
  margin-top: .5em;
  display: flex;
}

.share-via-link-form input {
  flex-grow: 1;
}

.share-via-link-form button {
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  display: flex;
}

.share-via-link-form [data-icon="copy"] {
  position: relative;
  top: auto;
  left: auto;
}

.share-via-link {
  outline: none;
  margin-right: .5em;
  line-height: 18px;
}

[dir="rtl"] .share-via-link {
  margin-left: .5em;
  margin-right: auto;
}

.instance-badge {
  z-index: var(--z-02-menu-bar);
  text-transform: uppercase;
  letter-spacing: .5em;
  color: #fff;
  pointer-events: none;
  border-radius: var(--border-radius);
  background-color: gray;
  padding: .25em .5em .25em 1em;
  font-size: .75rem;
  font-weight: 550;
  line-height: 1.5;
  display: none;
  position: absolute;
  bottom: -14px;
  left: 9px;
}

.instance-badge.instance-label-development {
  background-color: #c80000;
  display: inline-block;
}

.instance-badge.instance-label-staging, .instance-badge.instance-label-sandbox {
  background-color: green;
  display: inline-block;
}

.instance-badge.instance-label-demo {
  background-color: #1cbdd1;
  display: inline-block;
}

[dir="rtl"] .instance-badge {
  left: auto;
  right: 9px;
}

.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-box {
  color: #fff;
  white-space: normal;
  background-color: #333;
  border-radius: 4px;
  outline: 0;
  font-size: 14px;
  line-height: 1.4;
  transition-property: transform, visibility, opacity;
  position: relative;
}

.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}

.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: top;
  bottom: -7px;
  left: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: bottom;
  top: -7px;
  left: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  transform-origin: 0;
  right: -7px;
}

.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}

.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: 100%;
  left: -7px;
}

.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11);
}

.tippy-arrow {
  color: #333;
  width: 16px;
  height: 16px;
}

.tippy-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.tippy-content {
  z-index: 1;
  padding: 5px 9px;
  position: relative;
}

.tippy-box[data-animation="shift-toward"][data-state="hidden"] {
  opacity: 0;
}

.tippy-box[data-animation="shift-toward"][data-state="hidden"][data-placement^="top"] {
  transform: translateY(-10px);
}

.tippy-box[data-animation="shift-toward"][data-state="hidden"][data-placement^="bottom"] {
  transform: translateY(10px);
}

.tippy-box[data-animation="shift-toward"][data-state="hidden"][data-placement^="left"] {
  transform: translateX(-10px);
}

.tippy-box[data-animation="shift-toward"][data-state="hidden"][data-placement^="right"] {
  transform: translateX(10px);
}

.tippy-box {
  --tooltip-background-color: #2a2b2a;
  --tooltip-font-color: white;
  --tooltip-font-weight: 550;
  --tooltip-max-width: 150px;
  background-color: var(--tooltip-background-color);
  font-weight: var(--tooltip-font-weight);
  color: var(--tooltip-font-color);
  border-radius: var(--border-radius);
  text-align: center;
  max-width: var(--tooltip-max-width);
  box-shadow: var(--medium-box-shadow);
  opacity: .85;
  padding: .5em 1.25em;
}

.tippy-content {
  padding: 0;
}

.tippy-content p {
  opacity: .75;
  margin: 0;
  font-size: .85em;
  font-weight: normal;
}

.tippy-arrow {
  color: var(--tooltip-background-color);
}

.menu-bar .menu-sign-in {
  margin-left: .5em;
  margin-right: .1em;
  padding: .5em 1em;
}

[dir="rtl"] :is(.menu-bar .menu-sign-in) {
  margin-left: .1em;
  margin-right: .5em;
}

.menu-bar .menu-upgrade {
  align-items: center;
  margin-left: .75em;
  margin-right: 0;
  padding: .5em 1em;
  display: flex;
}

[dir="rtl"] :is(.menu-bar .menu-upgrade) {
  margin-left: 0;
  margin-right: .75em;
}

:is(.menu-bar .menu-upgrade) img {
  margin-left: 0;
  margin-right: .5em;
}

[dir="rtl"] :is(:is(.menu-bar .menu-upgrade) img) {
  margin-left: .5em;
  margin-right: 0;
}

.menu-avatar {
  justify-content: center;
  align-items: center;
  width: 50px;
  padding: 0;
  display: flex;
}

.menu-avatar .avatar {
  width: 2rem;
  height: 2rem;
  margin: 0;
  position: relative;
  left: -2px;
}

[dir="rtl"] :is(.menu-avatar .avatar) {
  left: 1px;
}

.menu-avatar .menu-avatar-badge {
  position: absolute;
  top: 1px;
  left: calc(50% + 4px);
}

[dir="rtl"] :is(.menu-avatar .menu-avatar-badge) {
  left: calc(50% + 6px);
}

.menu-avatar-subscriber {
  height: 1em;
}

.menu-bar {
  --menu-bar-text-color: var(--segment-text);
  --menu-bar-text-color-active: var(--interactive-text-active-color);
  --menu-bar-border-radius: var(--border-radius);
  --menu-bar-background: var(--off-white);
  --menu-bar-box-shadow: var(--light-box-shadow);
  z-index: var(--z-02-menu-bar);
  left: var(--menu-side-inset);
  right: var(--menu-side-inset);
  border-bottom-left-radius: var(--menu-bar-border-radius);
  border-bottom-right-radius: var(--menu-bar-border-radius);
  background: var(--menu-bar-background);
  box-shadow: var(--menu-bar-box-shadow);
  -webkit-user-select: none;
  user-select: none;
  touch-action: none;
  background-clip: padding-box;
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
  position: absolute;
  top: 0;
}

.menu-bar:before {
  content: "";
  background-color: var(--menu-bar-background);
  width: 100%;
  height: 150px;
  position: absolute;
  top: -150px;
}

body.read-only .menu-bar {
  display: none;
}

.menu-bar ul {
  color: var(--menu-bar-text-color);
  margin: 0;
  padding: 3px 1em;
  line-height: 32px;
  list-style: none;
  display: flex;
}

.menu-bar li {
  white-space: nowrap;
  display: inline-block;
}

.menu-bar a {
  color: inherit;
  align-items: center;
  height: 32px;
  padding: 0 10px;
  text-decoration: none;
  display: flex;
  position: relative;
}

:is(.menu-bar a):active {
  color: var(--menu-bar-text-color-active);
}

:is(.menu-bar a):hover:after {
  content: "";
  pointer-events: none;
  background-color: #0000000d;
  border-radius: 6px;
  width: calc(100% + 8px);
  height: 100%;
  position: absolute;
  top: 0;
  left: -4px;
}

.menu-bar button.menu-attached {
  appearance: none;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  cursor: pointer;
  background-color: #0000;
  border: 0;
  align-items: center;
  height: 32px;
  padding: 0 10px;
  display: flex;
  position: relative;
}

:is(.menu-bar button.menu-attached) svg {
  margin-left: .25em;
}

[dir="rtl"] :is(:is(.menu-bar button.menu-attached) svg) {
  margin-left: 0;
  margin-right: .25em;
}

:is(.menu-bar button.menu-attached):active {
  color: var(--menu-bar-text-color-active);
}

:is(.menu-bar button.menu-attached):hover:after {
  content: "";
  pointer-events: none;
  background-color: #0000000d;
  border-radius: 6px;
  width: calc(100% + 8px);
  height: 100%;
  position: absolute;
  top: 0;
  left: -4px;
}

.menu-bar-left {
  padding-left: .25em !important;
  padding-right: .25em !important;
}

.menu-bar-left li:nth-child(2) button.menu-attached:hover:after {
  width: calc(100% + 4px);
  left: 0;
}

[dir="rtl"] :is() {
  left: auto;
  right: 0;
}

.menu-bar-right {
  padding-left: .25em !important;
  padding-right: .25em !important;
}

.menu-bar-right li:last-child button.menu-attached:hover:after {
  width: calc(100% + 4px);
}

[dir="rtl"] :is() {
  left: 0;
}

.menu-bar-title {
  justify-content: center;
  align-items: center;
  margin-left: .45em;
  margin-right: .25em;
  display: flex !important;
}

[dir="rtl"] .menu-bar-title {
  margin-left: .25em;
  margin-right: .45em;
}

.menu-bar-title h1 {
  font-size: 100%;
  display: none;
}

.menu-bar-title .menu-bar-logo {
  height: 20px;
  position: relative;
  top: -1px;
}

.menu-external-link {
  margin-left: .25em;
  margin-right: -.2em;
  transform: scale(.8);
}

[dir="rtl"] .menu-external-link {
  margin-left: -.2em;
  margin-right: .25em;
}

.menu-carat-down {
  margin-left: .25em;
  margin-right: -.25em;
}

[dir="rtl"] .menu-carat-down {
  margin-left: -.25em;
  margin-right: .25em;
}

.menus-container {
  inset: 0 var(--menu-side-inset);
  pointer-events: none;
  z-index: var(--z-07-menu);
  transform-style: preserve-3d;
  position: absolute;
}

.street-name {
  --street-name-text: black;
  --street-name-background: white;
  padding: calc(3px * var(--street-name-size));
  background: var(--street-name-background);
  color: var(--street-name-text);
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  display: inline-block;
}

.street-name-text {
  text-align: left;
  text-overflow: ellipsis;
  height: calc(50px * var(--street-name-size));
  border: calc(3px * var(--street-name-size)) solid var(--street-name-text);
  padding: calc(11px * var(--street-name-size)) calc(20px * var(--street-name-size)) calc(5px * var(--street-name-size));
  font-family: Overpass Variable, sans-serif;
  font-weight: 700;
  font-size: calc(32px * var(--street-name-size));
  line-height: calc(28px * var(--street-name-size));
  letter-spacing: -.025em;
  overflow: hidden;
}

.street-name-text [dir="rtl"] {
  font-size: calc(30px * var(--street-name-size));
}

body.segment-move-dragging .street-nameplate-container .street-name {
  pointer-events: none;
}

.street-nameplate-container .street-name {
  pointer-events: auto;
  max-width: 100%;
  position: relative;
}

.street-name-hover-prompt {
  color: #000;
  cursor: pointer;
  white-space: normal;
  background-color: #fffc;
  justify-content: center;
  align-items: center;
  padding: 1em;
  display: flex;
  position: absolute;
  inset: 0;
}

.street-meta-item {
  white-space: nowrap;
  align-items: center;
  margin-bottom: .2em;
  display: flex;
}

.street-meta-item button {
  cursor: pointer;
  border-radius: var(--border-radius-large);
  justify-content: center;
  align-items: center;
  height: 2rem;
  padding: 0 .5em;
  display: flex;
}

:is(.street-meta-item button):hover {
  background-color: #fff3;
}

.street-meta-item a {
  color: inherit;
}

body.read-only :is(.street-meta-item a) {
  cursor: default;
  text-decoration: none;
}

.street-meta-item-plain {
  justify-content: center;
  align-items: center;
  height: 2rem;
  padding: 0 .5em;
  display: flex;
}

.street-meta-item-plain .underline {
  text-decoration: none;
}

.street-meta-icon {
  justify-content: center;
  align-items: center;
  margin-right: .3em;
  display: flex;
}

.street-meta-icon svg {
  width: 16px;
  height: 16px;
}

[dir="rtl"] .street-meta-icon {
  margin-left: .3em;
  margin-right: auto;
}

.street-meta-icon [data-icon-source="feather"] {
  stroke-width: 1.5px;
}

.street-meta-content {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.street-width {
  align-items: center;
  display: inline-flex;
}

.street-width .street-meta-icon svg {
  width: 15px;
  height: 15px;
}

.street-width .menu-carat-down {
  transform: scale(.85);
}

.street-width-over {
  color: var(--warning-color);
  margin-left: .25em;
}

[dir="rtl"] .street-width-over {
  margin-left: 0;
  margin-right: .25em;
}

.street-width-under {
  color: #606060;
  transition: color var(--skybox-transition);
  margin-left: .25em;
}

[dir="rtl"] .street-width-under {
  margin-left: 0;
  margin-right: .25em;
}

body.dark-skybox-invert-ui .street-width-under {
  color: #a0a0a0;
}

[data-radix-popper-content-wrapper] {
  z-index: 900 !important;
}

.dropdown-menu-content {
  --menu-border-radius: var(--border-radius);
  --menu-box-shadow: var(--medium-box-shadow);
  transform-origin: 50% 0;
  border-radius: var(--menu-border-radius);
  box-shadow: var(--menu-box-shadow);
  will-change: transform, opacity;
  background-color: #fff;
  padding: 5px;
  line-height: 1.4;
  animation-duration: .12s;
}

.dropdown-menu-content[data-side="bottom"] {
  animation-name: swing-down;
}

body.safari .dropdown-menu-content[data-side="bottom"] {
  animation-name: shift-down;
}

.dropdown-menu-item, .dropdown-menu-checkbox-item, .dropdown-menu-radio-item {
  -webkit-user-select: none;
  user-select: none;
  color: #000;
  border-radius: var(--border-radius);
  cursor: pointer;
  outline: none;
  padding: .5em 1em .5em 1.5em;
  text-decoration: none;
}

[dir="rtl"] :is(.dropdown-menu-item, .dropdown-menu-checkbox-item, .dropdown-menu-radio-item) {
  padding: .5em 1.5em .5em 1em;
}

.dropdown-menu-item[data-disabled], .dropdown-menu-checkbox-item[data-disabled], .dropdown-menu-radio-item[data-disabled] {
  color: #999;
  pointer-events: none;
}

.dropdown-menu-item[data-highlighted], .dropdown-menu-checkbox-item[data-highlighted], .dropdown-menu-radio-item[data-highlighted] {
  background-color: var(--interactive-element-hover-color);
}

.dropdown-menu-checkbox-item[data-state="checked"], .dropdown-menu-radio-item[data-state="checked"] {
  font-weight: 500;
}

.dropdown-menu-label {
  color: var(--color-turquoise-700);
  padding: .5em 1em .5em 1.5em;
  font-weight: 500;
}

[dir="rtl"] .dropdown-menu-label {
  padding: .5em 1.5em .5em 1em;
}

.dropdown-menu-item-indicator {
  color: var(--color-turquoise-500);
  justify-content: center;
  align-items: center;
  margin-top: 3px;
  display: inline-flex;
  position: absolute;
  left: .65em;
}

[dir="rtl"] .dropdown-menu-item-indicator {
  left: auto;
  right: .65em;
}

.dropdown-menu-arrow {
  fill: #fff;
}

@keyframes swing-down {
  from {
    opacity: 0;
    transform: rotateX(-90deg);
  }

  to {
    opacity: 1;
    transform: rotateX(0);
  }
}

@keyframes shift-down {
  from {
    opacity: 0;
    transform: translateY(40px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.street-meta {
  color: #000;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: auto;
  transition: color var(--skybox-transition);
  flex-wrap: wrap;
  justify-content: center;
  padding-top: .5em;
  font-size: .925rem;
  display: flex;
}

.street-meta > div:not(:first-child) {
  margin-left: .25em;
}

body.dark-skybox-invert-ui .street-meta {
  color: #fff;
}

.street-nameplate-container {
  z-index: var(--z-03-street-name);
  text-align: center;
  pointer-events: none;
  position: absolute;
  top: 10px;
  left: 150px;
  right: 150px;
}

.street-nameplate-container.move-down-for-menu {
  transform: translateY(20px);
}

.street-nameplate-container.no-movement {
  transition: none !important;
}

.street-nameplate-container.hidden {
  display: none;
}

body.read-only .street-nameplate-container {
  transform: translateY(0);
}

.up-down-input-element {
  width: 50px;
  font-family: var(--font-family);
  text-align: center;
  background: var(--form-element-background);
  border: 1px solid var(--form-element-border);
  vertical-align: bottom;
  border-left: 0;
  border-right: 0;
  outline: none;
  height: 30px;
  line-height: 22px;
}

.up-down-input-decrement {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.up-down-input-increment {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.up-down-input button svg {
  color: var(--color-midnight-900) !important;
}

.building-height .up-down-input {
  flex-direction: column-reverse;
  align-items: center;
  display: flex;
}

:is(.building-height .up-down-input) .up-down-input-element {
  border-left: 1px solid var(--form-element-border);
  border-right: 1px solid var(--form-element-border);
  width: 100px;
  margin-top: 5px;
  margin-bottom: 5px;
}

:is(.building-height .up-down-input) .up-down-input-decrement, :is(.building-height .up-down-input) .up-down-input-increment {
  border-radius: var(--border-radius-medium);
}

.info-bubble-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #fff0;
  border-radius: 3px;
  align-items: center;
  margin-top: .15em;
  margin-bottom: .15em;
  margin-left: -.25em;
  padding-left: .25em;
  padding-right: .25em;
  display: flex;
  overflow: hidden;
}

.info-bubble-label-editable .info-bubble-label-editable-icon {
  opacity: 0;
  margin-left: .25em;
  transition: opacity .12s ease-in;
  transform: translateZ(0);
}

[dir="rtl"] :is(.info-bubble-label-editable .info-bubble-label-editable-icon) {
  margin-left: 0;
  margin-right: .25em;
}

.info-bubble-label-editable:hover {
  cursor: text;
  background-color: var(--off-white);
  transition: background-color .12s ease-in;
}

.info-bubble-label-editable:hover .info-bubble-label-editable-icon {
  opacity: .85;
}

.info-bubble-remove {
  align-items: center;
  margin-left: 1em;
  padding: 0 .75em;
  font-size: 11px;
  display: flex;
  width: auto !important;
}

.info-bubble-remove .remove-icon {
  width: 16px;
  height: 16px;
  margin-right: .35em;
}

[dir="rtl"] :is(.info-bubble-remove .remove-icon) {
  margin-left: .35em;
  margin-right: 0;
}

.info-bubble-triangle {
  height: 24px;
  position: absolute;
  bottom: -24px;
  left: 0;
  right: 0;
  overflow: hidden;
}

.info-bubble-triangle:before {
  content: "";
  background-color: var(--info-bubble-background);
  width: 24px;
  height: 24px;
  box-shadow: var(--light-box-shadow);
  pointer-events: none;
  margin-left: -12px;
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: rotateZ(45deg);
}

.info-bubble-triangle-highlight:before {
  background-color: var(--color-turquoise-100);
}

.description-prompt, .description-close {
  border-top: 1px solid var(--color-turquoise-300);
  white-space: normal;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  color: var(--color-turquoise-700);
  cursor: pointer;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  padding: 1em 2em;
  overflow: hidden;
}

:is(.description-prompt, .description-close):hover {
  color: var(--color-turquoise-800);
  background-color: var(--color-turquoise-100);
}

.description-close {
  pointer-events: none;
}

.description-canvas {
  pointer-events: none;
  border-radius: var(--border-radius);
  box-shadow: var(--light-box-shadow);
  background-color: var(--info-bubble-background);
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  position: absolute;
  top: -50px;
  left: -50px;
  right: -50px;
}

.description {
  flex-direction: column;
  height: 100%;
  font-weight: 300;
  line-height: 1.4;
  display: flex;
  overflow: hidden;
}

.description p {
  margin: 1em 0;
}

:is(.description p):first-child {
  margin-top: 0;
}

.description footer {
  color: #a0a0a0;
  margin: 1em 0;
  font-size: .8em;
}

.description img {
  width: 100%;
  display: block;
}

.description-content {
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  touch-action: pan-y;
  overflow: hidden auto;
}

.description-text {
  padding: 1em;
  font-size: 16px;
}

.description-text h1 {
  margin-top: 0;
  font-size: 1.5em;
  font-weight: 300;
  line-height: 1.25;
}

.info-bubble {
  --info-bubble-header-background-color: var(--color-turquoise-200);
  --info-bubble-button-size: 30px;
  --info-bubble-border-radius: var(--border-radius);
  opacity: 0;
  background: var(--info-bubble-background);
  border-radius: var(--info-bubble-border-radius);
  min-width: 275px;
  box-shadow: var(--light-box-shadow);
  perspective: 1200px;
  z-index: var(--z-06-info-bubble);
  pointer-events: none;
  touch-action: none;
  transition: transform .1s, margin-top .15s, height .15s, opacity .15s;
  position: absolute;
  transform: rotateX(-80deg);
}

.info-bubble.visible {
  opacity: 1;
  pointer-events: auto;
  transform: rotateY(0);
}

.info-bubble header {
  height: calc(var(--info-bubble-button-size)  + 10px);
  line-height: calc(var(--info-bubble-button-size)  + 2px);
  color: var(--color-turquoise-900);
  background-color: var(--info-bubble-header-background-color);
  border-top-left-radius: var(--info-bubble-border-radius);
  border-top-right-radius: var(--info-bubble-border-radius);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 10px;
  font-size: 1.2em;
  font-weight: 500;
  display: flex;
  position: relative;
}

.info-bubble .icon {
  pointer-events: none;
  fill: #231f20;
  width: 100%;
  height: 100%;
  padding: 2px 3px;
  position: absolute;
  top: 0;
  left: 0;
}

.info-bubble .variants {
  text-align: left;
  min-height: var(--info-bubble-button-size);
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  margin-right: 20px;
}

:is(.info-bubble .variants) button {
  margin-bottom: 2px;
  margin-right: 3px;
}

:is(.info-bubble .variants) hr {
  opacity: 0;
  height: 5px;
  margin: 0;
  padding: 0;
  display: block;
}

:is(.info-bubble .variants) button[disabled] .icon {
  opacity: .4;
}

:is(.info-bubble .variants) [data-icon="lock"] {
  color: var(--color-copper-800);
  position: absolute;
  top: -2px;
  right: -2px;
}

[dir="rtl"] :is(:is(.info-bubble .variants) [data-icon="lock"]) {
  left: -2px;
  right: auto;
}

[dir="rtl"] :is(.info-bubble .variants) {
  text-align: right;
  direction: ltr;
  margin-left: 20px;
  margin-right: 0;
}

.info-bubble .non-variant {
  text-align: right;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
}

:is(.info-bubble .non-variant):only-child {
  text-align: center;
  width: 100%;
}

.building-height:is(.info-bubble .non-variant) {
  white-space: normal;
  text-align: center;
  width: 115px;
  height: auto;
  margin-top: -36px;
}

:is(.info-bubble .variants, .info-bubble .non-variant) button {
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: inline-flex;
  position: relative;
}

:is(:is(.info-bubble .variants, .info-bubble .non-variant) button) .icon {
  opacity: .666;
}

:is(:is(.info-bubble .variants, .info-bubble .non-variant) button):not([disabled]):active .icon {
  opacity: .9;
  margin-top: 1px;
}

.info-bubble .variant-selected, .info-bubble .variant-selected:hover {
  opacity: 1;
  background: var(--color-copper-400) !important;
}

:is(.info-bubble .variant-selected, .info-bubble .variant-selected:hover) .icon {
  opacity: 1 !important;
}

.info-bubble button {
  width: var(--info-bubble-button-size);
  height: var(--info-bubble-button-size);
}

.info-bubble-type-building header {
  border-top-right-radius: 0;
  margin-right: 140px;
}

[dir="rtl"] :is(.info-bubble-type-building header) {
  border-top-left-radius: 0;
  border-top-right-radius: var(--info-bubble-border-radius);
  margin-left: 140px;
  margin-right: 0;
}

:is(.info-bubble-type-building header):after {
  content: "";
  border-top: 40px solid var(--info-bubble-header-background-color);
  border-right: 40px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  right: -40px;
}

[dir="rtl"] :is() {
  border-left: 37px solid #0000;
  border-right: none;
  left: -37px;
  right: auto;
}

.info-bubble-controls {
  justify-content: space-between;
  padding: 10px;
  display: flex;
}

.info-bubble-controls .non-variant {
  text-align: center;
  width: auto;
}

.info-bubble-warnings {
  border-top: 1px solid var(--color-turquoise-300);
  white-space: normal;
  color: var(--warning-color);
  margin-top: 1px;
  padding: .5em 1.5em .5em 1em;
}

.info-bubble-warnings ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.info-bubble-warnings li {
  text-align: center;
  margin-bottom: .5em;
}

.info-bubble-warning-alert {
  color: var(--alert-text-color);
}

.info-bubble-warning-alert img {
  vertical-align: bottom;
  width: 1.6em;
  height: 1.6em;
  position: relative;
  top: 2px;
}

.info-bubble-warning-error img {
  vertical-align: bottom;
  width: 1.6em;
  height: 1.6em;
  position: relative;
  top: 1px;
}

.info-bubble.show-description .variants, .info-bubble.show-description .non-variant, .info-bubble.show-description .warnings, .info-bubble.show-description .description-prompt {
  pointer-events: none;
}

.info-bubble.show-description .description-close, .info-bubble.show-description .description {
  pointer-events: auto;
}

[dir="rtl"] .info-bubble-remove {
  margin-left: 0;
  margin-right: 1em;
}

button.scrollable {
  cursor: pointer;
  z-index: 1;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 40px;
  padding: 0;
  font-size: 1.5rem;
  display: flex;
  position: absolute;
  top: 0;
}

button.scrollable[disabled] {
  opacity: 0;
  pointer-events: none;
}

.palette-item {
  background-color: color-mix(in srgb, var(--sky-color), transparent 30%);
  transform-origin: 50% 75%;
  width: 60px;
  height: 80px;
  margin: 14px 1px 14px 0;
  display: inline-block;
  position: relative;
  top: 0;
  transition: transform 50ms !important;
}

.palette-item > div {
  text-align: center;
  width: 100%;
  height: 100%;
}

.palette-item:not(.palette-item-disabled):hover, .palette-item:not(.palette-item-disabled) > div:focus {
  background-color: color-mix(in srgb, var(--sky-color), transparent 5%);
  z-index: 1;
}

:is(.palette-item:not(.palette-item-disabled):hover, .palette-item:not(.palette-item-disabled) > div:focus) .palette-item-image {
  transform: scale(1.15);
}

.palette-item-image {
  transform-origin: 50% 75px;
  transition: transform 80ms cubic-bezier(0, 2.5, 1, 1);
}

@media (prefers-reduced-motion) {
  .palette-item-image {
    transition: none;
  }
}

.palette-item-disabled {
  --palette-item-disabled-saturation: 0%;
  background-color: color-mix(in hsl, var(--sky-color), hsl(none var(--palette-item-disabled-saturation) none / 70%) 100%);
}

.palette-item-disabled .palette-item-image {
  filter: grayscale(.8);
  opacity: .5;
}

.palette-item-disabled [data-icon="lock"] {
  z-index: 2;
  color: var(--color-copper-800);
  position: absolute;
  top: 4px;
  right: 4px;
}

[dir="rtl"] :is(.palette-item-disabled [data-icon="lock"]) {
  left: 4px;
  right: auto;
}

.palette-items-scrollable-container {
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  position: relative;
}

.palette-items-scrollable-container button.scrollable {
  z-index: 2;
  width: 30px;
  position: relative;
}

.scroll-right:is(.palette-items-scrollable-container button.scrollable) {
  right: -20px !important;
}

.scroll-left:is(.palette-items-scrollable-container button.scrollable) {
  left: -20px !important;
}

[dir="rtl"] .palette-items-scrollable-container {
  direction: ltr;
}

.palette-items {
  height: calc(var(--palette-height)  + 60px);
  white-space: nowrap;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  overflow: scroll hidden;
}

.palette-items ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.palette-trashcan {
  --palette-text-color: #6c6c6c;
  left: calc(-1 * var(--left-right-inset));
  right: calc(-1 * var(--left-right-inset));
  height: calc(var(--palette-height)  + 30px);
  opacity: 1;
  z-index: var(--z-07-trashcan);
  border-top: 2px dashed var(--palette-text-color);
  color: var(--palette-text-color);
  background-color: color-mix(in oklch, white 25%, var(--bottom-background));
  pointer-events: none;
  justify-content: center;
  align-items: center;
  font-size: 1.75rem;
  font-weight: 400;
  transition: transform .15s, opacity .15s;
  display: flex;
  position: absolute;
  bottom: 0;
  transform: translateY(0);
}

.palette-trashcan[hidden] {
  opacity: 0;
  transform: translateY(150px);
}

.palette-container-outer {
  --palette-height: 64px;
  --palette-background: var(--off-white);
  width: 100%;
  height: var(--palette-height);
  padding-left: var(--left-right-inset);
  padding-right: var(--left-right-inset);
  justify-content: center;
  display: flex;
  position: absolute;
  bottom: 0;
}

.palette-container {
  width: 100%;
  min-width: var(--breakpoint-md);
  background: var(--palette-background);
  z-index: var(--z-02-palette);
  -webkit-user-select: none;
  user-select: none;
  touch-action: none;
  border-top-left-radius: var(--border-radius-large);
  border-top-right-radius: var(--border-radius-large);
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  position: relative;
}

@media only screen and (width <= 768px) {
  .palette-container-outer {
    padding-left: 0;
    padding-right: 0;
  }

  .palette-container {
    border-radius: 0;
    min-width: 100%;
    left: 0;
    right: 0;
  }
}

.palette-commands {
  flex-direction: row;
  align-items: center;
  width: 120px;
  padding: 1em;
  display: flex;
}

.palette-commands button {
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
}

.palette-commands [data-icon="sun"] {
  width: 20px;
  height: 20px;
}

.palette-commands [data-icon="undo"], .palette-commands [data-icon="redo"] {
  width: 18px;
  height: 18px;
}

.palette-commands button + button {
  margin-left: .25em;
}

[dir="rtl"] :is(.palette-commands button + button) {
  margin-left: 0;
  margin-right: .25em;
}

.palette-commands-right {
  justify-content: flex-end;
}

button.close {
  --close-button-margin: 2px;
  --close-button-size: 34px;
  --close-icon-color: var(--color-turquoise-800);
  --close-icon-color-hover: var(--color-turquoise-700);
  right: var(--close-button-margin);
  top: var(--close-button-margin);
  width: var(--close-button-size);
  height: var(--close-button-size);
  appearance: none;
  border-radius: var(--border-radius-pill);
  color: var(--close-icon-color);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: #0000;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
  position: absolute;
}

[dir="rtl"] button.close {
  right: auto;
  left: var(--close-button-margin);
}

button.close:hover {
  color: var(--close-icon-color-hover);
  background-color: #ffffff80;
}

button.close:active {
  background-color: #ffffffbf;
}

button.close svg, button.close:hover svg {
  color: inherit !important;
}

.dialog-box-container {
  --dialog-border-radius: var(--border-radius-large);
  --header-background-color: var(--color-turquoise-200);
  --header-text-color: var(--color-turquoise-900);
  position: absolute;
  inset: 0;
}

.dialog-box-backdrop {
  z-index: var(--z-08-dialog-box-backdrop);
  background-color: var(--color-turquoise-200);
  touch-action: none;
  opacity: 0;
  transition: opacity 60ms ease-in-out;
  position: absolute;
  inset: 0;
}

.dialog-box-display-area {
  justify-content: center;
  align-items: center;
  padding: 1em;
  display: flex;
  position: absolute;
  inset: 0 0 30px;
}

.dialog-box {
  z-index: var(--z-09-dialog-box);
  touch-action: none;
  border-radius: var(--dialog-border-radius);
  opacity: 0;
  background: #fff;
  max-height: 100%;
  margin: 1em;
  padding: 0;
  line-height: 1.4;
  transition: opacity 80ms ease-in-out, transform 80ms ease-in-out;
  position: relative;
  overflow: hidden;
  transform: scale(.9);
  box-shadow: 0 0 20px #00000013;
}

.dialog-box > div {
  flex-direction: column;
  max-height: 90vh;
  display: flex;
}

.dialog-box header {
  text-align: center;
  background-color: var(--header-background-color);
  color: var(--header-text-color);
  padding: 2.5rem 1em;
}

.dialog-box footer {
  color: #a0a0a0;
  margin-top: 0;
  padding: 1rem 2rem;
  font-size: .85em;
}

:is(.dialog-box footer) > p:first-child {
  margin-top: 0;
}

:is(.dialog-box footer) a {
  color: inherit;
}

:is(.dialog-box footer) a:hover {
  color: #787878;
}

.dialog-box button.close {
  z-index: var(--z-09-dialog-box);
}

.dialog-box button.btn, .dialog-box a.btn {
  margin: 1em auto 0;
}

.dialog-transition-enter-done .dialog-box {
  opacity: 1;
  transform: scale(1);
}

.dialog-transition-exit .dialog-box {
  opacity: 0;
  transform: scale(.9);
}

.dialog-transition-enter-done .dialog-box-backdrop {
  opacity: .5;
}

.dialog-transition-exit .dialog-box-backdrop {
  opacity: 0;
}

.dialog-content {
  flex-grow: 1;
  position: relative;
  overflow-y: scroll;
}

.dialog-content:not(.dialog-content-bleed) {
  padding: 1rem 2rem;
}

.dialog-content > p:first-child {
  margin-top: 0;
}

button.dialog-primary-action {
  border: 0;
  border-top: 1px solid var(--color-turquoise-400);
  text-align: center;
  width: 100%;
  color: var(--interactive-text-color);
  cursor: pointer;
  background-color: #fff;
  border-radius: 0;
  min-height: 46px;
  font-weight: normal;
  margin-top: 0 !important;
}

button.dialog-primary-action:hover {
  background-color: var(--color-turquoise-100);
  color: var(--interactive-text-hover-color);
}

button.dialog-primary-action:active {
  color: var(--interactive-text-active-color);
}

.team-member {
  text-align: center;
}

.team-member-name {
  margin-top: .5em;
  font-size: 1.1em;
  font-weight: 550;
  line-height: 1.2;
  display: block;
}

.credits-team-past .team-member-name {
  font-size: 1em;
}

.team-member-name a {
  color: #000;
  text-decoration: none;
}

.team-member-title {
  color: gray;
  overflow-wrap: anywhere;
  margin-top: .25em;
  font-size: .9em;
  line-height: 1.2;
  display: block;
}

.team-member-mugshot {
  border-radius: var(--border-radius-pill);
  background-image: url("avatar.c5a8ca24.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 100%;
}

.team-member-mugshot:after {
  content: "";
  padding-bottom: 100%;
  display: block;
}

.credits-team {
  grid-gap: 1.5em;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-auto-flow: row;
  display: grid;
}

.credits-team-past {
  grid-gap: 1.25em;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.credits-container {
  margin-top: 1.5em;
  display: flex;
}

.credits-container > div {
  flex-grow: 1;
}

.credits-container h4 {
  color: gray;
  margin-top: 1em;
  margin-bottom: .25em;
}

.credits-container i {
  color: gray;
  font-style: normal;
}

.social-links li {
  margin-right: 1em;
  display: inline-block;
}

.social-links a {
  color: var(--color-midnight-600);
}

:is(.social-links a):hover {
  color: var(--color-midnight-700);
}

.social-links svg {
  width: 1.5em;
  height: 1.5em;
}

.social-github {
  color: var(--social-github);
}

.social-github:hover {
  color: var(--social-github-hover);
}

.social-discord {
  color: var(--social-discord);
}

.social-discord:hover {
  color: var(--social-discord-hover);
}

.social-bluesky {
  color: var(--social-bluesky);
}

.social-bluesky:hover {
  color: var(--social-bluesky-hover);
}

.social-mastodon {
  color: var(--social-mastodon);
}

.social-mastodon:hover {
  color: var(--social-mastodon-hover);
}

.social-twitter {
  color: var(--social-twitter);
}

.social-twitter:hover {
  color: var(--social-twitter-hover);
}

.social-instagram {
  color: var(--social-instagram);
}

.social-instagram:hover {
  color: var(--social-instagram-hover);
}

.about-dialog {
  max-width: 820px;
}

@media only screen and (width > 768px) {
  .about-dialog {
    max-height: 75vh !important;
  }
}

.about-dialog ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.about-dialog header {
  text-align: left;
  background-color: #fff;
  padding: 0;
}

[dir="rtl"] :is(.about-dialog header) {
  text-align: right;
}

.about-dialog header h1 {
  display: none;
}

.about-dialog .dialog-content {
  display: flex;
  overflow: hidden;
}

.about-dialog-logo {
  height: 36px;
  margin: 2.5rem 2rem 2rem;
}

.about-dialog-content {
  flex-grow: 1;
  display: flex;
}

.about-dialog-left {
  border-right: 1px solid var(--color-turquoise-500);
  width: min(30%, 280px);
  padding: 0 3.5em 0 2em;
}

[dir="rtl"] .about-dialog-left {
  border-left: 1px solid var(--color-turquoise-500);
  border-right: 0;
  padding: 0 2em 0 3.5em;
}

.about-dialog-left p:first-child {
  text-wrap: balance;
  margin-top: 0;
}

.about-dialog-left p {
  margin-top: 1.5em;
}

.about-dialog-right {
  flex: 1;
  padding: 0 2em 3em;
  overflow: hidden scroll;
}

.about-dialog-right > :first-child {
  margin-top: 0;
}

.about-dialog-right > h2 {
  margin-top: 1.5em;
}

@media only screen and (width < 640px) {
  .about-dialog-content {
    display: block;
    overflow-y: scroll;
  }

  .about-dialog-left {
    border: 0;
    width: 100%;
  }

  .credits-team {
    max-width: 500px;
  }
}

.about-dialog-sponsors {
  margin-top: -1em !important;
}

.about-dialog-sponsors img {
  height: 40px;
}

.about-dialog-sponsors li {
  margin-top: 1em;
  margin-right: 1.5em;
  display: inline-block;
}

[dir="rtl"] :is(.about-dialog-sponsors li) {
  margin-left: 1.5em;
  margin-right: 0;
}

.checkbox-item {
  --color-1: var(--color-midnight-400);
  --color-2: var(--color-turquoise-700);
  --disabled-color: var(--color-midnight-400);
  --input-default-border-color: var(--color-1);
  --input-unchecked-background-color: transparent;
  --input-checked-border-color: var(--color-1);
  --input-checked-background-color: white;
  --input-hover-border-color: var(--color-2);
  --input-active-border-color: var(--color-2);
  --input-active-background-color: var(--color-2);
  --input-disabled-border-color: var(--disabled-color);
  --checkmark-checked-color: var(--color-2);
  --checkmark-active-color: white;
  --checkmark-disabled-color: var(--disabled-color);
  --label-disabled-text-color: var(--disabled-color);
  --label-outline-color: var(--color-2);
  line-height: 1.5em;
  display: inline-block;
  position: relative;
}

.checkbox-item input {
  opacity: .00001;
  appearance: none;
  z-index: 2;
  position: absolute;
}

.checkbox-item label {
  cursor: pointer;
  margin-left: 1.3em;
  padding: 0 2px;
  display: inline-block;
  position: relative;
}

.checkbox-item label:before {
  content: "";
  background-color: var(--input-unchecked-background-color);
  border-radius: var(--border-radius-small);
  border: 1px solid var(--input-default-border-color);
  width: 1em;
  height: 1em;
  color: inherit;
  transition: background-color 60ms, color 60ms;
  display: block;
  position: absolute;
  top: .25em;
  left: -1.25em;
}

.checkbox-item input:checked ~ label:before {
  background-color: var(--input-checked-background-color);
  border-color: var(--input-checked-border-color);
}

.checkbox-item input:active:not(:disabled) ~ label:before {
  background-color: var(--input-active-border-color);
  border-color: var(--input-active-border-color);
}

.checkbox-item input:focus:not(:disabled) ~ label:before, .checkbox-item input:hover:not(:disabled) ~ label:before {
  border: 1px solid var(--input-hover-border-color);
}

.checkbox-item input:focus:checked:not(:disabled) + label:before, .checkbox-item input:hover:checked:not(:disabled) + label:before {
  border-color: var(--input-active-border-color);
}

.checkbox-item input:disabled + label:before {
  border-color: var(--input-disabled-border-color);
}

.checkbox-item input ~ svg {
  opacity: 0;
  color: #0000;
  pointer-events: none;
  position: absolute;
  top: .25em;
  left: .05em;
  transform: scale(.8);
}

.checkbox-item input:checked ~ svg {
  color: var(--checkmark-checked-color);
  opacity: 1;
}

.checkbox-item input:active ~ svg {
  color: var(--checkmark-active-color);
}

.checkbox-item input:checked:disabled ~ svg {
  color: var(--checkmark-disabled-color);
}

.checkbox-item input:focus ~ label {
  outline: 1px dotted var(--label-outline-color);
}

.checkbox-item input:disabled ~ label {
  color: var(--label-disabled-text-color);
  cursor: default;
}

.capacity-source-select {
  text-overflow: ellipsis;
  width: 100%;
  max-width: fit-content;
}

.segment-analytics {
  --analytics-color-1-main: var(--color-emerald-500);
  --analytics-color-1-alt: var(--color-emerald-200);
  --analytics-color-1-em: var(--color-emerald-600);
  --analytics-color-2-main: var(--color-turquoise-500);
  --analytics-color-2-alt: var(--color-turquoise-200);
  --analytics-color-2-em: var(--color-turquoise-600);
  --analytics-color-3-main: var(--color-copper-500);
  --analytics-color-3-alt: var(--color-copper-200);
  --analytics-color-3-em: var(--color-copper-600);
  --analytics-color-4-main: var(--color-midnight-500);
  --analytics-color-4-alt: var(--color-midnight-200);
  --analytics-color-4-em: var(--color-midnight-600);
  white-space: nowrap;
  width: 100%;
  margin-bottom: .25em;
  position: relative;
}

.capacity-bars {
  min-width: 25px;
  height: 50px;
  margin-left: 25px;
  margin-right: 10px;
  display: inline-block;
  position: relative;
}

.capacity-icon {
  z-index: 1;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: absolute;
}

[data-color="1"] .capacity-icon {
  background-color: var(--analytics-color-1-main);
}

[data-color="2"] .capacity-icon {
  background-color: var(--analytics-color-2-main);
}

[data-color="3"] .capacity-icon {
  background-color: var(--analytics-color-3-main);
}

[data-color="4"] .capacity-icon {
  background-color: var(--analytics-color-4-main);
}

.capacity-icon-image {
  background-color: #fff;
  border: 3px solid;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  margin-top: 4px;
  margin-left: 4px;
  display: flex;
  position: relative;
  overflow: hidden;
}

[data-color="1"] .capacity-icon-image {
  border-color: var(--analytics-color-1-em);
}

[data-color="2"] .capacity-icon-image {
  border-color: var(--analytics-color-2-em);
}

[data-color="3"] .capacity-icon-image {
  border-color: var(--analytics-color-3-em);
}

[data-color="4"] .capacity-icon-image {
  border-color: var(--analytics-color-4-em);
}

.capacity-icon-image img {
  width: 100%;
}

.capacity-bar {
  border-radius: 0 25px 25px 0;
  height: 50px;
  position: absolute;
  left: 0;
}

[data-color="1"] .capacity-bar.capacity-bar-average {
  background-color: var(--analytics-color-1-main);
}

[data-color="2"] .capacity-bar.capacity-bar-average {
  background-color: var(--analytics-color-2-main);
}

[data-color="3"] .capacity-bar.capacity-bar-average {
  background-color: var(--analytics-color-3-main);
}

[data-color="4"] .capacity-bar.capacity-bar-average {
  background-color: var(--analytics-color-4-main);
}

[data-color="1"] .capacity-bar.capacity-bar-potential {
  background-color: var(--analytics-color-1-alt);
}

[data-color="2"] .capacity-bar.capacity-bar-potential {
  background-color: var(--analytics-color-2-alt);
}

[data-color="3"] .capacity-bar.capacity-bar-potential {
  background-color: var(--analytics-color-3-alt);
}

[data-color="4"] .capacity-bar.capacity-bar-potential {
  background-color: var(--analytics-color-4-alt);
}

.capacity-text {
  vertical-align: top;
  display: inline-block;
}

.capacity-text .capacity-label {
  font-weight: 550;
}

.analytics-dialog {
  max-width: 820px;
  min-height: 500px;
  max-height: 75vh !important;
}

.analytics-dialog .dialog-content {
  flex-direction: column;
  display: flex;
  overflow: hidden auto;
}

.analytics-dialog-content {
  flex-grow: 1;
  display: inline;
}

.analytics-dialog-left {
  flex-basis: 240px;
  width: 520px;
  margin-right: 40px;
  padding-right: 40px;
  position: fixed;
}

[dir="rtl"] .analytics-dialog-left {
  border-right: 0;
  margin-left: 40px;
  margin-right: 0;
  padding-left: 40px;
  padding-right: 0;
}

.analytics-dialog-left p:first-child {
  margin-top: 0;
}

.analytics-dialog-left p {
  margin-top: 1.5em;
}

.analytics-dialog-right {
  flex: 1;
  margin-bottom: 15px;
  margin-left: 520px;
}

[dir="rtl"] .analytics-dialog-right {
  margin-left: 0;
  margin-right: 320px;
}

.analytics-dialog-right > :first-child {
  margin-top: 0;
}

.analytics-dialog-right > h2 {
  margin-top: 1.5em;
}

.analytics-dialog-sponsors {
  margin-top: -1em !important;
}

.analytics-dialog-sponsors img {
  height: 40px;
}

.analytics-dialog-sponsors li {
  margin-top: 1em;
  margin-right: 1.5em;
  display: inline-block;
}

[dir="rtl"] :is(.analytics-dialog-sponsors li) {
  margin-left: 1.5em;
  margin-right: 0;
}

.dialog-actions, .analytics-dialog footer {
  text-align: center;
}

@media only screen and (width >= 640px) {
  .analytics-dialog footer a {
    white-space: nowrap;
  }
}

.analytics-settings-notice {
  border-radius: var(--border-radius-pill);
  background-color: var(--color-citrine-100);
  width: max-content;
  color: var(--color-copper-900);
  margin: .5em auto 0;
  padding: .5em 1em;
}

.analytics-settings-notice svg {
  vertical-align: text-bottom;
  width: 16px;
  height: 16px;
  color: var(--color-copper-700);
  margin-right: .5em;
}

.feature-flag-dialog {
  max-width: 600px;
}

.feature-flag-dialog ul {
  padding: 0;
  list-style-type: none;
}

@media only screen and (width >= 640px) {
  :is(.feature-flag-dialog ul) {
    column-count: 2;
    column-gap: 2em;
  }
}

.feature-flag-dialog ul li {
  margin: 0 0 .25em;
}

.feature-flag-dialog p {
  text-align: center;
}

.feature-flag-label-modified {
  font-weight: 550;
}

.geotag-dialog {
  --geotag-error-banner-margin: 100px;
  width: 90vw;
  max-width: 1400px;
  height: 80vh;
  max-height: 960px;
}

.geotag-dialog .close {
  z-index: var(--z-index-10);
  text-shadow: 0 1px #fff;
}

.geotag-error-banner {
  text-align: center;
  background-color: var(--alert-background);
  border-bottom: var(--alert-border);
  border-bottom-left-radius: var(--border-radius-medium);
  border-bottom-right-radius: var(--border-radius-medium);
  box-shadow: var(--medium-box-shadow);
  z-index: 500;
  width: calc(100% - (var(--geotag-error-banner-margin) * 2));
  margin: 0 var(--geotag-error-banner-margin);
  padding: .75em 2.5em;
  position: absolute;
  top: 0;
}

.geotag-input-container {
  width: 100%;
  z-index: var(--z-index-10);
  pointer-events: none;
  position: absolute;
  top: 10px;
}

.geotag-input-form {
  pointer-events: auto;
  width: 30vw;
  min-width: 220px;
  margin: 0 auto;
  position: relative;
  box-shadow: 0 0 0 4px #ffffffd9;
}

.geotag-input-clear {
  cursor: pointer;
  padding: 8px;
  position: absolute;
  top: 0;
  right: 0;
}

.geotag-input {
  border: 1px solid var(--form-element-border);
  resize: none;
  width: 100%;
  font-size: inherit;
  background-color: var(--form-element-background);
  line-height: 2;
  padding: 3px 24px 3px 6px !important;
}

.geotag-suggestions-container {
  border: 1px solid var(--form-element-border);
  font-family: var(--font-family);
  background-color: #fff;
  border-top: 0;
  list-style-type: none;
}

.geotag-suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.geotag-suggestion {
  cursor: pointer;
  padding: 6px;
}

.geotag-suggestion[aria-selected="true"] {
  background-color: var(--form-element-background);
}

.leaflet-container {
  background-color: var(--off-white);
  height: 100%;
  font-family: var(--font-family);
  font-size: inherit;
}

.leaflet-container .leaflet-control-attribution {
  text-shadow: 0 1px #fff;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  background: none;
  margin: .25em;
}

.leaflet-container .leaflet-control-attribution a {
  pointer-events: auto;
}

.leaflet-popup-content-wrapper {
  border-radius: var(--border-radius);
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  box-shadow: 0 3px 10px #00000026;
}

.leaflet-popup-tip-container {
  height: 24px;
  bottom: -24px;
}

.leaflet-popup-tip {
  background: var(--info-bubble-background);
  width: 24px;
  height: 24px;
  box-shadow: none;
  margin-left: -12px;
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: rotateZ(45deg);
}

.leaflet-popup-content {
  text-align: center;
  margin: 0;
  padding: 1em;
}

.leaflet-control-zoom {
  background-color: #fff;
  padding: 2px;
}

.leaflet-left .leaflet-control, .leaflet-top .leaflet-control {
  margin-top: 12px;
}

.leaflet-touch .leaflet-bar, .leaflet-bar {
  box-shadow: var(--medium-box-shadow);
  border: 0;
}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: inherit;
}

.leaflet-bar a, .leaflet-bar a:hover {
  font-size: inherit;
  -webkit-user-select: none;
  user-select: none;
  border: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-bar a:first-child, .leaflet-touch .leaflet-bar a:first-child {
  border-bottom: 1px solid #e7e7e7;
}

.popover-trigger {
  vertical-align: bottom;
  border-radius: var(--border-radius-medium);
  width: 23px;
  height: 23px;
  color: var(--color-midnight-700);
  cursor: pointer;
  background-color: #0000;
  border: 0;
  justify-content: center;
  align-items: center;
  margin: 0 .35em;
  padding: 0;
  display: inline-flex;
}

.popover-trigger:hover {
  background-color: var(--color-midnight-100);
}

.popover-content {
  color: #414141;
  border-radius: var(--border-radius-large);
  max-width: 200px;
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  text-align: center;
  background-color: #fff;
  padding: .8em 1.2em;
  font-weight: normal;
  overflow: hidden;
  box-shadow: 0 5px 20px #18254926, 0 10px 20px -5px #1825490d;
}

.popover-content:focus {
  box-shadow: 0 10px 38px -10px #0e121659, 0 10px 20px -15px #0e121633, 0 0 0 3px #52a2af33;
}

.popover-arrow {
  fill: #fff;
}

.slider-root {
  --slider-thumb-color: var(--color-turquoise-700);
  --slider-thumb-color-hover: var(--color-turquoise-600);
  --slider-thumb-size: 19px;
  --slider-track-color: var(--color-turquoise-200);
  --slider-track-height: 7px;
  --slider-range-color: var(--color-turquoise-500);
  width: 100%;
  height: max(var(--slider-thumb-size), var(--slider-track-height));
  -webkit-user-select: none;
  user-select: none;
  touch-action: none;
  align-items: center;
  margin: .25em 0;
  display: flex;
  position: relative;
}

.slider-track {
  height: var(--slider-track-height);
  border-radius: var(--border-radius-pill);
  background-color: var(--slider-track-color);
  flex-grow: 1;
  position: relative;
}

.slider-track[data-disabled] {
  background-color: var(--color-midnight-100);
}

.slider-range {
  height: var(--slider-track-height);
  border-radius: var(--border-radius-pill);
  background-color: var(--slider-range-color);
  position: absolute;
  top: 0;
  left: 0;
}

.slider-range[data-disabled] {
  background-color: var(--color-midnight-300);
}

.slider-thumb {
  width: var(--slider-thumb-size);
  height: var(--slider-thumb-size);
  margin-top: calc(var(--slider-thumb-size) / -2);
  margin-left: calc(var(--slider-thumb-size) / -2);
  border-radius: var(--border-radius-pill);
  background-color: var(--slider-thumb-color);
  cursor: pointer;
  display: block;
  position: absolute;
  box-shadow: 0 2px 10px #00000024;
}

.slider-thumb[data-disabled] {
  background-color: var(--color-midnight-400);
}

.slider-thumb:hover:not([data-disabled]) {
  background-color: var(--slider-thumb-color-hover);
}

.slider-thumb:focus:not([data-disabled]) {
  box-shadow: inset 0 0 0 1px white, 0 0 0 1px white, 0 0 0 3px var(--color-turquoise-700);
}

.streetmix-plus-prompt button {
  border: 1px solid var(--color-turquoise-600);
  width: 100%;
  color: var(--color-turquoise-800);
  background-color: #0000;
}

.streetmix-plus-locked-banner {
  color: var(--color-copper-800);
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  margin: .5em 0;
  font-size: .9em;
  font-weight: 550;
  display: flex;
}

.streetmix-plus-locked-banner [data-icon="lock"] {
  margin: .2em .5em;
  font-size: .75em;
}

.custom-scale {
  flex-direction: column;
  margin-bottom: 1em;
  display: flex;
}

@media only screen and (width >= 640px) {
  .custom-scale {
    flex-direction: row;
  }
}

.custom-scale-label {
  flex-grow: unset;
  margin-top: .25em;
  margin-right: 1em;
  font-weight: 550;
  display: flex;
}

[dir="rtl"] .custom-scale-label {
  margin-left: 1em;
  margin-right: 0;
}

.custom-scale-popover {
  margin-top: -.2em;
}

.custom-scale-control {
  flex-grow: 1;
}

.custom-scale-info {
  color: var(--color-turquoise-800);
}

.custom-scale-disabled, .custom-scale-disabled .custom-scale-info {
  color: var(--color-midnight-400);
}

.save-as-image-dialog {
  max-width: 720px;
}

.save-as-image-dialog footer {
  text-align: center;
}

@media only screen and (width >= 640px) {
  .save-as-image-dialog footer a {
    white-space: nowrap;
  }
}

.save-as-image-options {
  text-align: left;
  margin-bottom: 1em;
}

.save-as-image-options .checkbox-item {
  display: block;
}

.save-as-image-options svg {
  vertical-align: text-top;
}

@media only screen and (width >= 640px) {
  .save-as-image-options {
    text-align: center;
  }

  .save-as-image-options .checkbox-item {
    margin-left: 20px;
    display: inline-block;
  }

  .save-as-image-options .checkbox-item:first-of-type {
    margin-left: 0;
  }

  [dir="rtl"] .save-as-image-options .checkbox-item {
    margin-left: 0;
    margin-right: 20px;
  }

  [dir="rtl"] .save-as-image-options .checkbox-item:first-of-type {
    margin-right: 0;
  }
}

.save-as-image-preview {
  border-top: 3px dashed var(--color-turquoise-600);
  border-bottom: 3px dashed var(--color-turquoise-600);
  flex-direction: column;
  justify-content: center;
  max-height: 300px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  position: relative;
}

.save-as-image-preview-loading {
  background-color: #ffffffe6;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.save-as-image-preview-image {
  z-index: -1;
  background-image: linear-gradient(45deg, #eee 25%, #0000 25%), linear-gradient(-45deg, #eee 25%, #0000 25%), linear-gradient(45deg, #0000 75%, #eee 75%), linear-gradient(-45deg, #0000 75%, #eee 75%);
  background-position: 0 0, 0 12px, 12px -12px, -12px 0;
  background-size: 24px 24px;
}

.save-as-image-preview-image img {
  cursor: grab;
  max-width: 100%;
  max-height: 300px;
  margin: 0 auto;
  display: block;
}

.save-as-image-preview-error {
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 3em;
  display: flex;
}

.save-as-image-too-large-error {
  color: var(--warning-color);
  font-weight: 550;
}

.save-as-image-download {
  text-align: center;
}

.save-as-image-download button {
  line-height: inherit;
}

.switch-item {
  display: flex;
}

.switch-root {
  width: 39px;
  height: 21px;
  box-shadow: 0 0 0 1px var(--color-turquoise-700);
  border-radius: var(--border-radius-pill);
  cursor: pointer;
  background-color: #fff;
  border: 0;
  margin-top: -2px;
  padding: 0;
  transition: background-color .12s;
  position: relative;
}

.switch-root:focus, .switch-root:active:not(:disabled) {
  box-shadow: 0 0 0 2px var(--color-turquoise-700);
}

.switch-root:disabled {
  box-shadow: 0 0 0 1px #d3d3d3;
}

.switch-root:disabled + label {
  color: #a9a9a9;
}

.switch-root[data-state="checked"] {
  background-color: var(--color-turquoise-200);
  box-shadow: 0 0 0 1px var(--color-turquoise-700);
}

.switch-root[data-state="checked"]:focus, .switch-root[data-state="checked"]:active:not(:disabled) {
  box-shadow: 0 0 0 2px var(--color-turquoise-700);
}

.switch-root + label {
  -webkit-user-select: none;
  user-select: none;
  margin-left: .75em;
}

[dir="rtl"] :is(.switch-root + label) {
  margin-left: 0;
  margin-right: .75em;
}

.switch-thumb {
  background-color: var(--color-midnight-200);
  will-change: transform;
  border-radius: 9999px;
  width: 15px;
  height: 15px;
  transition: transform .1s, background-color .12s;
  display: block;
  position: absolute;
  top: 3px;
  left: 0;
  transform: translateX(3px);
}

.switch-thumb[data-state="checked"] {
  background-color: var(--color-turquoise-600);
  transform: translateX(21px);
}

.switch-thumb[data-disabled] {
  background-color: #0000001a;
}

.radio-group-item {
  margin: .8em 0;
  display: flex;
}

.radio-group-radio {
  width: 21px;
  height: 21px;
  box-shadow: 0 0 0 1px var(--color-turquoise-700);
  cursor: pointer;
  background-color: #fff;
  border: 0;
  border-radius: 9999px;
  margin-top: -2px;
  padding: 0;
  transition: background-color .12s;
  position: relative;
}

.radio-group-radio:focus, .radio-group-radio:active:not(:disabled) {
  box-shadow: 0 0 0 2px var(--color-turquoise-700);
}

.radio-group-radio:disabled {
  box-shadow: 0 0 0 1px #d3d3d3;
}

.radio-group-radio:disabled + label {
  color: #a9a9a9;
}

.radio-group-radio + label {
  -webkit-user-select: none;
  user-select: none;
  margin-left: .75em;
}

[dir="rtl"] :is(.radio-group-radio + label) {
  margin-left: 0;
  margin-right: .75em;
}

.radio-group-indicator {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.radio-group-indicator:after {
  content: "";
  background-color: var(--color-turquoise-700);
  border-radius: 50%;
  width: 11px;
  height: 11px;
  display: block;
}

.radio-group-sublabel {
  color: gray;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: .8em;
  line-height: 8px;
  display: block;
}

.profile-settings-item {
  margin-top: 1.2em;
}

.profile-settings-item h3 {
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
}

.profile-settings-editable, .profile-settings-editable > form {
  flex-direction: row;
  align-items: center;
  width: 100%;
  display: flex;
}

:is(.profile-settings-editable, .profile-settings-editable > form) > :not(:last-child) {
  margin-right: .5em;
}

[dir="rtl"] :is(:is(.profile-settings-editable, .profile-settings-editable > form) > :not(:last-child)) {
  margin-left: .5em;
  margin-right: 0;
}

:is(.profile-settings-editable, .profile-settings-editable > form) > p {
  flex-grow: 1;
  align-items: center;
  height: 2.5rem;
  margin: 0;
  display: flex;
}

:is(.profile-settings-editable, .profile-settings-editable > form) input {
  appearance: none;
  border: 1px solid gray;
  border-radius: 4px;
  width: 100%;
  height: 2.5rem;
}

:is(.profile-settings-editable, .profile-settings-editable > form) .profile-settings-button {
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  display: flex;
}

:is(:is(.profile-settings-editable, .profile-settings-editable > form) .profile-settings-button) button {
  align-items: center;
  height: 2.5rem;
  margin: 0;
  padding: 0 1.5em;
  display: flex;
  position: relative;
}

:is(:is(.profile-settings-editable, .profile-settings-editable > form) .profile-settings-button) button + button {
  margin-left: .5em;
}

[dir="rtl"] :is(:is(:is(.profile-settings-editable, .profile-settings-editable > form) .profile-settings-button) button + button) {
  margin-left: 0;
  margin-right: .5em;
}

.profile-settings-pending {
  filter: grayscale();
  background-color: #f4f4f4;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 4px;
}

.profile-display-name-not-set {
  color: gray;
}

.profile-display-name-messages p {
  color: red;
  margin: .25em 0 0;
  font-size: .9rem;
}

.settings-dialog {
  width: 650px;
  height: 70vh;
  min-height: 300px;
  max-height: 90vh;
}

.settings-dialog-content {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

.settings-dialog-left {
  border-right: 1px solid var(--color-turquoise-500);
  width: 180px;
  overflow: hidden auto;
}

[dir="rtl"] .settings-dialog-left {
  border-left: 1px solid var(--color-turquoise-500);
}

.settings-dialog-left ul {
  margin: .5em 0 0;
  padding: 0;
  list-style-type: none;
}

.settings-dialog-left li {
  cursor: pointer;
  padding: .8em 1.2em;
}

:is(.settings-dialog-left li):hover {
  background-color: var(--color-turquoise-200);
}

.settings-dialog-right {
  flex: 1;
  padding: 2em;
  overflow: hidden auto;
}

.settings-dialog-right h2 {
  color: var(--color-turquoise-800);
  font-weight: normal;
}

:is(.settings-dialog-right h2):first-child {
  margin-top: 0;
}

.settings-dialog-right section {
  margin-bottom: 2em;
}

.settings-menu-active {
  background-color: var(--color-turquoise-100);
  color: var(--color-turquoise-900);
  font-weight: 550;
}

.settings-menu-icon {
  vertical-align: text-top;
  color: var(--color-turquoise-800);
  margin-right: .5em;
  font-size: 16px;
}

[dir="rtl"] .settings-menu-icon {
  margin-left: .5em;
}

.settings-item {
  align-items: center;
  margin-bottom: 1em;
  display: flex;
}

.sign-in-dialog {
  width: min(90vw, 450px);
  min-height: 250px;
}

.sign-in-loading {
  justify-content: center;
  align-items: center;
  display: flex;
}

.sign-in-email-label {
  margin-bottom: .25em;
  font-size: 1.2em;
  font-weight: 550;
  display: block;
}

.sign-in-input {
  border: var(--button-border);
  border-radius: 3px;
  width: 100%;
  padding: .75em;
  transition: border-color .15s ease-in-out, box-shadow .15s;
  box-shadow: inset 0 1px 1px #00000012;
}

.sign-in-input:focus {
  box-shadow: inset 0 1px 1px #00000012, 0 0 10px color-mix(in srgb, var(--color-turquoise-600), transparent 25%);
  outline: 0;
}

.sign-in-input.sign-in-input-error {
  border: 1px solid red !important;
}

.sign-in-email-password-note {
  color: #787878;
  margin-top: .25em;
  margin-bottom: .25em;
  line-height: 1.3;
}

.sign-in-social-heading {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  font-size: 1.25em;
  font-weight: 550;
  display: flex;
}

.sign-in-social-heading span {
  background-color: #fff;
  padding: 1em;
  display: inline-block;
  position: absolute;
}

.sign-in-button {
  width: 100%;
  position: relative;
  align-items: center !important;
  display: flex !important;
}

.sign-in-social-button {
  text-align: left;
  padding-left: 54px !important;
}

.sign-in-social-button svg, .sign-in-social-button img {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 16px;
}

.sign-in-twitter-button [data-icon="twitter"] {
  color: var(--social-twitter) !important;
}

.sign-in-facebook-button [data-icon="facebook"] {
  color: var(--social-facebook) !important;
}

.sign-in-disclaimer {
  text-align: center;
  margin-top: 2em;
  margin-left: 5em;
  margin-right: 5em;
}

.sign-in-email-sent {
  text-align: center;
  margin: 3em 2em 2em;
}

.sign-in-email {
  font-weight: 550;
}

.sign-in-resend {
  margin-top: 2em;
}

.sign-in-error-message {
  color: red;
  margin-top: .25em;
  margin-bottom: .25em;
  font-weight: 550;
}

.sign-in-email-button {
  justify-content: center;
}

.whats-new-dialog {
  width: min(90vw, 550px);
  height: 80vh;
  max-height: 800px;
  font-family: system-ui, sans-serif;
  font-size: 14px;
}

.whats-new-content {
  width: 100%;
  height: 100%;
  padding: 1rem 2rem;
  position: absolute;
  overflow-y: scroll;
}

.whats-new-content h1 {
  display: none;
}

.whats-new-content h2 {
  color: var(--color-turquoise-800);
  font-size: 1.75rem;
  font-weight: 550;
}

.whats-new-content h2:not(:first-of-type) {
  border-top: 1px solid var(--color-turquoise-400);
  margin-top: 1.25em;
  padding-top: 1.25em;
}

.whats-new-content h3 {
  color: var(--color-turquoise-800);
  margin-top: 1.5em;
  margin-bottom: 1em;
  font-size: 1.35rem;
  font-weight: 550;
}

.whats-new-content ul {
  padding-left: 1.5rem;
}

.whats-new-content li {
  margin-bottom: .75em;
}

.whats-new-content ul ul {
  margin-top: 1em;
}

.whats-new-content img {
  width: 100%;
}

.whats-new-content li > img {
  margin-top: 1em;
}

.whats-new-content .img-ui {
  border: 1px solid var(--color-turquoise-600);
  border-radius: 6px;
  padding: 10px;
  box-shadow: 0 2px 10px #0000000d;
}

.whats-new-loading {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.whats-new-language-banner {
  text-align: center;
  background-color: var(--alert-background);
  border-bottom: var(--alert-border);
  padding: .75em 2.5em;
}

.whats-new-scroll-shade {
  pointer-events: none;
  background: linear-gradient(180deg, var(--color-turquoise-700) 0%, transparent 100%);
  opacity: 0;
  width: 100%;
  height: max(60px, 10%);
  transition: opacity .3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.whats-new-scroll-shade.visible {
  opacity: .15;
}

.newsletter-dialog {
  max-width: 520px;
}

.newsletter-dialog label {
  margin-bottom: .25em;
  font-size: 1.2em;
  font-weight: 550;
  display: block;
}

.newsletter-dialog form {
  margin-top: 1.5em;
}

.newsletter-dialog input[type="email"] {
  border: var(--button-border);
  border-radius: 3px;
  width: 100%;
  padding: .75em;
  transition: border-color .15s ease-in-out, box-shadow .15s;
  display: block;
  box-shadow: inset 0 1px 1px #00000012;
}

:is(.newsletter-dialog input[type="email"]):focus {
  box-shadow: inset 0 1px 1px #00000012, 0 0 10px color-mix(in srgb, var(--color-turquoise-600), transparent 25%);
  outline: 0;
}

.subscribe-input-error:is(.newsletter-dialog input[type="email"]) {
  border: 1px solid red !important;
}

.newsletter-dialog .subscribe-error {
  color: red;
  margin-top: .25em;
  margin-bottom: .25em;
  font-weight: 550;
}

.newsletter-dialog .subscribe-buttons {
  text-align: center;
  margin-top: 2em;
  margin-bottom: 1em;
}

.upgrade-dialog {
  width: 440px;
  min-height: 220px;
  max-height: 75vh;
  padding: 20px;
}

.upgrade-dialog header {
  text-align: left;
  background-color: #fff;
  padding: 0;
}

[dir="rtl"] :is(.upgrade-dialog header) {
  text-align: right;
}

.upgrade-dialog .dialog-content {
  overflow: hidden scroll;
}

.upgrade-dialog-content {
  margin-bottom: 2em;
  display: flex;
}

.sentiment-survey-about-dialog {
  max-width: 500px;
}

.dialog-error {
  max-width: 400px;
}

.sky-options {
  --selection-highlight-color: var(--color-copper-700);
  --selection-focus-color: var(--color-turquoise-700);
  grid-gap: .75em;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-flow: row;
  height: auto;
  padding: 1em;
  display: grid;
}

.sky-option-item {
  border-radius: var(--border-radius-large);
  cursor: pointer;
  appearance: none;
  border: 0;
  width: 40px;
  height: 40px;
  padding: 0;
  transition: box-shadow .12s;
  position: relative;
}

.sky-option-item:focus {
  box-shadow: 0 0 0 2px var(--selection-focus-color), inset 0 0 0 2px #fff;
}

.sky-option-item.sky-selected {
  box-shadow: 0 0 0 2px var(--selection-highlight-color), inset 0 0 0 2px #fff;
}

.sky-option-item.sky-disabled {
  cursor: default;
}

.sky-option-item.sky-disabled .sky-disabled-overlay {
  border-radius: var(--border-radius-large);
  background-color: #dddddda6;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.sky-option-item.sky-disabled [data-icon="lock"] {
  z-index: 2;
  color: var(--color-copper-800);
  position: absolute;
  top: 4px;
  right: 4px;
}

[dir="rtl"] :is(.sky-option-item.sky-disabled [data-icon="lock"]) {
  left: 4px;
  right: auto;
}

.sky-picker-container-outer {
  --sky-picker-border-radius: var(--border-radius-large);
  --sky-picker-box-shadow: var(--medium-box-shadow);
  --header-background-color: var(--color-turquoise-200);
  --header-text-color: var(--color-turquoise-900);
  z-index: var(--z-07-sky-picker);
  max-width: 216px;
  position: absolute;
  bottom: 90px;
  left: 50px;
}

.sky-picker-container-inner {
  border-radius: var(--sky-picker-border-radius);
  box-shadow: var(--sky-picker-box-shadow);
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
  overflow: hidden;
}

[dir="rtl"] .sky-picker-container-inner {
  left: auto;
  right: 50px;
}

.sky-picker header {
  background-color: var(--header-background-color);
  color: var(--header-text-color);
  align-items: center;
  padding: .5em;
  display: flex;
}

.sky-picker.react-draggable header {
  cursor: move;
  cursor: grab;
}

.sky-picker.react-draggable-dragging header {
  cursor: grabbing;
}

.sky-picker header img {
  pointer-events: none;
  width: auto;
  height: 16px;
  margin-left: .5em;
}

[dir="rtl"] :is(.sky-picker header img) {
  margin-left: auto;
  margin-right: .5em;
}

.sky-picker header h3 {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  align-items: center;
  min-width: 0;
  margin: 0 .25em;
  font-size: 1em;
  display: flex;
  overflow: hidden;
}

:is(.sky-picker header h3) [data-icon="sun"] {
  width: 16px;
  height: 16px;
  margin-right: .5em;
}

[dir="rtl"] :is(:is(.sky-picker header h3) [data-icon="sun"]) {
  margin-left: .5em;
  margin-right: auto;
}

.sky-picker-content {
  padding: 0;
}

.sky-picker-upgrade {
  background-color: color-mix(in oklch, white 20%, var(--alert-background));
  text-align: center;
  color: var(--color-copper-900);
  padding: .75em 1em;
  font-size: 1rem;
  line-height: 1.2;
}

.sky-picker-upgrade button {
  border: 1px solid var(--color-copper-600);
  border-radius: var(--border-radius-medium);
  width: auto;
  color: var(--color-copper-800);
  background-color: #0000;
  margin: .75em auto .25em;
  padding: .5em 1em;
  font-weight: 550;
  display: block !important;
}

:is(.sky-picker-upgrade button):disabled {
  filter: grayscale(.8);
}

:is(.sky-picker-upgrade button):hover {
  color: var(--color-copper-900);
  background-color: #0000;
}

.sky-picker .close {
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 0;
  position: relative;
  top: auto;
  right: auto;
  align-items: center !important;
  display: flex !important;
}

.gallery-street-item {
  --selection-highlight-color: var(--color-copper-700);
  --thumbnail-width: 180px;
  --thumbnail-height: 110px;
  width: var(--thumbnail-width);
  height: var(--thumbnail-height);
  border-radius: var(--border-radius-medium);
  box-sizing: content-box;
  text-align: center;
  padding: 5px;
  display: inline-block;
  position: relative;
}

.gallery-street-item > * {
  box-sizing: border-box;
}

.gallery-street-item:first-child {
  padding-left: 2px;
}

.gallery-street-item:last-child {
  padding-right: 2px;
}

.gallery-street-item > a {
  border: 2px solid var(--color-turquoise-600);
  cursor: pointer;
  border-radius: var(--border-radius-medium);
  background-color: #f0f0f0;
  width: 100%;
  height: 100%;
  text-decoration: none;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

:is(.gallery-street-item > a):hover canvas {
  opacity: 1;
  filter: grayscale(0);
  transform: scale(1.45);
}

.gallery-street-item.gallery-selected > a {
  box-shadow: 0 0 0 2px var(--selection-highlight-color);
  border: 2px solid #fff;
}

:is(.gallery-street-item.gallery-selected > a) canvas {
  opacity: 1;
  filter: grayscale(0);
  transform: scale(1.45);
}

.gallery-street-item-inner {
  border-radius: 2px;
  flex-direction: column;
  display: flex;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.gallery-street-item-canvas {
  flex-grow: 1;
  position: relative;
}

.gallery-street-item-canvas canvas {
  width: var(--thumbnail-width);
  height: var(--thumbnail-height);
  border-radius: var(--border-radius-medium);
  opacity: .5;
  filter: grayscale(.25);
  transform-origin: bottom;
  transition: opacity 45ms, filter 45ms, transform 45ms ease-in-out;
  position: absolute;
  bottom: -2px;
  left: 0;
  transform: scale(1.4);
}

.gallery-street-item-label {
  text-align: center;
  color: #000;
  background-color: #f0f0f0;
  padding: 5px 5px 3px;
  font-size: .8em;
  position: relative;
}

.gallery-street-item-label > div {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.gallery-street-item-date {
  margin: 0 16px;
  font-weight: 300;
}

.gallery-street-item-delete {
  -webkit-user-select: none;
  user-select: none;
  background: none;
  border: 0;
  border-radius: 50%;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 0;
  font-size: .65em;
  line-height: 25px;
  position: absolute;
  bottom: 7px;
  right: 7px;
  align-items: center !important;
  display: flex !important;
}

[dir="rtl"] .gallery-street-item-delete {
  left: 7px;
  right: auto;
}

.gallery-street-item-delete:hover {
  background-color: #ffffff80;
}

.gallery-street-item-delete:active {
  background-color: #ffffffbf;
}

.gallery-street-item-delete svg {
  color: var(--color-midnight-900);
  width: 12px;
  height: 12px;
}

.gallery-street-item-delete:hover svg {
  color: var(--color-turquoise-800);
}

.gallery-street-item-error {
  height: 100%;
  color: var(--color-turquoise-800);
  justify-content: center;
  align-items: center;
  padding: 20px 1.5em 2px;
  display: flex;
}

.gallery-street-item .street-name {
  --street-name-size: var(--street-name-size-small);
  max-width: 95%;
  position: relative;
  top: -8px;
}

.gallery-header {
  flex-direction: row;
  flex: 0 0 40px;
  align-items: center;
  display: flex;
}

.gallery-header .avatar {
  flex: 0 0 32px;
  height: 32px;
  margin-top: -1px;
  margin-right: .5em;
}

[dir="rtl"] :is(.gallery-header .avatar) {
  margin-left: .5em;
  margin-right: 0;
}

.gallery-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
  font-size: 1.5em;
  overflow: hidden;
}

.gallery-street-count {
  color: gray;
  white-space: nowrap;
  font-size: 1.5em;
}

.gallery-streets-container {
  flex-direction: row;
  display: flex;
}

.streets-scrollable-container {
  height: var(--gallery-height);
  flex-grow: 1;
  min-width: 0;
  position: relative;
  top: 1px;
}

.streets-scrollable-container button.scrollable {
  width: 30px;
  margin-top: 45px;
}

.scroll-left:is(.streets-scrollable-container button.scrollable) {
  left: -10px !important;
}

.scroll-right:is(.streets-scrollable-container button.scrollable) {
  right: -10px !important;
}

.streets-scrollable-container .streets {
  white-space: nowrap;
  height: calc(var(--gallery-height)  + 20px);
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-x: scroll;
}

.gallery-user-buttons {
  flex-direction: column;
  flex: 0 0 100px;
  height: 110px;
  margin-top: 6px;
  margin-right: 16px;
  display: flex;
}

.gallery-user-buttons a.btn, .gallery-user-buttons button {
  justify-content: center;
  padding-left: .5em;
  padding-right: .5em;
  align-items: center !important;
  display: flex !important;
}

[dir="rtl"] .gallery-user-buttons {
  margin-left: 16px;
  margin-right: 0;
}

.gallery-new-street {
  flex-grow: 1;
  font-weight: 550;
}

.gallery-copy-last-street {
  flex-basis: 40px;
  height: 40px;
  margin-top: 10px;
}

.gallery-shield {
  cursor: pointer;
  width: 100vw;
  height: 100vh;
  z-index: var(--z-08-gallery-shield);
  position: absolute;
  top: 0;
  left: 0;
}

body.gallery-visible .main-screen {
  transform: translateY(calc(var(--gallery-height)  - 90px));
}

body.gallery-visible .street-nameplate-container {
  transform: translateY(60px);
}

body:not(.safari).gallery-visible .street-section-sky {
  transform: translateY(-20px);
}

.gallery-panel {
  --gallery-border-radius: var(--border-radius);
  --gallery-box-shadow: var(--medium-box-shadow);
  left: var(--left-right-inset);
  right: var(--left-right-inset);
  height: var(--gallery-height);
  z-index: var(--z-09-gallery);
  border-bottom-left-radius: var(--gallery-border-radius);
  border-bottom-right-radius: var(--gallery-border-radius);
  box-shadow: var(--gallery-box-shadow);
  pointer-events: none;
  transform: translateY(calc(-1 * var(--gallery-height))) rotateX(25deg);
  transform-origin: 50% 0;
  background: #fff;
  flex-direction: column;
  padding: 10px 16px 16px;
  display: flex;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.gallery-loading, .gallery-error {
  text-align: center;
  z-index: var(--z-03-gallery-message);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

:is(.gallery-loading, .gallery-error) a {
  color: inherit;
}

body:not(.gallery-no-move-transition) .main-screen, body:not(.gallery-no-move-transition) .gallery-panel, body:not(.gallery-no-move-transition) .street-nameplate-container, body:not(.gallery-no-move-transition) .street-section-sky {
  transition: transform .8s;
}

body.gallery-visible .gallery-panel {
  pointer-events: auto;
  transform: translateY(0);
}

.segment-image {
  pointer-events: none;
  position: absolute;
  top: 150px;
  left: 0;
  overflow: hidden;
}

body:not(.segment-resize-dragging) .segment.outside .segment-image {
  transition: none;
}

.segment-drag-layer {
  pointer-events: none;
  z-index: var(--z-04-drag-layer);
  position: fixed;
  inset: 0;
}

.segment.floating {
  opacity: .75;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  transition: none !important;
}

.segment.floating.first-drag-move {
  transition: top .15s;
}

.toast {
  pointer-events: auto;
  border-radius: 4px;
  width: auto;
  height: auto;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 10px #0000001a;
}

.toast-content {
  border-top: var(--alert-border);
  background-color: var(--alert-background);
  min-width: 200px;
  max-width: 330px;
  padding: 1em 1.25em;
  font-size: 13px;
  line-height: 1.4;
  display: inline-block;
  position: relative;
}

.toast-body {
  padding-right: 20px;
}

[dir="rtl"] .toast-body {
  padding-left: 20px;
  padding-right: 0;
}

.toast-message {
  margin: 0;
}

.toast-action {
  margin-top: 1em;
}

.toast-action button {
  border: 1px solid var(--color-copper-600);
  border-radius: var(--border-radius-medium);
  width: 100%;
  min-height: 32px;
  color: var(--color-copper-800);
  cursor: pointer;
  background-color: #0000;
  margin: 0;
  padding: .25em .5em;
  font-weight: 550;
  display: block;
}

:is(.toast-action button):hover {
  color: var(--color-copper-900);
  background-color: #0000;
}

.toast .close {
  --close-icon-color: var(--color-copper-800);
  --close-icon-color-hover: var(--color-copper-700);
  width: 24px;
  height: 24px;
  color: var(--close-icon-color);
  justify-content: center;
  padding: 0;
  top: 6px;
  right: 6px;
  align-items: center !important;
  display: flex !important;
}

[dir="rtl"] :is(.toast .close) {
  left: 6px;
  right: auto;
}

:is(.toast .close):hover {
  color: var(--close-icon-color-hover);
  background-color: #ffffff80;
}

:is(.toast .close):active {
  background-color: #ffffffbf;
}

.toast h3 {
  margin: 0 0 .25em;
}

.toast-success .toast-content {
  background-color: var(--color-emerald-300);
  border-top-color: var(--color-emerald-400);
}

.toast-success h3 {
  color: var(--color-emerald-900);
}

.toast-success .close {
  color: var(--color-emerald-700);
}

:is(.toast-success .close):hover {
  color: var(--color-emerald-800);
}

.toast-success .toast-action button {
  color: var(--color-emerald-800);
  border-color: var(--color-emerald-700);
}

:is(.toast-success .toast-action button):hover {
  color: var(--color-emerald-900);
}

.toast-warning .toast-content {
  background-color: #fff9f9;
  border-top-color: #ff5b5b;
}

.toast-warning h3 {
  color: #a00101;
}

.toast-warning .close {
  color: #c10000;
}

:is(.toast-warning .close):hover {
  color: #a00101;
}

.toast-warning .toast-action button {
  color: #c10000;
  border-color: #c10000;
}

:is(.toast-warning .toast-action button):hover {
  color: #a00101;
}

.toast-web-monetization img.wm-icon {
  width: 36px;
  height: 36px;
  margin-right: .75em;
}

[dir="rtl"] :is(.toast-web-monetization img.wm-icon) {
  margin-left: .75em;
  margin-right: 0;
}

.toast-web-monetization a, .toast-web-monetization a:visited {
  color: var(--color-emerald-800);
}

.toast-web-monetization a:hover, .toast-web-monetization a:active {
  color: var(--color-emerald-700);
}

.toast-icon-header {
  align-items: center;
  display: flex;
}

.toast-icon-header h3 {
  margin: 0;
  line-height: 1.2;
}

.toast-container {
  min-width: 300px;
  max-width: 330px;
  z-index: var(--z-07-toasts);
  pointer-events: none;
  flex-direction: column;
  align-items: flex-end;
  line-height: 0;
  display: flex;
  position: absolute;
  top: 50px;
  right: 30px;
}

.toast-container > div {
  will-change: transform;
  transform: translate3d(0, 0, 0);
}

[dir="rtl"] .toast-container {
  left: 30px;
  right: auto;
}

.toast-lifebar {
  background-color: #c0c0c0b3;
  border-radius: 0 4px 4px;
  width: auto;
  height: 5px;
  position: absolute;
  bottom: 10px;
  left: 0;
}

.sentiment-icon {
  border-radius: var(--border-radius-medium);
  color: #fff;
  vertical-align: middle;
  transform-origin: center;
  border: 0;
  width: 60px;
  height: 60px;
  margin: 0;
  padding: 5px;
  font-size: 2em;
  transition: transform .3s cubic-bezier(.5, 0, 0, 2.25);
  transform: scale(1);
}

.sentiment-icon.sentiment-1 {
  background-color: #ff5b5b;
}

.sentiment-icon.sentiment-2 {
  background-color: #fbaa38;
}

.sentiment-icon.sentiment-3 {
  background-color: #ffd755;
}

.sentiment-icon.sentiment-4 {
  background-color: #82e3da;
}

.sentiment-icon.sentiment-5 {
  background-color: var(--color-emerald-600);
}

.sentiment-survey-done-container {
  opacity: 0;
  pointer-events: none;
  background: #fff;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  transition: opacity .2s ease-in;
  position: absolute;
  top: 20px;
  left: 20px;
}

.sentiment-survey-done-container.visible {
  opacity: 1;
  pointer-events: auto;
}

.sentiment-survey-done-content {
  text-align: left;
  opacity: 0;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  transition: opacity .2s ease-in;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.sentiment-survey-done-content.visible {
  opacity: 1;
}

.sentiment-survey-done-content h2 {
  margin: 0 !important;
}

.sentiment-survey-done-text {
  flex-direction: row;
  margin: 1em 0;
  display: flex;
}

.sentiment-survey-done-text strong:first-of-type {
  color: var(--color-turquoise-800);
}

.sentiment-survey-done-text .sentiment-icon {
  margin-right: .5em;
}

.sentiment-survey-done-text div:last-child {
  flex-grow: 1;
}

.sentiment-survey-done-comment {
  flex-direction: row;
  place-content: center space-between;
  align-items: center;
  margin: 0;
  display: flex;
}

.sentiment-survey-done-comment input {
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--color-turquoise-600);
  flex-grow: 1;
  height: 2.25em;
  margin-right: .5em;
}

.sentiment-survey-done-comment button {
  height: 2.25em;
  padding: 0 1em;
}

.sentiment-survey-done-buttons {
  justify-content: space-between;
  display: flex;
}

.sentiment-survey-done-buttons a.btn {
  margin-right: 1em;
}

:is(.sentiment-survey-done-buttons a.btn) [dir="rtl"] * {
  margin-left: 1em;
  margin-right: auto;
}

.sentiment-survey-container {
  z-index: 190;
  pointer-events: none;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 100px;
}

.sentiment-survey-container button.close {
  z-index: 1;
}

.sentiment-survey-background {
  opacity: 0;
  pointer-events: none;
  background: linear-gradient(#17444640 30%, #0000 70%);
  width: 100%;
  height: 100%;
  transition: opacity .2s ease-in;
  position: absolute;
  top: 0;
  left: 0;
}

.sentiment-survey-visible .sentiment-survey-background {
  opacity: 1;
  pointer-events: auto;
}

.sentiment-survey-dialog {
  border-radius: var(--border-radius-medium);
  width: max-content;
  max-width: 600px;
  box-shadow: var(--medium-box-shadow);
  text-align: center;
  pointer-events: auto;
  background-color: #fff;
  margin: 160px auto 15px;
  padding: 1.5em 3em;
  position: relative;
}

.sentiment-survey-dialog h2 {
  color: var(--color-turquoise-900);
  margin-top: .5em;
  margin-bottom: 0;
  font-weight: normal;
}

:is(.sentiment-survey-dialog h2) em {
  font-style: normal;
  font-weight: 550;
}

.sentiment-survey-dialog p:first-of-type {
  margin-top: 0;
}

.sentiment-survey-dialog sub {
  color: gray;
  margin-bottom: 1.5em;
  font-style: italic;
  display: block;
}

.sentiment-survey-about-link {
  margin-top: 1.5em;
  margin-bottom: 0;
}

.sentiment-survey-buttons {
  position: relative;
}

.sentiment-survey-buttons button.sentiment-button {
  opacity: 1;
  background: none;
  border: 0;
  padding: 0 .5em;
  transition: opacity .3s;
}

.sentiment-survey-buttons button.sentiment-button:not(:disabled):hover .sentiment-icon, .sentiment-survey-buttons button.sentiment-button:not(:disabled):focus .sentiment-icon {
  transform: scale(1.2);
}

.sentiment-survey-buttons button.sentiment-button:disabled {
  background-color: #0000 !important;
}

:is(.sentiment-survey-buttons button.sentiment-button:disabled):not(.sentiment-selected) {
  opacity: 0;
}

.sentiment-survey-buttons .sentiment-selected .sentiment-icon {
  animation-name: tada;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.sentiment-survey-sign-in-prompt {
  background-color: #fffc;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes tada {
  0% {
    transform: scale3d(1.2, 1.2, 1.2);
  }

  10%, 20% {
    transform: scale3d(1, 1, 1)rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale3d(1.4, 1.4, 1.4)rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    transform: scale3d(1.4, 1.4, 1.4)rotate3d(0, 0, 1, -3deg);
  }

  100% {
    transform: scale3d(1.2, 1.2, 1.2);
  }
}

.debug-container {
  z-index: var(--z-09-debug);
  backdrop-filter: blur(4px);
  background-color: #c0c0c080;
  padding: 30px;
  position: fixed;
  inset: 0;
}

.debug-container .debug-panel {
  resize: none;
  background-color: #f5f5f5;
  border: 1px solid #d3d3d3;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  box-shadow: 0 0 0 10px #fff;
}

:is(.debug-container .debug-panel) h2 {
  margin-top: 0;
  margin-bottom: .5em;
}

:is(.debug-container .debug-panel) textarea {
  resize: none;
  color: #35f035;
  background-color: #222;
  border: 0;
  width: 100%;
  height: 100%;
  padding: 1em;
  font-family: monospace;
}

:is(:is(.debug-container .debug-panel) textarea)::selection {
  color: #222;
  background-color: #35f035;
}

.blocking-shield {
  z-index: var(--z-09-blocking-shield);
  color: #0000;
  cursor: wait;
  background: none;
}

.blocking-shield h1 {
  margin-top: 2em;
  margin-bottom: 2em;
}

.blocking-shield button {
  cursor: pointer;
}

.blocking-shield.hidden {
  display: none;
}

.blocking-shield.darken, .blocking-shield.darken-immediately {
  background-color: color-mix(in srgb, var(--color-turquoise-200), transparent 15%);
  color: #000;
}

.blocking-shield.darken {
  transition: background 1.5s, color 1.5s;
}

.blocking-shield.darken-immediately {
  transition: none;
}

.resize-guides {
  --resize-guide-text-color: #0009;
  --resize-guide-text-inverted-color: #fff9;
  --resize-guide-line: 1px dashed #0006;
  --resize-guide-line-inverted: 1px dashed #fff6;
  z-index: var(--z-07-resize-guide);
  pointer-events: none;
  position: absolute;
  top: -75px;
  bottom: 120px;
  left: 50%;
}

.resize-guide {
  border-left: var(--resize-guide-line);
  border-right: var(--resize-guide-line);
  height: 100%;
  position: absolute;
}

body.dark-skybox-invert-ui .resize-guide {
  border-left: var(--resize-guide-line-inverted);
  border-right: var(--resize-guide-line-inverted);
}

.resize-guide-max-before, .resize-guide-max-after, .resize-guide-min-before, .resize-guide-min-after {
  width: 50px;
  color: var(--resize-guide-text-color);
  font-size: .8rem;
  position: absolute;
  bottom: 400px;
}

body.dark-skybox-invert-ui :is(.resize-guide-max-before, .resize-guide-max-after, .resize-guide-min-before, .resize-guide-min-after) {
  color: var(--resize-guide-text-inverted-color);
}

.resize-guide-max-before {
  text-align: right;
  left: -55px;
}

.resize-guide-max-after {
  text-align: left;
  right: -55px;
}

.resize-guide-min-before {
  text-align: left;
  left: 5px;
}

.resize-guide-min-after {
  text-align: right;
  right: 5px;
}

.segment-label-container {
  --segment-rule-color: #a0a0a0;
  --segment-grid-height: 10px;
  border-left: 1px solid var(--segment-rule-color);
  border-right: 1px solid var(--segment-rule-color);
  left: 0;
  right: -1px;
  top: var(--canvas-baseline);
  justify-content: center;
  height: 90px;
  font-size: .9em;
  display: flex;
  position: absolute;
}

.segment-label {
  color: var(--segment-text);
  pointer-events: none;
  line-height: 1.4;
  position: absolute;
  top: 44px;
  left: 2px;
  right: 2px;
}

.segment-label > p {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  margin: 0;
  overflow: hidden;
}

.segment.hover .segment-label {
  z-index: 1;
  background-color: var(--bottom-background);
  margin-top: -4px;
  padding: 4px;
  font-weight: 600;
  left: auto;
  right: auto;
  overflow: visible;
}

.segment.warning .segment-label {
  color: var(--warning-color);
}

.segment-width {
  height: 24px;
  color: var(--segment-text);
  pointer-events: none;
  line-height: 20px;
  position: absolute;
  top: 22px;
  left: 0;
  right: 0;
  overflow: hidden;
}

:root[dir="rtl"] .segment-width {
  direction: rtl !important;
}

.segment-grid {
  height: var(--segment-grid-height);
  pointer-events: none;
  background-image: linear-gradient(90deg, var(--segment-rule-color) 1px, transparent 1px);
  background-repeat: repeat-x;
  position: absolute;
  top: 0;
  left: -1px;
  right: -1px;
}

.segment-grid.units-imperial {
  background-size: var(--tile-size) 100%;
}

.segment-grid.units-metric {
  background-size: calc(var(--tile-size) * 100 / 30 / 2) 100%;
}

.segment-empty {
  pointer-events: none;
  z-index: -1;
  position: absolute;
}

.segment-empty .segment-label, .segment-empty .segment-width {
  color: var(--segment-text);
  opacity: .5;
}

.sky-background {
  width: 100vw;
  height: 100%;
  position: absolute;
  left: 0;
}

.sky-background-color {
  will-change: opacity;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.sky-background-objects {
  width: 100vw;
  height: 100%;
  position: absolute;
  left: 0;
}

.sky-background-objects > div {
  opacity: 0;
  transition: opacity var(--skybox-transition), transform var(--skybox-transition);
  transform: translateY(-14px);
}

.sky-background-object-enter-done {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.street-section-sky {
  pointer-events: none;
  opacity: 1;
  height: calc(100% - 100px);
  position: absolute;
  top: -70px;
  left: 0;
  right: 0;
}

body.read-only .street-section-sky {
  height: calc(100% - 20px);
}

.rear-clouds, .front-clouds {
  width: 100%;
  transition: opacity var(--skybox-transition);
  background-position: 0 0;
  background-repeat: repeat-x;
  position: absolute;
  left: 0;
}

.rear-clouds {
  background-image: url("sky-rear.5c350576.svg");
  background-size: 250px 250px;
  height: 250px;
  bottom: 205px;
}

.front-clouds {
  background-image: url("sky-front.8927059c.svg");
  background-size: 250px 280px;
  height: 280px;
  bottom: 55px;
}

.sky-animations .rear-clouds {
  animation-name: rear-clouds-move;
  animation-duration: 60s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.sky-animations .front-clouds {
  animation-name: front-clouds-move;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes rear-clouds-move {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 250px 0;
  }
}

@keyframes front-clouds-move {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 250px 0;
  }
}

.sky-foreground {
  width: 100%;
  height: 100%;
  transition: opacity var(--skybox-transition);
  z-index: var(--z-01-foreground);
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.street-scroll-indicators {
  position: absolute;
  bottom: 0;
}

.street-scroll-indicators button {
  opacity: .25;
  letter-spacing: -.15em;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  height: 80px;
  z-index: var(--z-07-street-scroll);
  background-color: #0000;
  border: 0;
  margin-top: -80px;
  padding: 0 .2em;
  font-size: 80px;
  font-weight: normal;
  line-height: 1;
  position: fixed;
}

:is(.street-scroll-indicators button):hover {
  opacity: .45;
}

:is(.street-scroll-indicators button):active {
  opacity: .65;
}

[dir="rtl"] :is(.street-scroll-indicators button) {
  direction: ltr;
}

.street-scroll-indicator-left {
  left: 0;
}

.street-scroll-indicator-right {
  right: 0;
  padding-right: .4em !important;
}

.street-section-dirt {
  --dirt-height: 40px;
  --dirt-height-minimum: 5px;
  --ground-height-minimum: 235px;
  left: 0;
  right: 0;
  top: calc(var(--canvas-height)  - 80px + var(--dirt-height));
  border-top: var(--dirt-height-minimum) solid var(--background-dirt-color);
  height: max(calc(50vh - (var(--street-section-inner-height) / 2)), var(--ground-height-minimum));
  background: var(--bottom-background);
  z-index: -2;
  pointer-events: none;
  position: absolute;
}

.street-section-dirt-left, .street-section-dirt-right {
  content: " ";
  top: calc(-1 * (var(--dirt-height)  + var(--dirt-height-minimum)));
  background-color: var(--background-dirt-color);
  width: 360px;
  height: calc(var(--dirt-height)  + var(--dirt-height-minimum));
  position: absolute;
}

.street-section-dirt-left {
  left: 0;
}

.street-section-dirt-right {
  right: 0;
}

body.segment-resize-dragging, body.segment-resize-dragging * {
  cursor: col-resize !important;
}

.drag-handle {
  border-top: 5px solid var(--color-turquoise-200);
  border-bottom: 5px solid var(--color-turquoise-200);
  border-radius: var(--border-radius-medium);
  width: 30px;
  height: 100px;
  color: var(--color-turquoise-700);
  z-index: var(--z-06-drag-handle);
  pointer-events: none;
  background: var(--info-bubble-background);
  cursor: col-resize;
  opacity: 0;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  transition: transform .15s, opacity .15s;
  display: flex;
  position: absolute;
  top: 320px;
}

.drag-handle:hover {
  color: var(--color-turquoise-800);
}

.drag-handle-left {
  transform-origin: 100%;
  left: -5px;
  transform: rotateY(90deg);
}

.drag-handle-right {
  transform-origin: 0;
  right: -5px;
  transform: rotateY(-90deg);
}

.segment.immediate-show-drag-handles .drag-handle {
  transition: none !important;
}

.drag-handle.floating {
  background-color: var(--color-turquoise-700);
}

body.segment-resize-dragging .drag-handle.floating, .segment.show-drag-handles .drag-handle {
  pointer-events: auto;
  opacity: 1;
  transform: none;
}

.segment {
  height: calc(var(--canvas-height)  + 85px + 30px);
  text-align: center;
  perspective: 500px;
  touch-action: none;
  display: block;
  position: absolute;
  top: -150px;
}

.segment-canvas-container {
  width: 100%;
  height: 100%;
}

body.segment-move-dragging .segment-empty {
  display: none;
}

body:not(.segment-resize-dragging) .segment {
  transition: width .15s, left .15s, transform .15s;
}

body.immediate-segment-resize .segment {
  transition: none !important;
}

.segment.hover {
  z-index: var(--z-02-segment-focused) !important;
}

.segment.hover .hover-bk {
  background: var(--segment-hover-background);
  z-index: -1;
  height: calc(var(--canvas-baseline)  - 10px);
  pointer-events: none;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
}

body:not(.read-only) .segment {
  cursor: grab;
}

.segment.dragged-out {
  opacity: 0;
}

body.segment-move-dragging, body.segment-move-dragging * {
  cursor: grabbing !important;
}

body.not-within-canvas, body.not-within-canvas * {
  cursor: no-drop !important;
}

body.segment-move-dragging #street-section-editable .segment {
  height: calc(var(--canvas-height)  + 85px + 30px + 90px);
  transition: left .1s, transform .3s;
}

.switching-away-exit {
  z-index: var(--z-10-switch-away);
  pointer-events: none;
  perspective: 400px;
  position: absolute;
  transition: none !important;
  transform: none !important;
}

.switching-away-exit-done {
  z-index: var(--z-10-switch-away) !important;
}

.switching-away-exit-active canvas {
  transform-origin: 50% 120%;
  opacity: 0;
  transform: rotateX(-60deg);
  transition: transform var(--segment-switching-time) ease-in, opacity var(--segment-switching-time) ease-in !important;
}

.switching-in-enter {
  perspective: 400px;
  z-index: -1 !important;
}

.switching-in-enter canvas {
  transform-origin: 50% 100%;
  opacity: 0;
  transform: rotateX(90deg);
}

.switching-in-enter-done {
  z-index: -1 !important;
}

.switching-in-enter-active canvas {
  opacity: 1;
  transform: none;
  transition: transform var(--segment-switching-time), opacity var(--segment-switching-time) !important;
}

#street-section-outer {
  --building-space: 360px;
  inset: calc(-1 * var(--gallery-height)) 0 -20px;
  touch-action: pan-x;
  overscroll-behavior-x: contain;
  display: block;
  position: absolute;
  overflow: scroll hidden;
}

[dir="rtl"] #street-section-outer {
  direction: ltr;
}

#street-section-inner {
  height: var(--street-section-inner-height);
  -webkit-user-select: none;
  user-select: none;
  display: block;
  position: absolute;
  bottom: 130px;
  left: 0;
}

body.read-only #street-section-inner {
  bottom: 50px;
}

#street-section-editable {
  height: calc(var(--canvas-height)  + 80px);
  text-align: left;
  touch-action: pan-x;
  position: absolute;
}

#street-section-canvas {
  z-index: var(--z-01-street-section);
  margin-left: var(--building-space);
  margin-right: var(--building-space);
  height: calc(var(--canvas-height)  + 40px);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.street-section-building {
  width: 396px;
  height: calc(var(--canvas-height)  + 70px);
  touch-action: pan-x;
  display: block;
  position: absolute;
  top: -105px;
}

.street-section-building .hover-bk {
  z-index: -1;
  position: absolute;
  inset: -1000px 0 45px;
}

.street-section-building.hover .hover-bk {
  background: var(--segment-hover-background);
}

.street-section-building canvas {
  position: absolute;
  bottom: 0;
}

.street-segment-building-left canvas {
  left: -1px;
}

.street-segment-building-right canvas {
  right: 0;
}

.print-container {
  width: 100%;
  height: 100%;
  z-index: var(--z-10-print);
  background-color: #fff;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.print-container img {
  max-width: 100%;
  max-height: 100%;
}

@media print {
  .print-container {
    display: flex !important;
  }

  body > :not(#react-app), #react-app > :not(.print-container) {
    display: none;
  }
}

.welcome-panel-container {
  --welcome-panel-border-radius: var(--border-radius);
  --welcome-panel-box-shadow: var(--medium-box-shadow);
  z-index: var(--z-05-welcome);
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.welcome-panel {
  text-align: center;
  color: #404040;
  pointer-events: auto;
  border-bottom-left-radius: var(--welcome-panel-border-radius);
  border-bottom-right-radius: var(--welcome-panel-border-radius);
  box-shadow: var(--welcome-panel-box-shadow);
  background: #fff;
  margin: 0;
  padding: .5em 1.5em .75em;
  font-size: 1.2rem;
  display: inline-block;
  position: relative;
}

.welcome-panel-content h1 {
  margin-top: .5em;
  margin-bottom: .5em;
}

.welcome-panel-content button {
  margin: 0 .25em;
  padding: .5em 1em;
}

.welcome-panel-content ul, .welcome-panel-content p, .welcome-panel-content .paragraph {
  margin: 0 0 5px;
  padding: 0;
  list-style: none;
  display: inline-block;
}

.welcome-panel-content p, .welcome-panel-content .paragraph {
  max-width: 700px;
  line-height: 1.5;
  display: block;
}

.welcome-panel-content li {
  margin: 0;
  padding: 0;
  display: inline-block;
}

:is(.welcome-panel-content li):not(:first-child):before {
  content: "·";
  color: var(--color-turquoise-700);
  margin: 0 5px;
  padding-left: 0;
}

.welcome-panel-content input[type="radio"] {
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  display: none;
}

.welcome-panel-content label {
  cursor: pointer;
}

:is(.welcome-panel-content label):hover {
  color: #000;
}

.welcome-panel-content input[type="radio"]:checked + label {
  color: #000;
  font-weight: 550;
}

.welcome-panel-content input[type="radio"]:not(:checked) + label {
  text-decoration: underline;
}

.welcome-panel .street-name {
  --street-name-size: var(--street-name-size-small);
  vertical-align: middle;
  max-width: 100%;
  margin: -.1em .25em 0;
  display: inline-block;
}

.notification-bar {
  z-index: var(--z-08-notification-bar);
  text-align: center;
  background-color: var(--alert-background);
  border-bottom: var(--alert-border);
  padding: .75em calc(36px + 1.5em);
  position: relative;
}

.notification-bar .close {
  --close-icon-color: var(--color-copper-800);
  --close-icon-color-hover: var(--color-copper-700);
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 0;
  top: 7px;
  right: 6px;
  align-items: center !important;
  display: flex !important;
}

:is(.notification-bar .close):hover {
  background-color: #ffffff80;
}

:is(.notification-bar .close):active {
  background-color: #ffffffbf;
}

:is(.notification-bar .close) svg {
  color: var(--close-icon-color) !important;
}

:is(.notification-bar .close):hover svg {
  color: var(--close-icon-color-hover) !important;
}

.sponsor-banner {
  z-index: var(--z-08-notification-bar);
  background-color: var(--background-dirt-color);
  border-top: 5px solid color-mix(in oklch, black 10%, var(--background-dirt-color));
  color: #fff;
  padding: .5em 1em .75em;
  position: relative;
}

.sponsor-title {
  text-transform: uppercase;
  opacity: .8;
  margin-right: 1em;
  font-weight: 550;
}

.sponsor-banner-misc a {
  color: #c1c1c1;
  opacity: .5;
  text-decoration: underline;
}
/*# sourceMappingURL=main.css.map */
