.sponsor-banner {
  position: relative;
  z-index: var(--z-08-notification-bar);
  padding: 0.5em 1em 0.75em;
  background-color: var(--background-dirt-color);
  border-top: 5px solid
    color-mix(in oklch, black 10%, var(--background-dirt-color));
  color: white;
}

.sponsor-title {
  text-transform: uppercase;
  font-weight: 550;
  opacity: 0.8;
  margin-right: 1em;
}

.sponsor-banner-misc a {
  color: #c1c1c1;
  opacity: 0.5;
  text-decoration: underline;
}
