body.segment-resize-dragging,
body.segment-resize-dragging * {
  cursor: col-resize !important;
}

.drag-handle {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 320px;
  width: 30px;
  height: 100px;
  border-top: 5px solid var(--color-turquoise-200);
  border-bottom: 5px solid var(--color-turquoise-200);
  border-radius: var(--border-radius-medium);
  font-size: 2.5rem;
  color: var(--color-turquoise-700);
  z-index: var(--z-06-drag-handle);
  pointer-events: none;
  background: var(--info-bubble-background);
  cursor: col-resize;
  transition:
    transform 150ms,
    opacity 150ms;
  opacity: 0;

  &:hover {
    color: var(--color-turquoise-800);
  }
}

.drag-handle-left {
  left: -5px;
  transform-origin: 100% 50%;
  transform: rotateY(90deg);
}

.drag-handle-right {
  right: -5px;
  transform-origin: 0 50%;
  transform: rotateY(-90deg);
}

.segment.immediate-show-drag-handles .drag-handle {
  transition: none !important;
}

.drag-handle.floating {
  background-color: var(--color-turquoise-700);
}

body.segment-resize-dragging .drag-handle.floating,
.segment.show-drag-handles .drag-handle {
  pointer-events: auto;
  opacity: 1;
  transform: none;
}
