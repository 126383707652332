.blocking-shield {
  z-index: var(--z-09-blocking-shield);
  background: transparent;
  color: transparent;
  cursor: wait;

  h1 {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  button {
    cursor: pointer;
  }

  &.hidden {
    display: none;
  }

  &.darken,
  &.darken-immediately {
    /* While the preferred mixing method is in oklch, there is a bug(?) in
       Safari where doing so also causes the hue to shift unexpectedly.
       Mixing transparency in srgb will only affect alpha channel as expected
       in tested browsers (Firefox + Chrome) including Safari. */
    background-color: color-mix(
      in srgb,
      var(--color-turquoise-200),
      transparent 15%
    );
    color: black;
  }

  &.darken {
    transition:
      background 1500ms,
      color 1500ms;
  }

  &.darken-immediately {
    transition: none;
  }
}
