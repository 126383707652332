.instance-badge {
  z-index: var(--z-02-menu-bar);
  display: none;
  position: absolute;
  left: 9px;
  bottom: -14px;
  padding: 0.25em 1em;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 550;
  line-height: 1.5;
  letter-spacing: 0.5em;
  padding-right: 0.5em; /* Right padding minus letter-spacing */
  color: white;
  background-color: gray;
  pointer-events: none;
  border-radius: var(--border-radius);

  &.instance-label-development {
    display: inline-block;
    background-color: rgb(200 0 0);
  }

  &.instance-label-staging,
  &.instance-label-sandbox {
    display: inline-block;
    background-color: green;
  }

  &.instance-label-demo {
    display: inline-block;
    background-color: rgb(28 189 209);
  }
}

[dir="rtl"] {
  .instance-badge {
    right: 9px;
    left: auto;
  }
}
