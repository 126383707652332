.segment-analytics {
  --analytics-color-1-main: var(--color-emerald-500);
  --analytics-color-1-alt: var(--color-emerald-200);
  --analytics-color-1-em: var(--color-emerald-600);
  --analytics-color-2-main: var(--color-turquoise-500);
  --analytics-color-2-alt: var(--color-turquoise-200);
  --analytics-color-2-em: var(--color-turquoise-600);
  --analytics-color-3-main: var(--color-copper-500);
  --analytics-color-3-alt: var(--color-copper-200);
  --analytics-color-3-em: var(--color-copper-600);
  --analytics-color-4-main: var(--color-midnight-500);
  --analytics-color-4-alt: var(--color-midnight-200);
  --analytics-color-4-em: var(--color-midnight-600);

  position: relative;
  width: 100%;
  margin-bottom: 0.25em;

  /* not a great no-wrap solution but we're trying it for now */
  white-space: nowrap;
}

.capacity-bars {
  position: relative;
  height: 50px;
  margin-right: 10px;
  display: inline-block;
  min-width: 25px;
  margin-left: 25px;
}

.capacity-icon {
  position: absolute;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  z-index: 1;

  [data-color="1"] & {
    background-color: var(--analytics-color-1-main);
  }

  [data-color="2"] & {
    background-color: var(--analytics-color-2-main);
  }

  [data-color="3"] & {
    background-color: var(--analytics-color-3-main);
  }

  [data-color="4"] & {
    background-color: var(--analytics-color-4-main);
  }
}

.capacity-icon-image {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: white;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  margin-left: 4px;
  margin-top: 4px;
  border: 3px solid;
  overflow: hidden;

  [data-color="1"] & {
    border-color: var(--analytics-color-1-em);
  }

  [data-color="2"] & {
    border-color: var(--analytics-color-2-em);
  }

  [data-color="3"] & {
    border-color: var(--analytics-color-3-em);
  }

  [data-color="4"] & {
    border-color: var(--analytics-color-4-em);
  }

  img {
    width: 100%;
  }
}

.capacity-bar {
  position: absolute;
  left: 0;
  height: 50px;
  border-radius: 0 25px 25px 0;
}

.capacity-bar.capacity-bar-average {
  [data-color="1"] & {
    background-color: var(--analytics-color-1-main);
  }

  [data-color="2"] & {
    background-color: var(--analytics-color-2-main);
  }

  [data-color="3"] & {
    background-color: var(--analytics-color-3-main);
  }

  [data-color="4"] & {
    background-color: var(--analytics-color-4-main);
  }
}

.capacity-bar.capacity-bar-potential {
  [data-color="1"] & {
    background-color: var(--analytics-color-1-alt);
  }

  [data-color="2"] & {
    background-color: var(--analytics-color-2-alt);
  }

  [data-color="3"] & {
    background-color: var(--analytics-color-3-alt);
  }

  [data-color="4"] & {
    background-color: var(--analytics-color-4-alt);
  }
}

.capacity-text {
  display: inline-block;
  vertical-align: top;

  .capacity-label {
    font-weight: 550;
  }
}
