.toast {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);

  /* Note: width and height must be set in JavaScript to
     match exact width and height of .toast-content child */
  width: auto;
  height: auto;

  /* Allow pointer interaction */
  pointer-events: auto;
}

.toast-content {
  position: relative;
  display: inline-block; /* Variable width toast */
  max-width: 330px;
  min-width: 200px;
  padding: 1em 1.25em;
  font-size: 13px;
  border-top: var(--alert-border);
  background-color: var(--alert-background);

  /* Restore line-height after being unset by parent element */
  line-height: 1.4;
}

.toast-body {
  padding-right: 20px; /* Space for close button */

  [dir="rtl"] & {
    padding-left: 20px; /* Space for close button */
    padding-right: 0;
  }
}

.toast-message {
  margin: 0;
}

.toast-action {
  margin-top: 1em;
}

.toast-action button {
  display: block;
  border: 1px solid var(--color-copper-600);
  border-radius: var(--border-radius-medium);
  padding: 0.25em 0.5em;
  margin: 0;
  width: 100%;
  min-height: 32px;
  font-weight: 550;
  background-color: transparent;
  color: var(--color-copper-800);
  cursor: pointer;

  &:hover {
    background-color: transparent;
    color: var(--color-copper-900);
  }
}

/* Close button overrides */
.toast .close {
  --close-icon-color: var(--color-copper-800);
  --close-icon-color-hover: var(--color-copper-700);

  width: 24px;
  height: 24px;
  padding: 0;
  top: 6px;
  right: 6px;
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  color: var(--close-icon-color);

  [dir="rtl"] & {
    right: auto;
    left: 6px;
  }

  &:hover {
    background-color: rgb(255 255 255 / 50%);
    color: var(--close-icon-color-hover);
  }

  &:active {
    background-color: rgb(255 255 255 / 75%);
  }
}

.toast h3 {
  margin: 0;
  margin-bottom: 0.25em;
}

.toast-success {
  .toast-content {
    background-color: var(--color-emerald-300);
    border-top-color: var(--color-emerald-400);
  }

  h3 {
    color: var(--color-emerald-900);
  }

  .close {
    color: var(--color-emerald-700);

    &:hover {
      color: var(--color-emerald-800);
    }
  }

  .toast-action button {
    color: var(--color-emerald-800);
    border-color: var(--color-emerald-700);

    &:hover {
      color: var(--color-emerald-900);
    }
  }
}

.toast-warning {
  .toast-content {
    background-color: #fff9f9;
    border-top-color: #ff5b5b;
  }

  h3 {
    color: #a00101;
  }

  .close {
    color: #c10000;

    &:hover {
      color: #a00101;
    }
  }

  .toast-action button {
    color: #c10000;
    border-color: #c10000;

    &:hover {
      color: #a00101;
    }
  }
}
