.street-name {
  --street-name-text: black;
  --street-name-background: white;

  display: inline-block;
  padding: calc(3px * var(--street-name-size));
  background: var(--street-name-background);
  color: var(--street-name-text);
  user-select: none;
  white-space: nowrap;
}

.street-name-text {
  font-family: "Overpass Variable", sans-serif;
  font-weight: 700;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;

  /* Set size of text, border, etc */
  height: calc(50px * var(--street-name-size));
  border: calc(3px * var(--street-name-size)) solid var(--street-name-text);
  padding: calc(11px * var(--street-name-size))
    calc(20px * var(--street-name-size)) calc(5px * var(--street-name-size));
  font-size: calc(32px * var(--street-name-size));
  line-height: calc(28px * var(--street-name-size));
  letter-spacing: -0.025em;

  [dir="rtl"] {
    font-size: calc(30px * var(--street-name-size));
  }
}

body.segment-move-dragging .street-nameplate-container .street-name {
  pointer-events: none;
}

.street-nameplate-container .street-name {
  position: relative;
  max-width: 100%;
  pointer-events: auto;
}

.street-name-hover-prompt {
  position: absolute;
  inset: 0;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  background-color: rgb(255 255 255 / 80%);
  cursor: pointer;
  white-space: normal;
}
