body.gallery-visible {
  .main-screen {
    transform: translateY(calc(var(--gallery-height) - 90px));
  }

  .street-nameplate-container {
    transform: translateY(60px);
  }
}

body:not(.safari).gallery-visible .street-section-sky {
  transform: translateY(-20px);
}

.gallery-panel {
  --gallery-border-radius: var(--border-radius);
  --gallery-box-shadow: var(--medium-box-shadow);

  position: absolute;
  left: var(--left-right-inset);
  right: var(--left-right-inset);
  top: 0;
  height: var(--gallery-height);
  display: flex;
  flex-direction: column;
  padding: 10px 16px 16px;
  z-index: var(--z-09-gallery);
  border-bottom-left-radius: var(--gallery-border-radius);
  border-bottom-right-radius: var(--gallery-border-radius);
  background: white;
  box-shadow: var(--gallery-box-shadow);
  overflow: hidden;
  pointer-events: none;
  transform: translateY(calc(-1 * var(--gallery-height))) rotateX(25deg);
  transform-origin: 50% 0;
}

.gallery-loading,
.gallery-error {
  position: absolute;
  inset: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: var(--z-03-gallery-message);

  a {
    color: inherit;
  }
}

body:not(.gallery-no-move-transition) .main-screen,
body:not(.gallery-no-move-transition) .gallery-panel,
body:not(.gallery-no-move-transition) .street-nameplate-container,
body:not(.gallery-no-move-transition) .street-section-sky {
  transition: transform 800ms;
}

body.gallery-visible .gallery-panel {
  transform: translateY(0);
  pointer-events: auto;
}
