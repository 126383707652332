.slider-root {
  --slider-thumb-color: var(--color-turquoise-700);
  --slider-thumb-color-hover: var(--color-turquoise-600);
  --slider-thumb-size: 19px;
  --slider-track-color: var(--color-turquoise-200);
  --slider-track-height: 7px;
  --slider-range-color: var(--color-turquoise-500);

  position: relative;
  display: flex;
  width: 100%;
  height: max(var(--slider-thumb-size), var(--slider-track-height));
  margin: 0.25em 0;
  align-items: center;
  user-select: none;
  touch-action: none;
}

.slider-track {
  position: relative;
  flex-grow: 1;
  height: var(--slider-track-height);
  border-radius: var(--border-radius-pill);
  background-color: var(--slider-track-color);

  &[data-disabled] {
    background-color: var(--color-midnight-100);
  }
}

.slider-range {
  position: absolute;
  top: 0;
  left: 0;
  height: var(--slider-track-height);
  border-radius: var(--border-radius-pill);
  background-color: var(--slider-range-color);

  &[data-disabled] {
    background-color: var(--color-midnight-300);
  }
}

.slider-thumb {
  position: absolute;
  display: block;
  width: var(--slider-thumb-size);
  height: var(--slider-thumb-size);
  margin-top: calc(var(--slider-thumb-size) / -2);
  margin-left: calc(var(--slider-thumb-size) / -2);
  border-radius: var(--border-radius-pill);
  background-color: var(--slider-thumb-color);
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0 0 0 / 14%);

  &[data-disabled] {
    background-color: var(--color-midnight-400);
  }

  &:hover:not([data-disabled]) {
    background-color: var(--slider-thumb-color-hover);
  }

  &:focus:not([data-disabled]) {
    box-shadow:
      inset 0 0 0 1px white,
      0 0 0 1px white,
      /* Match background color */ 0 0 0 3px var(--color-turquoise-700);
  }
}
