.street-meta-item {
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-bottom: 0.2em;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    padding: 0 0.5em;
    cursor: pointer;
    border-radius: var(--border-radius-large);

    &:hover {
      background-color: rgba(255 255 255 / 20%);
    }
  }

  /* Links that appear in meta items (currently: only in Author)
     should have the same text color as rest of component */
  a {
    color: inherit;

    /* In read-only mode, links are still interactive, but
       made to look like other text */
    body.read-only & {
      text-decoration: none;
      cursor: default;
    }
  }
}

.street-meta-item-plain {
  /* Match button dimensions but not behavior */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  padding: 0 0.5em;

  /* Underlines are hidden */
  .underline {
    text-decoration: none;
  }
}

.street-meta-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.3em;

  svg {
    width: 16px;
    height: 16px;
  }

  [dir="rtl"] & {
    margin-left: 0.3em;
    margin-right: auto;
  }

  [data-icon-source="feather"] {
    stroke-width: 1.5;
  }
}

.street-meta-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}
