/**
 *  Global variables (via CSS custom properties)
 *  ------------------------------------------------------- */

:root {
  /* Dimensions */
  --tile-size: 12px;
  --canvas-height: 480px;
  --canvas-ground: 35px;
  --canvas-baseline: calc(var(--canvas-height) - var(--canvas-ground) + 150px);
  --street-section-inner-height: calc(var(--canvas-height) + 45px);
  --gallery-height: 180px;
  --left-right-inset: 50px;
  --menu-side-inset: calc(var(--left-right-inset) - 20px);
  --border-radius-small: 2px;
  --border-radius-medium: 4px;
  --border-radius-large: 6px;
  --border-radius-pill: 999em; /* because 50% creates ovals, not pills */
  --border-radius: var(--border-radius-medium); /* alias */
  --breakpoint-md: 768px;

  /* Brand colors */
  --color-emerald-100: rgb(219 248 220);
  --color-emerald-200: rgb(203 246 213);
  --color-emerald-300: rgb(158 244 182);
  --color-emerald-400: rgb(117 228 153);
  --color-emerald-500: rgb(91 193 125);
  --color-emerald-600: rgb(81 173 112);
  --color-emerald-700: rgb(65 141 90);
  --color-emerald-800: rgb(47 105 66);
  --color-emerald-900: rgb(28 68 41);
  --color-turquoise-100: rgb(222 243 249);
  --color-turquoise-200: rgb(210 240 248);
  --color-turquoise-300: rgb(193 235 247);
  --color-turquoise-400: rgb(116 217 244);
  --color-turquoise-500: rgb(71 195 225);
  --color-turquoise-600: rgb(59 166 192);
  --color-turquoise-700: rgb(46 135 156);
  --color-turquoise-800: rgb(33 100 117);
  --color-turquoise-900: rgb(18 65 76);
  --color-copper-100: rgb(250 235 226);
  --color-copper-200: rgb(250 229 217);
  --color-copper-300: rgb(249 212 190);
  --color-copper-400: rgb(248 184 144);
  --color-copper-500: rgb(249 148 77);
  --color-copper-600: rgb(223 119 36);
  --color-copper-700: rgb(182 96 28);
  --color-copper-800: rgb(137 70 17);
  --color-copper-900: rgb(90 44 9);
  --color-midnight-100: rgb(237 238 242);
  --color-midnight-200: rgb(231 233 238);
  --color-midnight-300: rgb(216 220 229);
  --color-midnight-400: rgb(194 201 216);
  --color-midnight-500: rgb(167 177 199);
  --color-midnight-600: rgb(123 139 173);
  --color-midnight-700: rgb(104 122 162);
  --color-midnight-800: rgb(67 89 139);
  --color-midnight-900: rgb(41 57 94);

  /* Supporting colors
   * These are on a 5-point scale instead of 9 */
  --color-citrine-100: rgb(253 243 209);
  --color-citrine-200: rgb(250 234 164);
  --color-citrine-300: rgb(255 215 85);
  --color-citrine-400: rgb(255 184 67);
  --color-citrine-500: rgb(255 131 0);

  /* TODO: Color scales for gray/white, black/dark, and red */
  --color-gray-100: #eee; /* ported from old color */
  --color-gray-200: #dcdcdc; /* ported from old color */
  --color-gray-400: #999; /* ported from actual usage */

  /* Misc colors */
  --background-dirt-color: rgb(53 45 39);
  --sky-color: rgb(169 204 219); /* deprecated for everything except palette */
  --off-white: rgb(247 247 247);
  --bottom-background: rgb(216 211 203);
  --warning-color: rgb(220 20 20);
  --segment-text: rgb(40 35 30);
  --segment-hover-background: rgb(255 255 255 / 20%);
  --info-bubble-background: white; /* TODO: rename */

  /* Link and button text */
  --interactive-text-color: var(--color-turquoise-700);
  --interactive-text-hover-color: var(--color-turquoise-800);
  --interactive-text-active-color: var(--color-turquoise-600);
  --interactive-element-hover-color: var(--color-turquoise-100);

  /* Other UI */
  --form-element-background: var(--color-turquoise-100);
  --form-element-border: var(--color-turquoise-200);
  --button-border: 1px solid var(--color-turquoise-600);
  --alert-background: var(--color-citrine-100);
  --alert-border: 5px solid var(--color-citrine-300);
  --alert-text-color: var(--color-citrine-500);

  /* Third-party brand colors (e.g. social, etc) in alphabetical order
   * All colors must be official (please update them as necessary)
   * unless noted (e.g. if we need a special color for contrast, etc)
   * If there isn't an official dark color for hover, it is computed */

  /* Note: color-mix() is a draft specification.
   * https://drafts.csswg.org/css-color-5 */

  /* Discord (https://discord.com/branding) */
  --social-discord: #5865f2;
  --social-discord-hover: color-mix(in oklch, black 10%, var(--social-discord));

  /* Facebook (https://about.meta.com/brand/resources/facebookapp/logo) */
  --social-facebook: rgb(24 119 242);
  --social-facebook-hover: color-mix(
    in oklch,
    black 10%,
    var(--social-facebook)
  );

  /* GitHub (https://github.com/logos) */
  --social-github: #171515;
  --social-github-hover: color-mix(in oklch, black 10%, var(--social-github));

  /* Instagram
   * Difficult to reproduce their gradient, so we use colors from
   * https://brandpalettes.com/instagram-color-codes/ */
  --social-instagram: #e1306c;
  --social-instagram-hover: #c13584;

  /* Bluesky
     No brand guidelines found, color is picked from logo file */
  --social-bluesky: #009bff;
  --social-bluesky-hover: color-mix(in oklch, black 10%, var(--social-bluesky));

  /* Mastodon (https://joinmastodon.org/branding) */
  --social-mastodon: #6364ff;
  --social-mastodon-hover: #563acc;

  /* Twitter (https://about.twitter.com/en/who-we-are/brand-toolkit) */
  --social-twitter: #4a99e9;
  --social-twitter-hover: color-mix(in oklch, black 10%, var(--social-twitter));

  /* Layered UI box shadows */
  --light-box-shadow: 0 0 14px 0 rgb(0 0 0 / 7.5%);
  --medium-box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
  --heavy-box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%); /* UNUSED */

  /* Design */
  --street-name-size: 1.5;
  --street-name-size-small: 0.5;

  /* Typography */
  --font-family: "Rubik Variable", system-ui, sans-serif;
  --font-size-base: 13px;
  --header-font-family: "Manrope Variable", system-ui, sans-serif;

  /* Timing */
  --segment-switching-time: 250ms;
  --skybox-transition: 500ms ease;

  /* Z-index scale */
  --z-index-01: 100;
  --z-index-02: 200;
  --z-index-03: 300;
  --z-index-04: 400;
  --z-index-05: 500;
  --z-index-06: 600;
  --z-index-07: 700;
  --z-index-08: 800;
  --z-index-09: 900;
  --z-index-10: 1000;

  /* Z-index components
   * Variables are assigned to a scale value, defined above. Components are
   * ordered so it's easy to see the hierarchy, and variable names also
   * contain the scale value so it's visible in the CSS files where they're
   * used. If a scale value needs to be fine-tuned, + a number to it.
   * As a remninder, this z-index scale does not override the constraints
   * imposed by stacking contexts. */
  --z-01-main-screen: var(--z-index-01);
  --z-01-street-section: var(--z-index-01);
  --z-01-foreground: var(--z-index-01);
  --z-02-segment-focused: var(--z-index-02);
  --z-02-palette: var(--z-index-02);
  --z-02-menu-bar: var(--z-index-02);
  --z-03-street-name: var(--z-index-03);
  --z-03-gallery-message: var(--z-index-03);
  --z-04-drag-layer: var(--z-index-04);
  --z-05-welcome: var(--z-index-05);
  --z-06-info-bubble: var(--z-index-06);
  --z-06-drag-handle: var(--z-index-06);
  --z-07-street-scroll: var(--z-index-07);
  --z-07-toasts: var(--z-index-07);
  --z-07-trashcan: var(--z-index-07);
  --z-07-resize-guide: var(--z-index-07);
  --z-07-debug-hover-polygon: var(--z-index-07);
  --z-07-sky-picker: calc(var(--z-index-07) + 50);
  --z-07-menu: calc(var(--z-index-07) + 75);
  --z-08-dialog-box-backdrop: var(--z-index-08);
  --z-08-gallery-shield: var(--z-index-08);
  --z-08-notification-bar: var(--z-index-08);
  --z-09-gallery: var(--z-index-09);
  --z-09-dialog-box: var(--z-index-09);
  --z-09-debug: var(--z-index-09);

  /* Blocking overlays
   * These live at the highest level of z-index scale. */
  --z-09-loading: var(--z-index-09);
  --z-09-error: var(--z-index-09);
  --z-09-blocking-shield: var(--z-index-09);
  --z-10-flash-border: var(--z-index-10);
  --z-10-switch-away: var(--z-index-10);
  --z-10-print: var(--z-index-10);
}
