/* Styles must be used in conjunction with the default tippy.js css */
.tippy-box {
  --tooltip-background-color: #2a2b2a;
  --tooltip-font-color: white;
  --tooltip-font-weight: 550;
  --tooltip-max-width: 150px;

  background-color: var(--tooltip-background-color);
  padding: 0.5em 1.25em;
  font-weight: var(--tooltip-font-weight);
  color: var(--tooltip-font-color);
  border-radius: var(--border-radius);
  text-align: center;
  max-width: var(--tooltip-max-width);
  box-shadow: var(--medium-box-shadow);
  opacity: 0.85;
}

.tippy-content {
  padding: 0;

  p {
    margin: 0;
    font-weight: normal;
    font-size: 0.85em;
    opacity: 0.75;
  }
}

.tippy-arrow {
  color: var(--tooltip-background-color);
}
