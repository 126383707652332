.save-as-image-dialog {
  max-width: 720px;

  footer {
    text-align: center;
  }
}

/* Don't wrap the Creative Commons label. But this is a long link, so only
  wrap on big screens. */
@media only screen and (width >= 640px) {
  .save-as-image-dialog footer a {
    white-space: nowrap;
  }
}

.save-as-image-options {
  /* TODO: proper rtl support */
  text-align: left;
  margin-bottom: 1em;

  .checkbox-item {
    display: block;
  }

  /* Lock icon, if present */
  svg {
    vertical-align: text-top;
  }

  @media only screen and (width >= 640px) {
    & {
      text-align: center;

      .checkbox-item {
        display: inline-block;
        margin-left: 20px;
      }

      .checkbox-item:first-of-type {
        margin-left: 0;
      }

      [dir="rtl"] & .checkbox-item {
        margin-left: 0;
        margin-right: 20px;
      }

      [dir="rtl"] & .checkbox-item:first-of-type {
        margin-right: 0;
      }
    }
  }
}

.save-as-image-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  max-height: 300px;
  border-top: 3px dashed var(--color-turquoise-600);
  border-bottom: 3px dashed var(--color-turquoise-600);
}

.save-as-image-preview-loading {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255 255 255 / 90%);
}

.save-as-image-preview-image {
  /* Adds a grid for transparent backgrounds */
  background-image:
    linear-gradient(45deg, #eee 25%, transparent 25%),
    linear-gradient(-45deg, #eee 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #eee 75%),
    linear-gradient(-45deg, transparent 75%, #eee 75%);
  background-size: 24px 24px;
  background-position:
    0 0,
    0 12px,
    12px -12px,
    -12px 0;

  /* Display below border */
  z-index: -1;

  img {
    /* Images may have variable dimensions. Use max-width/max-height
    to preserve aspect ratio */
    max-width: 100%;
    max-height: 300px;
    cursor: grab;

    /* Center image within preview container */
    display: block;
    margin: 0 auto;
  }
}

.save-as-image-preview-error {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  padding: 3em;
}

.save-as-image-too-large-error {
  font-weight: 550;
  color: var(--warning-color);
}

.save-as-image-download {
  text-align: center;

  /* Override a reset to this line-height */
  button {
    line-height: inherit;
  }
}
