.custom-scale {
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;

  @media only screen and (width >= 640px) {
    & {
      flex-direction: row;
    }
  }
}

.custom-scale-label {
  display: flex;
  flex-grow: unset;
  font-weight: 550;
  margin-right: 1em;
  margin-top: 0.25em;

  [dir="rtl"] & {
    margin-left: 1em;
    margin-right: 0;
  }
}

.custom-scale-popover {
  margin-top: -0.2em;
}

.custom-scale-control {
  flex-grow: 1;
}

.custom-scale-info {
  color: var(--color-turquoise-800);
}

.custom-scale-disabled {
  color: var(--color-midnight-400);

  .custom-scale-info {
    color: var(--color-midnight-400);
  }
}
