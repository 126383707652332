.welcome-panel-container {
  --welcome-panel-border-radius: var(--border-radius);
  --welcome-panel-box-shadow: var(--medium-box-shadow);

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--z-05-welcome);
  text-align: center;
  user-select: none;
  pointer-events: none;
}

.welcome-panel {
  display: inline-block;
  position: relative;
  padding: 0.5em 1.5em 0.75em;
  margin: 0;
  background: white;
  text-align: center;
  font-size: 1.2rem;
  color: rgb(64 64 64);
  pointer-events: auto;
  border-bottom-left-radius: var(--welcome-panel-border-radius);
  border-bottom-right-radius: var(--welcome-panel-border-radius);
  box-shadow: var(--welcome-panel-box-shadow);
}

.welcome-panel-content {
  h1 {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  button {
    margin: 0 0.25em;
    padding: 0.5em 1em;
  }

  ul,
  p,
  .paragraph {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
  }

  p,
  .paragraph {
    line-height: 1.5;
    display: block;
    max-width: 700px;
  }

  li {
    display: inline-block;
    padding: 0;
    margin: 0;

    &:not(:first-child)::before {
      content: "·";
      margin: 0 5px;
      color: var(--color-turquoise-700);
      padding-left: 0;
    }
  }

  input[type="radio"] {
    display: none;
    border: 0;
    background: none;
    padding: 0;
    margin: 0;
  }

  label {
    cursor: pointer;

    &:hover {
      color: black;
    }
  }

  input[type="radio"]:checked + label {
    color: black;
    font-weight: 550;
  }

  input[type="radio"]:not(:checked) + label {
    text-decoration: underline;
  }
}

/* Component specific tweaks to <StreetName /> */
.welcome-panel .street-name {
  --street-name-size: var(--street-name-size-small);

  display: inline-block;
  vertical-align: middle;
  margin: 0 0.25em;
  margin-top: -0.1em;
  max-width: 100%;
}
