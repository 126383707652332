.menu-bar {
  --menu-bar-text-color: var(--segment-text);
  --menu-bar-text-color-active: var(--interactive-text-active-color);
  --menu-bar-border-radius: var(--border-radius);
  --menu-bar-background: var(--off-white);
  --menu-bar-box-shadow: var(--light-box-shadow);

  z-index: var(--z-02-menu-bar);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: absolute;
  left: var(--menu-side-inset);
  right: var(--menu-side-inset);
  top: 0;
  border-bottom-left-radius: var(--menu-bar-border-radius);
  border-bottom-right-radius: var(--menu-bar-border-radius);
  background: var(--menu-bar-background);
  background-clip: padding-box;
  box-shadow: var(--menu-bar-box-shadow);
  user-select: none;
  touch-action: none;

  /* Extra space above the menu bar which pans down when the gallery is open */
  &::before {
    content: "";
    position: absolute;
    top: -150px;
    width: 100%;
    height: 150px;
    background-color: var(--menu-bar-background);
  }

  body.read-only & {
    display: none;
  }

  ul {
    display: flex;
    list-style: none;
    padding: 3px 1em;
    margin: 0;
    line-height: 32px;
    color: var(--menu-bar-text-color);
  }

  li {
    display: inline-block;
    white-space: nowrap;
  }

  a {
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    height: 32px;
    padding: 0 10px;

    &:active {
      color: var(--menu-bar-text-color-active);
    }

    /* Button "hover" state is actually wider than the button */
    &:hover::after {
      content: "";
      background-color: rgba(0 0 0 / 5%);
      position: absolute;
      top: 0;
      left: -4px;
      width: calc(100% + 8px);
      height: 100%;
      border-radius: 6px;
      pointer-events: none;
    }
  }

  button.menu-attached {
    position: relative;
    display: flex;
    align-items: center;
    appearance: none;
    border: 0;
    font-size: inherit;
    font-weight: inherit;
    background-color: transparent;
    color: inherit;
    cursor: pointer;
    height: 32px;
    padding: 0 10px;

    /* Dropdown carat style */
    svg {
      margin-left: 0.25em;

      [dir="rtl"] & {
        margin-right: 0.25em;
        margin-left: 0;
      }
    }

    &:active {
      color: var(--menu-bar-text-color-active);
    }

    /* Button "hover" state is actually wider than the button */
    &:hover::after {
      content: "";
      background-color: rgba(0 0 0 / 5%);
      position: absolute;
      top: 0;
      left: -4px;
      width: calc(100% + 8px);
      height: 100%;
      border-radius: 6px;
      pointer-events: none;
    }
  }
}

.menu-bar-left {
  padding-right: 0.25em !important;
  padding-left: 0.25em !important;

  /* First button gets special hover treatment */
  li:nth-child(2) button.menu-attached:hover::after {
    width: calc(100% + 4px);
    left: 0;

    [dir="rtl"] & {
      left: auto;
      right: 0;
    }
  }
}

.menu-bar-right {
  padding-right: 0.25em !important;
  padding-left: 0.25em !important;

  /* Last button gets special hover treatment */
  li:last-child button.menu-attached:hover::after {
    width: calc(100% + 4px);

    [dir="rtl"] & {
      left: 0;
    }
  }
}

.menu-bar-title {
  margin-right: 0.25em;
  margin-left: 0.45em;
  display: flex !important;
  justify-content: center;
  align-items: center;

  [dir="rtl"] & {
    margin-left: 0.25em;
    margin-right: 0.45em;
  }

  h1 {
    font-size: 100%;
    display: none;
  }

  .menu-bar-logo {
    height: 20px;
    position: relative;
    top: -1px;
  }
}

.menu-external-link {
  margin-left: 0.25em;
  margin-right: -0.2em;
  transform: scale(0.8);

  [dir="rtl"] & {
    margin-left: -0.2em;
    margin-right: 0.25em;
  }
}

.menu-carat-down {
  margin-left: 0.25em;
  margin-right: -0.25em;

  [dir="rtl"] & {
    margin-left: -0.25em;
    margin-right: 0.25em;
  }
}
