.palette-container-outer {
  --palette-height: 64px;
  --palette-background: var(--off-white);

  position: absolute;
  bottom: 0;
  width: 100%;
  height: var(--palette-height);
  padding-left: var(--left-right-inset);
  padding-right: var(--left-right-inset);
  display: flex;
  justify-content: center;
}

.palette-container {
  position: relative;
  width: 100%;
  min-width: var(--breakpoint-md);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: var(--palette-background);
  z-index: var(--z-02-palette);
  user-select: none;
  touch-action: none;
  border-top-left-radius: var(--border-radius-large);
  border-top-right-radius: var(--border-radius-large);
}

@media only screen and (width <= 768px) {
  .palette-container-outer {
    padding-left: 0;
    padding-right: 0;
  }

  .palette-container {
    left: 0;
    right: 0;
    border-radius: 0;
    min-width: 100%;
  }
}

.palette-commands {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1em;
  width: 120px;

  button {
    height: 40px;
    width: 40px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  [data-icon="sun"] {
    width: 20px;
    height: 20px;
  }

  [data-icon="undo"],
  [data-icon="redo"] {
    width: 18px;
    height: 18px;
  }

  /* Spacing between buttons */
  button + button {
    margin-left: 0.25em;

    /* Other way around on rtl */
    [dir="rtl"] & {
      margin-left: 0;
      margin-right: 0.25em;
    }
  }
}

.palette-commands-right {
  justify-content: flex-end;
}
