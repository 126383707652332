/**
 *  Typography
 *  ------------------------------------------------------- */

/* Set root font-size */
html {
  font-size: var(--font-size-base);
}

body,
input,
select,
option {
  font-family: var(--font-family);
  font-size: 1rem;
}

b,
strong {
  font-weight: 550;
}

/* Locale-specific rules */
html[lang="ko"] {
  word-break: keep-all;
}

/* Baseline size and appearance for h1 */
h1 {
  margin: 0;
  line-height: 1;
  font-size: 250%;
  font-weight: 300;
}
