.btn,
a.btn {
  display: inline-block;
  outline: none;
  border: 0;
  border-radius: var(--border-radius-medium);
  padding: 0.75em 2em;
  background-color: var(--color-turquoise-300);
  color: var(--color-midnight-900);
  font-weight: 550;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: var(--color-turquoise-400);
  }

  &:active {
    background-color: var(--color-turquoise-400);
  }

  &[disabled] {
    background-color: var(--color-gray-100);
    color: var(--color-gray-400);
    cursor: auto;

    &:hover {
      background-color: var(--color-gray-100);
    }
  }

  &.btn-primary {
    background-color: var(--color-emerald-500);
    color: white;

    &:hover {
      background-color: var(--color-emerald-600);
    }
  }

  &.btn-secondary {
    background-color: var(--color-turquoise-500);
    color: white;

    &:hover {
      background-color: var(--color-turquoise-600);
    }
  }

  &.btn-tertiary {
    background-color: transparent;
    color: var(--interactive-text-color);

    &:not(:disabled) {
      border: 1px solid var(--color-turquoise-500);
    }

    &:hover {
      background-color: var(--interactive-element-hover-color);
      color: var(--interactive-text-hover-color);
    }

    &:active {
      color: var(--interactive-text-active-color);
    }
  }
}
