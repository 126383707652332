.sky-picker-container-outer {
  --sky-picker-border-radius: var(--border-radius-large);
  --sky-picker-box-shadow: var(--medium-box-shadow);
  --header-background-color: var(--color-turquoise-200);
  --header-text-color: var(--color-turquoise-900);

  z-index: var(--z-07-sky-picker);
  position: absolute;

  /* TODO: use button trigger to set initial position,
     and remember position on a per-session basis */
  bottom: 90px;
  left: 50px;
  max-width: 216px; /* prevent upgrade text from expanding width */
}

.sky-picker-container-inner {
  background-color: white;
  border-radius: var(--sky-picker-border-radius);
  overflow: hidden;
  box-shadow: var(--sky-picker-box-shadow);
  user-select: none;

  [dir="rtl"] & {
    left: auto;
    right: 50px;
  }
}

.sky-picker header {
  padding: 0.5em;
  background-color: var(--header-background-color);
  color: var(--header-text-color);
  display: flex;
  align-items: center;
}

.sky-picker.react-draggable header {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
}

.sky-picker.react-draggable-dragging header {
  cursor: grabbing;
}

.sky-picker header img {
  height: 16px;
  width: auto;
  margin-left: 0.5em;
  pointer-events: none;

  [dir="rtl"] & {
    margin-right: 0.5em;
    margin-left: auto;
  }
}

.sky-picker header h3 {
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
  margin: 0 0.25em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1em;

  [data-icon="sun"] {
    margin-right: 0.5em;
    width: 16px;
    height: 16px;

    [dir="rtl"] & {
      margin-left: 0.5em;
      margin-right: auto;
    }
  }
}

.sky-picker-content {
  padding: 0;
}

.sky-picker-upgrade {
  padding: 0.75em 1em;
  background-color: color-mix(in oklch, white 20%, var(--alert-background));
  font-size: 1rem;
  line-height: 1.2;
  text-align: center;
  color: var(--color-copper-900);

  /* Very similar to .toast-action style */
  button {
    display: block !important;
    border: 1px solid var(--color-copper-600);
    border-radius: var(--border-radius-medium);
    padding: 0.5em 1em;
    margin: 0.75em auto 0.25em;
    width: auto;
    font-weight: 550;
    background-color: transparent;
    color: var(--color-copper-800);

    &:disabled {
      filter: grayscale(0.8);
    }

    &:hover {
      background-color: transparent;
      color: var(--color-copper-900);
    }
  }
}

/* Close button overrides */
.sky-picker .close {
  position: relative;
  width: 24px;
  height: 24px;
  padding: 0;
  top: auto;
  right: auto;
  display: flex !important;
  justify-content: center;
  align-items: center !important;
}
