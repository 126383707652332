/* TODO: Further refactor / split up of this stylesheet */

#street-section-outer {
  --building-space: 360px;

  position: absolute;
  display: block;
  inset: calc(-1 * var(--gallery-height)) 0 -20px; /* Bottom inset hides the scrollbar */
  overflow: scroll hidden;
  touch-action: pan-x;

  /* Prevent overscroll from doing forward/back navigation on some browsers */
  overscroll-behavior-x: contain;

  [dir="rtl"] & {
    direction: ltr;
  }
}

#street-section-inner {
  position: absolute;
  display: block;
  left: 0;
  bottom: 130px;

  /* TODO: Recalc other heights based on this value? */
  height: var(--street-section-inner-height);
  user-select: none;

  body.read-only & {
    bottom: 50px;
  }
}

#street-section-editable {
  position: absolute;
  height: calc(var(--canvas-height) + 80px);
  text-align: left;
  touch-action: pan-x;
}

#street-section-canvas {
  z-index: var(--z-01-street-section);
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  margin-left: var(--building-space);
  margin-right: var(--building-space);
  height: calc(var(--canvas-height) + 40px);
}

.street-section-building {
  display: block;
  position: absolute;
  top: -105px;
  width: 396px;
  height: calc(var(--canvas-height) + 70px);
  touch-action: pan-x;

  .hover-bk {
    position: absolute;
    z-index: -1;
    inset: -1000px 0 45px;
  }

  &.hover .hover-bk {
    background: var(--segment-hover-background);
  }

  canvas {
    position: absolute;
    bottom: 0;
  }
}

/* Slightly shift left building 1px to correct "overbite" over ground */
.street-segment-building-left canvas {
  left: -1px;
}

.street-segment-building-right canvas {
  right: 0;
}
