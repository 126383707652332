.whats-new-dialog {
  width: min(90vw, 550px);
  height: 80vh;
  max-height: 800px;
  font-family: system-ui, sans-serif;
  font-size: 14px;
}

.whats-new-content {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 1rem 2rem;
  overflow-y: scroll;

  h1 {
    display: none;
  }

  h2 {
    color: var(--color-turquoise-800);
    font-weight: 550;
    font-size: 1.75rem;
  }

  h2:not(:first-of-type) {
    border-top: 1px solid var(--color-turquoise-400);
    padding-top: 1.25em;
    margin-top: 1.25em;
  }

  h3 {
    margin-top: 1.5em;
    margin-bottom: 1em;
    font-weight: 550;
    font-size: 1.35rem;
    color: var(--color-turquoise-800);
  }

  ul {
    padding-left: 1.5rem;
  }

  li {
    margin-bottom: 0.75em;
  }

  ul ul {
    margin-top: 1em;
  }

  img {
    width: 100%;
  }

  li > img {
    margin-top: 1em;
  }

  .img-ui {
    border: 1px solid var(--color-turquoise-600);
    border-radius: 6px;
    padding: 10px;
    box-shadow: 0 2px 10px rgb(0 0 0 / 5%);
  }
}

.whats-new-loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.whats-new-language-banner {
  /* Very similar to the notification bar banner */
  padding: 0.75em 2.5em;
  text-align: center;
  background-color: var(--alert-background);
  border-bottom: var(--alert-border);
}

.whats-new-scroll-shade {
  position: absolute;
  width: 100%;
  height: max(60px, 10%);
  top: 0;
  left: 0;
  transition: opacity 300ms ease-in-out;
  pointer-events: none;
  background: linear-gradient(
    180deg,
    var(--color-turquoise-700) 0%,
    transparent 100%
  );
  opacity: 0;
}

.whats-new-scroll-shade.visible {
  opacity: 0.15;
}
